import React, { Component, useState, useContext, useRef, FormEvent, RefObject, useEffect, useCallback, useMemo } from 'react'
import _ from "lodash"
import { appDate, CalendarContext } from "../../Context/CalendarContext"
import iassign from 'immutable-assign'
import { useManagerOwnershipQuery, OrgOwnershipAmount, ManagerOwnershipQuery, useUpdateManagerOwnershipMutation, UpdateOrgOwnershipInput, useCreateManagerOwnershipMutation, CreateOrgOwnershipInput, useDeleteManagerOwnershipMutation, DeleteInput, useManagerOwnershipDiverseTablesQuery, ManagerOwnershipDiversifyFragment, ManagerOwnershipDiverseTablesQuery, Maybe, useGetLookupQuery, GetLookupQuery, Manager, EmployeeDemographicsInput, useUpdateManagerOwnershipDiverseTablesMutation, useUpdateManagerOwnershipExplanationMutation, OwnershipExplanationInput, OrgOwnership, OriginCode } from '../../__generated__/graphql'
import { ManagerDetails_overview_Manager_structure_ownership } from '../../queries/types/ManagerDetails'
import { Container, Row, Col, Table, Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import classnames from 'classnames'

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import PlaceHolder from '../ui/PlaceHolder'
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import { excludePropertyArray, convertLookupToString } from '../../helpers/object'
import RouteLeavingGuard, { GuardModal } from "../Shared/RouteLeavingGuard"
import { useHistory } from 'react-router-dom'
import { ColumnDefSource, EmployeeTable } from '../ui/EmployeeTable'
import { CalendarPicker } from '../CalendarPicker'
import moment from 'moment'
import exportTables from '../../helpers/exportTable'
import DOMPurify from 'dompurify'
interface ResultProps {
  data: ManagerOwnershipQuery
  diverseData: ManagerOwnershipDiverseTablesQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<Result>
  setCalendar: (date:string) => void
  searchYear: string
  raceData: GetLookupQuery
  genderData: GetLookupQuery
  minorityData: GetLookupQuery
  handleEdit: () => void
}

interface idProps {
  id: number
  auth: Auth
}

interface Totals {
  [key: string]: Total
}

interface Total {
  economicOwnershipPercent: number
  controllingOwnershipPercent: number
}

interface ManagerOwnershipInput extends FormInputField{
  year?: number
}

enum ManagerOwnershipTooltipType {
  legalNameEntityTooltip = "legalNameEntityTooltip",
  currentTitleTooltip = "currentTitleTooltip",
  yearAdmittedAsOwnerTooltip = "yearAdmittedAsOwnerTooltip",
  economicOwnershipPercentTooltip = "economicOwnershipPercentTooltip",
  controllingOwnershipPercentTooltip = "controllingOwnershipPercentTooltip",
  // Slideout, ignore for now
  // ownershipDemographicsTooltip = "ownershipDemographicsTooltip",
}

const ManagerOwnershipTooltipsIdsMapping = {
  [ManagerOwnershipTooltipType.legalNameEntityTooltip]: 8,
  [ManagerOwnershipTooltipType.currentTitleTooltip]: 9,
  [ManagerOwnershipTooltipType.yearAdmittedAsOwnerTooltip]: 10,
  [ManagerOwnershipTooltipType.economicOwnershipPercentTooltip]: 91,
  [ManagerOwnershipTooltipType.controllingOwnershipPercentTooltip]: 92,
  // [ManagerOwnershipTooltipType.ownershipDemographicsTooltip]: 0
}

export const ManagerOwnershipDiverseTablesDef: ColumnDefSource = {
  // Diversity Profile
  ethnicity: {
    columns: [
      {
        headerName: "Diversity Profile",
        className: "text-left width-60-percent",
        field: "type.value",
        optionSource: "EmployeeRaceType"
      },
      {
        headerName: "% Owned",
        className: "text-right",
        type: "float",
        field: "percent"
      }
    ],
    calculatedTotal: {columnNumber: 1}
  },
  // Gender Breakdown
  gender: {
    columns: [
      {
        headerName: "Gender Breakdown",
        className: "text-left width-60-percent",
        type: "Lookup",
        field: "type.value",
        optionSource: "EmployeeGenderType"
      },
      {
        headerName: "% Owned",
        className: "text-right",
        type: "float", // type: float, subType: percent
        field: "percent"
      }
    ],
    calculatedTotal: {columnNumber: 1}
  },
  // Other Minority
  other: {
    columns: [
      {
        headerName: "Other Minority",
        className: "text-left width-60-percent",
        field: "type.value",
        optionSource: "EmployeeOtherMinorityType"
      },
      {
        headerName: "% Owned",
        className: "text-right",
        type: "float",
        field: "percent"
      }
    ]
  }
}

const appYear = moment(appDate).endOf("year").format("YYYY-MM-DD")

const ManagerOverviewOwnership: React.FC<idProps> = ({ id, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const [searchYear, setSearchYear] = useState(appYear)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  const [updateOwnership] = useUpdateManagerOwnershipMutation()
  const [createOwnership] = useCreateManagerOwnershipMutation()
  const [deleteOwnership] = useDeleteManagerOwnershipMutation()
  const [updateDiverTables] = useUpdateManagerOwnershipDiverseTablesMutation()
  const [updateExplanation] = useUpdateManagerOwnershipExplanationMutation()
  const tooltip_ids = useMemo(() => Object.values(ManagerOwnershipTooltipsIdsMapping) || [], [])
  let { data, loading, error, refetch: _refetch } =
  useManagerOwnershipQuery({
    variables: {
      id,
      tooltip_ids
    },
  })
  const refetchCallback = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch])
  // avoid unnecessary forceUpdate
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)
  useEffect(() => {
    if(!editMode && forceUpdateFlag) {
      refetchCallback()
    }
  }, [editMode, forceUpdateFlag])

  let yearFilter = moment(searchYear).year()
  let diverVariables = {id, filters: {startYear: yearFilter, endYear: yearFilter}}
  const { data:diverseData, loading:diverseLoading, error:diverseError } =
  useManagerOwnershipDiverseTablesQuery({
    variables: diverVariables,
  });

  const { loading: raceLoading, error: raceError, data: raceData } = useGetLookupQuery({
    variables: { name: "EmployeeRaceType" }
  })
  const { loading: genderLoading, error: genderError, data: genderData } = useGetLookupQuery({
    variables: { name: "EmployeeGenderType" }
  })

  const { loading: minorityLoading, error: minorityError, data: minorityData } = useGetLookupQuery({
    variables: { name: "EmployeeOtherMinorityType" }
  })

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    let currentOwnershipOrg = resultRef!.current?.state.currentState.org
    let initialOwnershipOrg = resultRef!.current?.state.initialState.org
    let currentDiverseOrg = resultRef!.current?.state.currentDiverseState.org
    let initialDiverseOrg = resultRef!.current?.state.initialDiverseState.org
    if(!currentOwnershipOrg || currentOwnershipOrg.__typename !== "Manager"){
      return
    }
    if(!initialOwnershipOrg || initialOwnershipOrg.__typename !== "Manager"){
      return
    }
    if(!currentDiverseOrg || currentDiverseOrg.__typename !== "Manager"){
      return
    }
    if(!initialDiverseOrg || initialDiverseOrg.__typename !== "Manager"){
      return
    }
    setSaving(true)
    setForceUpdateFlag(true)
    let org = initialOwnershipOrg
    let diverseOrg = initialDiverseOrg

    let countDown = 0
    const resetAfterCountdown = (countDown: number) => {
      if(countDown <= 0){
        setSaving(false)
        setEditMode(false)
      }
    }
    // Delete Ownerships
    let deletedOwnerships = initialOwnershipOrg!.ownership!.filter(x => {
      if(currentOwnershipOrg && currentOwnershipOrg.__typename === "Manager" && currentOwnershipOrg.ownership){
        return !_.find(currentOwnershipOrg.ownership, (o) => (o.id === x.id) )
      }
      return false
    })

    _.forEach(deletedOwnerships, (ownership) => {
      const deleteData = {
        id: ownership.id,
      } as DeleteInput
      countDown ++
      deleteOwnership({ variables: { input: deleteData } })
        .then(result => {
          if (result && result.data) {
            countDown --
            resetAfterCountdown(countDown)
          }
        })
        .catch(err => {
          countDown --
          resetAfterCountdown(countDown)
          console.log("Error deleteOwnership", err.message)
          // throw new Error(`${err.message}`)
        })
    })

    // Update Exiting Ownerships
    let updatedOwnerships = currentOwnershipOrg.ownership.filter(x => {
      if(org && org.__typename === "Manager" && org.ownership){
        if(typeof(x.id) === "number"){
          return !_.find(org.ownership, (o) => (JSON.stringify(o) === JSON.stringify(x)) )
        } else {
          return false
        }
      }
      return true
    })

    _.forEach(updatedOwnerships, (ownership) => {
      const patchList = convertLookupToString(ownership, false)

      const updateData = {
        id: ownership.id,
        patch: excludePropertyArray(patchList, ["__typename", "id", "updateDate"])
      } as UpdateOrgOwnershipInput
      countDown ++
      updateOwnership({ variables: { input: updateData } })
        .then(result => {
          if (result && result.data) {
            let unformattedNewData = result.data.updateOrgOwnership
            let newState = {org:{id: unformattedNewData!.orgId, ownership: unformattedNewData!.ownership, __typename: "Manager"}, tooltips: data?.tooltips}
            let previousState = resultRef!.current?.state
            resultRef!.current?.setState({ ...previousState, currentState: newState, initialState: newState })
            countDown --
            resetAfterCountdown(countDown)
          }
        })
        .catch(err => {
          countDown --
          resetAfterCountdown(countDown)
          console.log("Error updateOwnership", err.message)
          // throw new Error(`${err.message}`)
        })
      })

    // Create New Ownerships
    let newOwnerships = currentOwnershipOrg.ownership.filter(x => {
      if(org && org.__typename === "Manager" && org.ownership){
        return typeof(x.id) === "string"
      }
      return true
    })

    _.forEach(newOwnerships, (ownership) => {
      const patch = excludePropertyArray(convertLookupToString(ownership, false), ["__typename", "id", "updateDate", "origin"])

      const updateData = {
        orgId: org.id,
        ...patch
      } as CreateOrgOwnershipInput
      countDown ++
      createOwnership({ variables: { input: updateData } })
        .then(result => {
          if (result && result.data) {
            let unformattedNewData = result.data.createOrgOwnership
            let newState = {org:{id: unformattedNewData!.orgId, ownership: unformattedNewData!.ownership, __typename: "Manager"}, tooltips: data?.tooltips}
            let previousState = resultRef!.current?.state
            resultRef!.current?.setState({ ...previousState, currentState: newState, initialState: newState })
            countDown --
            resetAfterCountdown(countDown)
          }
        })
        .catch(err => {
          countDown --
          resetAfterCountdown(countDown)
          console.log("Error createOwnership", err.message)
          // throw new Error(`${err.message}`)
        })
    })

    // Upsert DiverseTablesData
    const updatedGender = (currentDiverseOrg?.gender || []).filter(x => {
      if(diverseOrg && diverseOrg.__typename === "Manager"){
        return !_.find(diverseOrg.gender, (o) => (JSON.stringify(o) === JSON.stringify(x)))
      }
      return true
    }).map(el => {
      if(el && !el?.percent) {
        return {...el, percent: 0}
      }else {
        return el}
    })
    const updatedOther = (currentDiverseOrg?.other || []).filter(x => {
      if(diverseOrg && diverseOrg.__typename === "Manager"){
        return !_.find(diverseOrg.other, (o) => (JSON.stringify(o) === JSON.stringify(x)))
      }
      return true
    }).map(el => {
      if(el && !el?.percent) {
        return {...el, percent: 0}
      }else {
        return el}
    })
    const updatedEthnicity = (currentDiverseOrg?.ethnicity || []).filter(x => {
      if(diverseOrg && diverseOrg.__typename === "Manager"){
        return !_.find(diverseOrg.ethnicity, (o) => (JSON.stringify(o) === JSON.stringify(x)))
      }
      return true
    }).map(el => {
      if(el && !el?.percent) {
        return {...el, percent: 0}
      }else {
        return el}
    })
    const patchGender = convertLookupToString(updatedGender, false)
    const patchOther = convertLookupToString(updatedOther, false)
    const patchEthnicity = convertLookupToString(updatedEthnicity, false)

    let patch = {
      gender: patchGender,
      other: patchOther,
      ethnicity: patchEthnicity
    }
    const updateDiverseData = {
      id,
      patch: excludePropertyArray(patch, ["__typename", "id", "updateDate"])
    } as EmployeeDemographicsInput

    let defaultSearchYear = moment(searchYear).year()
    updateDiverTables({variables: {input: updateDiverseData, filters:{startYear:defaultSearchYear, endYear:defaultSearchYear}}}).then(
      result => {
      if (result && result.data) {
        let previousState = resultRef!.current?.state
        let unformattedNewData = result.data.upsertEmployeeDemographics
        let { ethnicity, gender, other } = (unformattedNewData!.org! as Manager & ManagerOwnershipDiversifyFragment)
        let newState = {org:{id: unformattedNewData!.org!.id, ethnicity, gender, other, __typename: "Manager"}, tooltips: data?.tooltips}
        resultRef!.current?.setState({ ...previousState, currentDiverseState: newState, initialDiverseState: newState })
        countDown --
        resetAfterCountdown(countDown)
      }
    })
    .catch(err => {
      countDown --
      resetAfterCountdown(countDown)
      console.log("Error updateDiverTable", err.message)
      // throw new Error(`${err.message}`)
    })

    // update Explanation
    let {ownershipExplanation}  = currentOwnershipOrg
    let {ownershipExplanation: initialExplanation}  = initialOwnershipOrg
    if(!_.isNull(ownershipExplanation) && initialExplanation !== ownershipExplanation){
      const updateExplanationData = {
        id,
        patch: ownershipExplanation
      } as OwnershipExplanationInput
      countDown ++
      updateExplanation({variables: { input: updateExplanationData }})
        .then(result => {
          if (result && result.data) {
            let unformattedNewData = result.data.upsertOwnershipExplanation
            let newState = {org:{id: unformattedNewData!.org!.id, ownershipExplanation: (unformattedNewData!.org as Manager)!.ownershipExplanation, __typename: "Manager"}, tooltips: data?.tooltips}
            let previousState = resultRef!.current?.state
            resultRef!.current?.setState({ ...previousState, currentState: newState, initialState: newState })
            countDown --
            resetAfterCountdown(countDown)
          }
        })
        .catch(err => {
          countDown --
          resetAfterCountdown(countDown)
          console.log("Error updateExplanation", err.message)
          // throw new Error(`${err.message}`)
        })
    }
    resetAfterCountdown(countDown)
  }
  let explanation = ""
  if(data?.org?.__typename === "Manager") explanation = data.org?.ownershipExplanation || ""
  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables({extraRows: [{values:["Other Explanation",explanation]}]})}>
        Export CSV
        <img src='/assets/CSV.svg' className="ml-2"/>
      </Button>
      {auth.checkPermissions(["edit:manager"]) &&
        <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} disabled={loading}/>
      }
    </div>
  )

  if (loading || diverseLoading || raceLoading || genderLoading || minorityLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error || diverseError || raceError || genderError || minorityError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <p>{error?.message || diverseError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (data?.org && data.org.__typename === "Manager" && diverseData?.org && raceData && genderData && minorityData) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <RouteLeavingGuard
              when={editMode}
              navigate={path => history.push(path)}
            />
            <Result
              key={searchYear}
              ref={resultRef}
              editMode={editMode}
              setEditMode={setEditMode}
              data={data}
              diverseData={diverseData}
              setCalendar={setSearchYear}
              searchYear={searchYear}
              raceData={raceData}
              genderData={genderData}
              minorityData={minorityData}
              handleEdit={handleEdit}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

const currentYear = new Date().getFullYear()

const ManagerOwnershipInputList:ManagerOwnershipInput[] = [
  { property: 'name', label: "", type: 'text' },
  { property: "title", label: "", type: "text" },
  { property: "type.code", label: "", type: "select", subtype: "single", optionSource: "OrgOwnershipType"},
  { property: "yearAdmittedAsOwner", label: "", type: "number", subtype: "year", subClasses: { inputClasses: "text-right" } },
  { property: "investmentTeamMember", label: "", type: "checkbox", subtype: 'boolean', subClasses: { labelClasses: "d-none", inputWrapperClasses: "col-sm-12" }},
]

const ManagerOwnershipExplanationInputList:ManagerOwnershipInput[] = [
  {
    property: 'ownershipExplanation',
    label: "owners and ownership demographics explanation",
    type: 'textarea',
    subClasses: {
      wrapperClasses: "col-sm-11 pl-2",
      labelClasses: "pl-1",
      inputWrapperClasses: "col-sm-12 pl-2"
      }
    },
]

interface ResultState {
  currentState: ManagerOwnershipQuery
  initialState: ManagerOwnershipQuery
  currentDiverseState: ManagerOwnershipDiverseTablesQuery
  initialDiverseState: ManagerOwnershipDiverseTablesQuery
  searchYear: string
}

class Result extends Component<ResultProps> {
  constructor(props: ResultProps) {
    super(props)
    this.state = {
      currentState: this.props.data,
      initialState: this.props.data,
      currentDiverseState: this.props.diverseData,
      initialDiverseState: this.props.diverseData,
      searchYear: this.props.searchYear
    }
  }

  state:ResultState
  // test function
  getLocalYear = () =>{
    let searchYear = this.props.searchYear
    let stateSearchYear = this.state.searchYear
    let year = moment(searchYear).year()
    let stateYear = moment(stateSearchYear).year()
  }

  componentDidUpdate = (prevProps: ResultProps) => {
    if(prevProps.searchYear !== this.props.searchYear) {
      this.setState({searchYear: this.props.searchYear })
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState, currentDiverseState: this.state.initialDiverseState})
  }

  handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  handleInputChange = (
    value: any,
    property: string,
    id: number,
    year?: number
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["org", "ownership"]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as OrgOwnership[])
        var selectedRow = _.find(rows, (o) => {return o.id === id}) as any as OrgOwnership
        if(year){
          let yearValue = _.find(selectedRow?.ownershipAmount, ['year', year])
          if(yearValue){
            // If the year already exists set it
            _.set(yearValue, property, value)
          } else {
            // If the year does not exist create it
            let newYear:OrgOwnershipAmount = {
              year: year,
              economicOwnershipPercent: 0,
              controllingOwnershipPercent: 0,
              __typename: "OrgOwnershipAmount"
            }
            _.set(newYear, property, value)
            selectedRow?.ownershipAmount.push(newYear)
          }
        } else {
          if(property === "type.code"){
            _.set(selectedRow, "type", {code: value || null, __typename: "OrgOwnershipTypeLookup"})
          } else {
            _.set(selectedRow, property, value);
          }
        }
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  handleDiverseTableInputChange = (
    value: any,
    property: string,
    table: string,
    type: any
  ) => {
    let oldState = _.cloneDeep(this.state.currentDiverseState)
    let path = ["org", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => {return (o?.type?.code === type.code)})
        if(selectedRow){
          _.set(selectedRow, property, value)
        } else {
          let defaultSearchYear = moment(this.props.searchYear).year()
          let newRow = {
              year: defaultSearchYear,
              type: {...type, __typename: "Lookup"},
              percent: undefined,
            }
            _.set(newRow, property, value)

          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ ...this.state, currentDiverseState: newState })
  }

  newRow() {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["org", "ownership"]
    console.log(614, {oldState, value: _.get(oldState, path)})
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as OrgOwnership[])
        let newRow: OrgOwnership = {
          __typename: "OrgOwnership",
          name: "",
          title: "",
          yearAdmittedAsOwner: null,
          investmentTeamMember: false,
          type: null,
          ownershipAmount: [],
          origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'},
          updateDate: moment().format(DATE_API_FORMAT),
          id: "new" + rows.length as any
        }
        rows.push(newRow)
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  removeRow(id:number) {
    let newState = _.cloneDeep(this.state.currentState)
    newState = iassign(
      newState,
      currentState => (currentState?.org as any)?.ownership,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as OrgOwnership[])
        _.remove(rows, (r:any) => { return r.id === id})
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  formatDiverseData = (sourceData:any, mappingList:any) => {
    let defaultYear = moment(this.props.searchYear).year()
    return _.map(mappingList.__type?.enumValues, (type:any) => {
      const foundData = _.find(sourceData, (o:any) => o.type.code === type.code)
      if(foundData){
        return foundData
      } else {
        return {
          year: defaultYear,
          type:{...type, __typename: "Lookup"},
          percent: undefined
        }
      }
    })
  }

  handleExplanationInputChange = (
    value: any,
    property: string,
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["org", property]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, {})
    }
    let newState = iassign(
      oldState,
      path,
      () => value
    )
    this.setState({ ...this.state, currentState: newState})
  }

  generateExplanationComponent = (inputFields: ManagerOwnershipInput[], state: Maybe<ManagerOwnershipQuery["org"]> | undefined) => {
    return (
      <Col sm="9" className="px-3">
        {inputFields.map(
          (
            {
              property,
              label,
              type,
              subtype,
              placeholder,
              optionSource,
              subClasses,
            },
            idx
          ) => {
            let propertyVal: any = _.get(state, property)
            let onChangeCallback = (value: any) =>{
              if(type === "select" && value === ""){
                this.handleExplanationInputChange(null, property)
              } else {
                this.handleExplanationInputChange(value, property)
              }
            }
            return (
              <FormInput
                key={idx}
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={idx}
                editMode={this.props.editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                subClasses={subClasses}
                showZero={true}
              />
            )
      })}
      </Col>)
  }

  render() {
    const diverseData = this.state.currentDiverseState
    const {gender, ethnicity, other} = diverseData.org as Manager as {
      __typename: "Manager";
      id: number;
  } & ManagerOwnershipDiversifyFragment

    const formattedEthnicityData = this.formatDiverseData(ethnicity,this.props.raceData)
    const formattedGenderData = this.formatDiverseData(gender,this.props.genderData)
    const formattedOtherData = this.formatDiverseData(other,this.props.minorityData)
    const ownerships = _.get(this.state.currentState, 'org.ownership', [])
    const totals = ownerships.reduce(
      (
        result: Totals,
        entry: any
      ) => {
        _.each(entry.ownershipAmount, (ownershipAmount) => {
          result[ownershipAmount.year] = result[ownershipAmount.year] || {economicOwnershipPercent: 0, controllingOwnershipPercent: 0}
          result[ownershipAmount.year].economicOwnershipPercent += ownershipAmount.economicOwnershipPercent
          result[ownershipAmount.year].controllingOwnershipPercent += ownershipAmount.controllingOwnershipPercent
        })
        return result
      },
      {}
    )
    const years = _.range(currentYear, currentYear - 11)
    let defaultYear = moment(this.props.searchYear).year()
    // this.getLocalYear()
    const tooltipsData = this.state.currentState?.tooltips
    return (
      <>
        <div className='pane pane-table' key={'owner-table'}>
          <Row>
            <Col>
              <h3 className="headline underline green-underline mb-3">
                <div className="d-inline-flex align-items-center">
                  Owners
                </div>
              </h3>
            </Col>
          </Row>
          <div className='table-container'>
            <Table className='exportable' data-export-name={"Owner data"}>
            <thead>
              <tr>
                <th className="medium-wide text-left" id="legalNameEntityTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="legalNameEntityTooltip">
                    Legal Name/Entity
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                      id={"generic-tooltip-legalNameEntityTooltip"}
                      />
                      <UncontrolledTooltip
                        className="generic-tooltip"
                        target={"generic-tooltip-legalNameEntityTooltip"}
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                          tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerOwnershipTooltipsIdsMapping["legalNameEntityTooltip" as ManagerOwnershipTooltipType] || "")?.text || ""
                          )}}
                        delay={{hide: 1000, show: 0}}
                      />
                  </div>
                </th>
                <th className="medium text-left" id="currentTitleTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="currentTitleTooltip">
                    Current Title
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                      id={"generic-tooltip-currentTitleTooltip"}
                    />
                    <UncontrolledTooltip
                      className="generic-tooltip"
                      target={"generic-tooltip-currentTitleTooltip"}
                      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                        tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerOwnershipTooltipsIdsMapping["currentTitleTooltip" as ManagerOwnershipTooltipType] || "")?.text || ""
                        )}}
                      delay={{hide: 1000, show: 0}}
                    />
                  </div>
                </th>
                <th className="medium-wide text-left">Participant Type</th>

                <th className="medium text-right" id="yearAdmittedAsOwnerTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="yearAdmittedAsOwnerTooltip">
                    Year Admitted as Owner
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                      id={"generic-tooltip-yearAdmittedAsOwnerTooltip"}
                    />
                    <UncontrolledTooltip
                      className="generic-tooltip"
                      target={"generic-tooltip-yearAdmittedAsOwnerTooltip"}
                      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                        tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerOwnershipTooltipsIdsMapping["yearAdmittedAsOwnerTooltip" as ManagerOwnershipTooltipType] || "")?.text || ""
                        )}}
                      delay={{hide: 1000, show: 0}}
                    />
                  </div>
                </th>
                <th>Investment Team Member</th>
                {years.map((year:number, idx: number) => {
                  return idx === 0 ?
                    <React.Fragment key={year}>
                      <th id="economicOwnershipPercentTooltipContainer">
                        <div className="d-inline-flex align-items-center tooltip-icon" id="economicOwnershipPercentTooltip">
                          % Economic {year}
                          <FontAwesomeIcon
                            icon={"question-circle" as IconName}
                            size="sm"id={"generic-tooltip-economicOwnershipPercentTooltip"}
                          />
                          <UncontrolledTooltip
                            className="generic-tooltip"
                            target={"generic-tooltip-economicOwnershipPercentTooltip"}
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                              tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerOwnershipTooltipsIdsMapping["economicOwnershipPercentTooltip" as ManagerOwnershipTooltipType] || "")?.text || ""
                              )}}
                            delay={{hide: 1000, show: 0}}
                          />
                        </div>
                      </th>
                      <th id="controllingOwnershipPercentTooltipContainer">
                        <div className="d-inline-flex align-items-center tooltip-icon" id="controllingOwnershipPercentTooltip">
                          % Controlling {year}
                          <FontAwesomeIcon
                            icon={"question-circle" as IconName}
                            size="sm"id={"generic-tooltip-controllingOwnershipPercentTooltip"}
                          />
                          <UncontrolledTooltip
                            className="generic-tooltip"
                            target={"generic-tooltip-controllingOwnershipPercentTooltip"}
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                              tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerOwnershipTooltipsIdsMapping["controllingOwnershipPercentTooltip" as ManagerOwnershipTooltipType] || "")?.text || ""
                              )}}
                            delay={{hide: 1000, show: 0}}
                          />
                        </div>
                      </th>
                    </React.Fragment>
                    :
                    <React.Fragment key={year}>
                      <th>% Economic {year}</th>
                      <th>% Controlling {year}</th>
                      </React.Fragment>
                  })}
                  <th />
                </tr>
              </thead>
              <tbody>
                {ownerships.map((ownership:ManagerDetails_overview_Manager_structure_ownership, idx:number) => {
                  return(
                    <OwnershipRow
                      key={ownership.id}
                      data={ownership}
                      row={idx}
                      editMode={this.props.editMode}
                      updateValue={(value, property, year?) => this.handleInputChange(value,property,ownership.id,year)}
                      years={years}
                      removeRow={(id:number) => this.removeRow(id)}
                    />
                  )
                })}
                {this.props.editMode &&
                  <tr>
                    <td colSpan={7} className="text-left">
                      <Button color="link" onClick={() => this.newRow()}>
                        <FontAwesomeIcon
                          icon="plus-circle"
                          className="mr-2 text-blue-100"
                        />
                        Add Row
                      </Button>
                    </td>
                    {years.map((year:number) => {
                      return(
                        <td key={year}></td>
                      )
                    })}
                  </tr>
                }
                <tr>
                  <td className="text-left">Calculated Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {years.map((year:number) => {
                    return(
                      <React.Fragment key={year}>
                        <td className={classnames("text-right", { "text-accent-red": this.props.editMode ? (totals[year]?.economicOwnershipPercent !== 100) : (totals[year]?.economicOwnershipPercent !== 100 && totals[year]?.economicOwnershipPercent !== 0 && totals[year]?.economicOwnershipPercent !== undefined) })} key={`${year}-economicOwnershipPercent`}>{totals[year]?.economicOwnershipPercent.toFixed(2) || "0.00"}<span className="input-group-addon percent">&#37;</span></td>
                        <td className={classnames("text-right", { "text-accent-red": this.props.editMode ? (totals[year]?.controllingOwnershipPercent !== 100) : (totals[year]?.controllingOwnershipPercent !== 100 && totals[year]?.controllingOwnershipPercent !== 0 && totals[year]?.controllingOwnershipPercent !== undefined) })} key={`${year}-controllingOwnershipPercent`}>{totals[year]?.controllingOwnershipPercent.toFixed(2) || "0.00"}<span className="input-group-addon percent">&#37;</span></td>
                      </React.Fragment>
                    )
                  })}<td />
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className='pane pane-table' key={'ownership-demographics-table'}>
          <Row>
            <Col lg={2} md={3} xs={4}>
              <FormInput
                property={'year'}
                displayName={"Year:"}
                type={"select"}
                idx={1}
                editMode={!this.props.editMode}
                propertyVal={defaultYear}
                updateValue={this.props.setCalendar}
                subClasses={{wrapperClasses: classnames("no-gutters"), labelClasses:"font-weight-bold", inputClasses:"w-initial ml-3"}}
                options={_.range(moment(appYear, "YYYY-MM-DD").year(), 1900).map((yr) => (
                  {
                    code:yr.toString(),
                    value:yr.toString()
                  }
                ))}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <h3 className="headline underline green-underline mb-3">
                <div className="d-inline-flex align-items-center tooltip-icon" id="ownershipDemographicsTooltip">
                  Ownership Demographics
                  <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  size="sm"
                  />
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col className="border-right">
              <EmployeeTable
                data={formattedEthnicityData}
                tableName={`ethnicity`}
                exportTableName={`Diversity`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => {
                  this.handleDiverseTableInputChange(value, property, 'ethnicity', type)
                }}
                source={ManagerOwnershipDiverseTablesDef}
              />
            </Col>
            <Col className="border-right">
              <EmployeeTable
                data={formattedGenderData}
                tableName={`gender`}
                exportTableName={`Gender`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => {
                  this.handleDiverseTableInputChange(value, property, 'gender', type)
                }}
                source={ManagerOwnershipDiverseTablesDef}
              />
            </Col>
            <Col>
              <EmployeeTable
                data={formattedOtherData}
                tableName={`other`}
                exportTableName={`Other`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => {
                  this.handleDiverseTableInputChange(value, property, 'other', type)
                }}
                source={ManagerOwnershipDiverseTablesDef}
              />
            </Col>
          </Row>
          <Row>
            {this.generateExplanationComponent(ManagerOwnershipExplanationInputList,this.state.currentState.org)}
          </Row>

        </div>
      </>
    )
  }
}

interface OwnershipRowProps {
  data: any
  row: number
  editMode: boolean
  updateValue: (value:any, property:string, year?:number) => void
  years: number[]
  removeRow: (id:number) => void
}

const OwnershipRow = ({data, row, editMode, updateValue, years, removeRow}: OwnershipRowProps) => {
  return (
    <tr className={ editMode ? "hover-actions editing" : "hover-actions" }>
      {ManagerOwnershipInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly, year, subClasses}, idx) => {
        let propertyVal, onChangeCallback
        if(year){
          const yearValue = _.find(data.ownershipAmount, ['year', year])
          propertyVal = _.get(yearValue, property)
          onChangeCallback = (value:any) =>
            updateValue(value, property, year)
        }else {
          propertyVal = _.get(data, property)
          onChangeCallback = (value:any) =>
            updateValue(value, property)
        }

        return(
          <td key={idx}>
            <FormInput
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={idx + (row*years.length)}
              editMode={editMode}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              readonly={readonly}
              subClasses={subClasses}
            />
          </td>
        )
      })}
      {years.map((year:number, idx:number) => {
        const yearValue = _.find(data.ownershipAmount, ['year', year])
        const economicOwnershipPercentVal = _.get(yearValue, "economicOwnershipPercent")
        const controllingOwnershipPercentVal = _.get(yearValue, "controllingOwnershipPercent")
        const onChangeEconomicOwnershipPercentCallback = (value:any) => updateValue(value, "economicOwnershipPercent", year)
        const onChangeControllingOwnershipPercentCallback = (value: any) => updateValue(value, "controllingOwnershipPercent", year)
        return(
          <React.Fragment key={`${year}-${idx}`}>
            <td key={`${idx}-economicOwnershipPercent`}>
              <FormInput
                property={"economicOwnershipPercent"}
                displayName={""}
                type={"float"}
                subtype={"percent"}
                placeholder={"0.00%"}
                idx={idx + (row*years.length)}
                editMode={editMode}
                propertyVal={economicOwnershipPercentVal}
                updateValue={onChangeEconomicOwnershipPercentCallback}
                subClasses={{ inputClasses: "text-right" }}
              />
            </td>
            <td key={`${idx}-controllingOwnershipPercent`}>
              <FormInput
                property={"controllingOwnershipPercent"}
                displayName={""}
                type={"float"}
                subtype={"percent"}
                placeholder={"0.00%"}
                idx={idx + (row * years.length)}
                editMode={editMode}
                propertyVal={controllingOwnershipPercentVal}
                updateValue={onChangeControllingOwnershipPercentCallback}
                subClasses={{ inputClasses: "text-right" }}
              />
            </td>
          </React.Fragment>
        )
      })}
      <td className="actions">
        <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeRow(data.id) } >
          <FontAwesomeIcon
            icon="trash"
          />
        </Button>
      </td>
    </tr>
  )
}

interface YearPickerComponentProps {
  updateValue: (value:any) => void
  defaultYear: number // year user searched for
  editMode: boolean
  setEditMode: (mode:boolean) => void
  handleEdit: () => void
}

interface PickerProps {
  defaultYear:number // year user searched
  startYear: number // appYear
  setYear: React.Dispatch<React.SetStateAction<number>>
  updateValue: (value:any) => void
  editMode: boolean
  setEditMode: (mode:boolean) => void
  handleEdit: () => void
}

const YearPickerComponent:React.FC<YearPickerComponentProps> = (props) => {
  const {updateValue, defaultYear, editMode, setEditMode, handleEdit} = props
  const [year, setYear] = useState(defaultYear)
  useEffect(()=>{
    setYear(props.defaultYear)
  }, [props])

  let startYear = moment(appYear, "YYYY-MM-DD").year()
  return <YearPicker defaultYear={defaultYear} startYear={startYear} setYear={setYear} updateValue={updateValue} editMode={editMode} setEditMode = {setEditMode} handleEdit={handleEdit}/>
}


const YearPicker:React.FC<PickerProps> = (props) => {
  const {defaultYear, startYear, setYear, updateValue, editMode, setEditMode, handleEdit} = props
  const yearSelectRef = useRef<HTMLSelectElement>(null);
  const [ modalVisible, setModalVisible ] = useState(false)
  const callbackList = useRef<(()=>void)[]>([]);

  const closeModal = (callback?: (() => void) | undefined) => setModalWithCallback(false, callback)

  const setModalWithCallback= (newState:boolean, callback?:()=>void) => {
    setModalVisible(newState);
    if(callback) {
      callbackList.current.push(callback)
    }
  }

  useEffect(() => {
    callbackList.current.forEach((callback) => callback())
    callbackList.current = [];
  }, [modalVisible]);


  const handleYearChange = (e:React.MouseEvent<HTMLSelectElement>) => {
    let value = yearSelectRef?.current?.value
    if(value){
      if(editMode) {
        setModalVisible(true)
      }else{
        setYear(parseInt(value))
        updateValue(`${value}-12-31`)
      }
    }
  };

  return (
    <div className="">
      <h4>Year:
        <select
          defaultValue={defaultYear}
          ref={yearSelectRef}
          // comment this when fix change year while editing.
          disabled={props.editMode}
          onClick={e=>handleYearChange(e)}>
          {_.range(startYear, 1900).map((yr) => (
            <option value={yr} key={`${yr}-option`}>{yr}</option>
          ))}
        </select>
      </h4>
      <GuardModal
        key={`${defaultYear}-leaving-modal`}
        open={modalVisible}
        stay={() => setModalVisible(false)}
        leave={()=>closeModal(()=>{
          setModalVisible(false)
          handleEdit()
        })}
      />
    </div>
  );
}

export default ManagerOverviewOwnership
