import _ from "lodash"
import React from "react"
import { HurdleBenchmark } from '../../../../__generated__/graphql'
import { previousQuarterEnd } from "../helper"
import {
  DatedFeeRow,
  EntryFeeRow,
  RedemptionFeeRow,
  BooleanFeeRow,
  HedgeFundHurdleRow,
  AltFeeRow,
  WaterfallRow,
  PrivateHurdleRow,
} from "./FeeComponents"
import { FeeData } from "./getFeeData"
import { OtherFeeInputs } from "./inputs"
import { OtherFeeVehicleType } from "./OtherFees"

export interface NumericInputData<T> {
  value: T
  onChange: (value: string) => void
}

export interface OtherInputData<T> {
  value: T
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export interface FurtherDetailsInputData<T> {
  value: T
  onChange: (value: string) => void
}

export interface OtherFeesGenericProps {
  label: string
  editing: boolean
}

interface OtherFeesRowsProps {
  editing: boolean
  vehicleType: OtherFeeVehicleType
  feeData: FeeData
  updateFeeData: (path: string, value: any) => void
}

export const FeeRows: React.FC<OtherFeesRowsProps> = ({
  editing,
  vehicleType,
  feeData,
  updateFeeData,
}) => {
  const feesForVehicle = OtherFeeInputs.find(
    (item) => item.vehicleType === vehicleType
  )
  if (feesForVehicle) {
    return (
      <>
        {feesForVehicle.fees.map((data, index) => {
          let sharedProps = { label: data.label, editing }
          const inputData = _.get(feeData, data.path)
          const key = `${data.type}${data.path}`
          if(vehicleType === "OpenEndedMutualFund"){
            // CAL-3702 no editing for mutual funds.
            sharedProps = { label: data.label, editing: false }
          }
          switch (data.type) {
            case "datedFee":
              return (
                <DatedFeeRow
                  key={key}
                  {...sharedProps}
                  date={inputData?.date}
                  input={{
                    value: inputData?.value,
                    onChange: (value) => {
                      updateFeeData(`${data.path}`, {
                        value,
                        date: previousQuarterEnd,
                      })
                    },
                  }}
                />
              )
            case "entryFee":
              return (
                <EntryFeeRow
                  key={key}
                  {...sharedProps}
                  boolInput={{
                    value: inputData.entryFees,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      updateFeeData(
                        `${data.path}.entryFees`,
                        e.target.value === "true" ? true : false
                      )
                    },
                  }}
                  feeInput={{
                    value: inputData.entryFeePct,
                    onChange: (value: string) => {
                      updateFeeData(`${data.path}.entryFeePct`, value)
                    },
                  }}
                />
              )
            case "redemptionFee":
              return (
                <RedemptionFeeRow
                  key={key}
                  {...sharedProps}
                  boolInput={{
                    value: inputData.redemptionFees,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      updateFeeData(
                        `${data.path}.redemptionFees`,
                        e.target.value === "true" ? true : false
                      )
                    },
                  }}
                  feeInput={{
                    value: inputData.redemptionFeePct,
                    onChange: (value: string) => {
                      updateFeeData(`${data.path}.redemptionFeePct`, value)
                    },
                  }}
                  daysInput={{
                    value: inputData.redemptionDays,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      updateFeeData(
                        `${data.path}.redemptionDays`,
                        e.target.value
                      )
                    },
                  }}
                />
              )
            case "booleanFee":
              return (
                <BooleanFeeRow
                  key={key}
                  id={key}
                  {...sharedProps}
                  boolInput={{
                    value: inputData,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      updateFeeData(
                        data.path,
                        e.target.value === "true" ? true : false
                      )
                    },
                  }}
                />
              )
            case "hfHurdle":
              return (
                <HedgeFundHurdleRow
                  key={key}
                  {...sharedProps}
                  date={inputData.hurdleBenchmark.date}
                  benchmarkInput={{
                    options: inputData.hurdleBenchmarkOptions,
                    value: inputData.hurdleBenchmark.benchmark,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      const hurdleBenchmark = _.get(
                        feeData,
                        `${data.path}.hurdleBenchmark`,
                        {} as HurdleBenchmark
                      )
                      hurdleBenchmark.benchmark = e.target.value as any
                      hurdleBenchmark.date = previousQuarterEnd
                      updateFeeData(
                        `${data.path}.hurdleBenchmark`,
                        hurdleBenchmark
                      )
                    },
                  }}
                  feeInput={{
                    value: inputData.hurdleBenchmark.benchmarkAdd,
                    onChange: (value: string) => {
                      const hurdleBenchmark = _.get(
                        feeData,
                        `${data.path}.hurdleBenchmark`,
                        {} as HurdleBenchmark
                      )
                      hurdleBenchmark.benchmarkAdd = value as any
                      hurdleBenchmark.date = previousQuarterEnd
                      updateFeeData(
                        `${data.path}.hurdleBenchmark`,
                        hurdleBenchmark
                      )
                    },
                  }}
                />
              )
            case "altFee":
              return (
                <AltFeeRow
                  {...sharedProps}
                  idx={`${index}-${key}`}
                  date={inputData.date}
                  feeInput={{
                    value: inputData.rate,
                    onChange: (value: string) => {
                      const fee = _.get(feeData, `${data.path}`)
                      fee.rate = value
                      fee.date = previousQuarterEnd
                      updateFeeData(`${data.path}`, fee)
                    },
                  }}
                  detailsInput={{
                    value: inputData.details,
                    onChange: (value: string) => {
                      const fee = _.get(feeData, `${data.path}`)
                      fee.details = value
                      fee.date = previousQuarterEnd
                      updateFeeData(`${data.path}`, fee)
                    },
                  }}
                />
              )
            case "waterfall":
              return (
                <WaterfallRow
                  key={key}
                  {...sharedProps}
                  waterfallInput={{
                    options: inputData.waterfallOptions,
                    value: inputData.waterfall,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      updateFeeData(`${data.path}.waterfall`, e.target.value)
                    },
                  }}
                  detailsInput={{
                    value: inputData.waterfallFurtherDetails,
                    onChange: (value: string) => {
                      updateFeeData(
                        `${data.path}.waterfallFurtherDetails`,
                        value
                      )
                    },
                  }}
                />
              )
            case "peHurdle":
              return (
                <PrivateHurdleRow
                  key={key}
                  {...sharedProps}
                  date={inputData.date}
                  returnTypeInput={{
                    options: inputData.hurdleReturnOptions,
                    value: inputData.hurdleReturnType,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      const hurdle = _.get(feeData, `${data.path}`)
                      hurdle.hurdleReturnType = e.target.value
                      hurdle.date = previousQuarterEnd
                      updateFeeData(`${data.path}`, hurdle)
                    },
                  }}
                  calculationTypeInput={{
                    options: inputData.hurdleCalculationOptions,
                    value: inputData.hurdleCalculationType,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      const hurdle = _.get(feeData, `${data.path}`)
                      hurdle.hurdleCalculationType = e.target.value
                      hurdle.date = previousQuarterEnd
                      updateFeeData(`${data.path}`, hurdle)
                    },
                  }}
                  feeInput={{
                    value: inputData.hurdle,
                    onChange: (value: string) => {
                      const hurdle = _.get(feeData, `${data.path}`)
                      hurdle.hurdle = value
                      hurdle.date = previousQuarterEnd
                      updateFeeData(`${data.path}`, hurdle)
                    },
                  }}
                />
              )
            default:
              return <div>invalid fee type detected: {data.type}</div>
          }
        })}
      </>
    )
  } else {
    return <></>
  }
}
