import classnames from 'classnames'
import { sortBy, get, map, set, findIndex, cloneDeep, isEqual, find, uniqBy, isEmpty, isNil, flatten, maxBy, remove, compact } from 'lodash'
import React, { Component, useState, useContext, useRef, useMemo } from 'react'
import { Table, Container, Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import iassign from 'immutable-assign'
import moment, { Moment } from 'moment'
import omitDeep from 'omit-deep-lodash'
import { useHistory } from 'react-router-dom'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { CalendarContext, appDate } from "../../Context/CalendarContext"
import { Maybe, useAssetsByAccountQuery, AssetsByAccountQuery, ManagerAccountTurnover, ManagerAccountRanges, useUpdateManagerAssetsByAccountsMutation, ManagerAssetsInputFields, ManagerAccountTurnoverInput, ManagerLargestAccountsInput, ManagerAccountRangesInput, ClientType, Scalars, TooltipFragment } from '../../__generated__/graphql'
import { managerTypeBreakdown, ManagerType as ManagerBreakdownType, listQuarters} from '../../helpers/helpers'
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from '../ui/PlaceHolder'
import { FormInputField, DATE_API_FORMAT } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import ErrorDisplay from '../Shared/ErrorDisplay'
import exportTables from '../../helpers/exportTable'
import DOMPurify from 'dompurify'


interface ResultProps {
  setSearchDate: (searchDate:string) => void
  data: AssetsByAccountQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  historicView: boolean,
  onChange: (stateDiff:stateDiffType) => void,
  fetchMore: any,
}

interface HistoricalProps {
  data: AssetsByAccountQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  fetchMore: any
}

interface idProps {
  id: number
  auth: Auth
}

interface AccountRangeInputField extends FormInputField{
  amountRange?: string
}

const firstHistoricalDate = moment(appDate).subtract(5,"years")

interface stateDiffType {
  accountTurnover: ManagerAccountTurnover[],
  largestAccounts: ManagerAssetsByAccountsLargestFragment[],
  accountRanges: ManagerAccountRanges[]
}

const emptyStateDiff:stateDiffType = {
  accountTurnover: [],
  largestAccounts: [],
  accountRanges: []
}

enum ManagerAssetsAccountsTooltipType {
  // Slideout, ignore for now
  // assetsAccountsTooltip = 'assetsAccountsTooltip',
  assetsPercentGainedTooltip = 'assetsPercentGainedTooltip',
  assetsPercentLostTooltip = 'assetsPercentLostTooltip',
  assetsRankTooltip = 'assetsRankTooltip'
} 

const ManagerAssetsAccountsTooltipsIdsMapping = {
  // Slideout, ignore for now
  // [ManagerAssetsAccountsTooltipType.assetsAccountsTooltip]: 0,
  [ManagerAssetsAccountsTooltipType.assetsPercentGainedTooltip]: 39,
  [ManagerAssetsAccountsTooltipType.assetsPercentLostTooltip]: 40,
  [ManagerAssetsAccountsTooltipType.assetsRankTooltip]: 41,
}

const ManagerAssetsAccounts: React.FC<idProps> = ({ id, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)//"2019-03-31")//context.quarter)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()

  const emptySingleSubmissionData: ManagerAssetsInputFields = {
    accountTurnover: [],
    largestAccounts: [],
    accountRanges: []
  }

  const [submissionSingleData, setSubmissionSingleData] = useState(emptySingleSubmissionData)
  const [updateManagerAssetsByAccount] = useUpdateManagerAssetsByAccountsMutation()

  const historicalStartDate = moment(appDate).format(DATE_API_FORMAT)
  const historicalEndDate = firstHistoricalDate.format(DATE_API_FORMAT)

  const tooltip_ids = useMemo(() => Object.values(ManagerAssetsAccountsTooltipsIdsMapping) || [], [])
  let { data, loading, error, fetchMore } =
  useAssetsByAccountQuery({
    variables: {
      id,
      tooltip_ids,
      startDate: screen === "single" ? searchDate : historicalStartDate,
      endDate: screen === "single" ? searchDate : historicalEndDate
    },
    // partialRefetch: true,
    fetchPolicy: 'network-only'
  })

  const handleEdit = (newEditMode: boolean) => {
    if (newEditMode === false) {
      resultRef.current?.resetForm() // only reset form on cancel
      setSubmissionSingleData(emptySingleSubmissionData)
    }
    setEditMode(newEditMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)

    let submission = {
      id: id,
      patch: submissionSingleData
    }

    submission.patch.largestAccounts?.forEach((la) => {
      remove(la.accounts || [], (account) => {
        return account.size === undefined || account.size === null
      })
    })

    updateManagerAssetsByAccount({ variables: { input: submission, startDate: screen === "single" ? searchDate : historicalStartDate, endDate: screen === "single" ? searchDate : historicalEndDate } })
      .then(result => {
        setSaving(false)
        if (result && result.data) {
          let unformattedNewData = { assets: result.data.assets?.org }
          resultRef.current?.resetHistoricalDate()
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.error("Error updateManagerAssetsByAccount", err.message)
      })
  }

  type CustomManagerLargestAccountsInput = {
    quarterEndDate: Scalars['Date'];
    accounts: Array<CustomManagerLargestAccountItemInput> ;
  }

  type CustomManagerLargestAccountItemInput = {
    rank: Scalars['Int'];
    type: ClientType;
    size?: Scalars['Float'];
  }

  const handleChange = (stateDiff:stateDiffType) => {
    let subData:ManagerAssetsInputFields = {
      accountTurnover: [],
      largestAccounts: [],
      accountRanges: []
    }

    if (stateDiff.accountTurnover) {
      stateDiff.accountTurnover.map((account, index) => {
        let subAccountTurnover:ManagerAccountTurnoverInput = omitDeep(account, '__typename')
        return subData.accountTurnover && subData.accountTurnover.push(subAccountTurnover)
      })
    }

    if (stateDiff.largestAccounts) {
      stateDiff.largestAccounts.forEach((largestAccount, index) => {
        let subLargestAccounts:CustomManagerLargestAccountsInput = {
          quarterEndDate: largestAccount.quarterEndDate,
          accounts: compact(largestAccount?.accounts?.map(account => {
            if (account?.rank) {
              let a:CustomManagerLargestAccountItemInput = {
                rank: account.rank ,
                size: account.size ,
                type: account.type.code as ClientType
              }
              return a
            }
          }) || [])
        }
        subData.largestAccounts && subData.largestAccounts.push(subLargestAccounts as ManagerLargestAccountsInput)
      })
    }

    if (stateDiff.accountRanges) {
      stateDiff.accountRanges.map((account, index) => {
        let subAccountRanges:ManagerAccountRangesInput = omitDeep(account, '__typename')
        return subData.accountRanges && subData.accountRanges.push(subAccountRanges)
      })
    }
    setSubmissionSingleData(subData)
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
      </div>
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>resultRef.current?.loadMore()}>
            {resultRef.current?.state.loadingMore && "Loading"}
            {!resultRef.current?.state.loadingMore && "Load 5 More Years"}
          </Button>
        }
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={() =>handleEdit(false)}/>
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    );
  }
  if (data && data.assets && data.assets.__typename === "Manager") {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              key={searchDate}
              editMode={editMode}
              setEditMode={setEditMode}
              setSearchDate={setSearchDate}
              data={data}
              searchDate={searchDate}
              historicView={ screen === 'historical'}
              onChange={handleChange}
              fetchMore={fetchMore}
              ref={resultRef}
            />
          </Col>
        </Row>
      </Container>
    )
  }

  return <></>
}


class Result extends Component<ResultProps> {
  state = {
    initialState: this.props.data,
    currentState: this.props.data,
    stateDiff: emptyStateDiff,
    loadingMore: false,
    historicalDate: firstHistoricalDate
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState, stateDiff: emptyStateDiff })
  }

  resetHistoricalDate = () => {
    this.setState({ historicalDate: firstHistoricalDate, stateDiff: emptyStateDiff })
  }

  static getDerivedStateFromProps(props: ResultProps, state:any) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    return {
      currentState: resetDate && !props.editMode ? props.data : state.currentState,
      initialState: resetDate ? props.data : state.initialState,
      stateDiff: resetDate && !props.editMode ? emptyStateDiff: state.stateDiff,
      loadingMore: state.loadingMore,
      historicalDate: state.historicalDate
    }
  }

  handleInputChange = (
    tableName: string,
    newState: ManagerAccountTurnover[] | ManagerAssetsByAccountsLargestFragment[] | ManagerAccountRanges[]
  ) => {
    let state = cloneDeep(this.state.currentState)
    let path = ["assets", tableName]
    if(!get(state, path)) {
      set(state, path, [])
    }
    let newCurrentState = iassign(
      state,
      path,
      () => newState
    )

    // Create State Diff
    if (this.state.initialState.assets && this.state.initialState.assets.__typename === "Manager" &&
      newCurrentState.assets && newCurrentState.assets.__typename === 'Manager') {

      const { accountTurnover:newAccountTurnover, largestAccounts:newLargestAccounts, accountRanges:newAccountRanges } = newCurrentState.assets
      const { accountTurnover:oldAccountTurnover, largestAccounts:oldLargestAccounts, accountRanges:oldAccountRanges } = this.state.initialState.assets
      let submissionData:stateDiffType = {
        accountTurnover: [],
        largestAccounts: [],
        accountRanges: []
      }

      if (newAccountTurnover && oldAccountTurnover && !isEqual(newAccountTurnover, oldAccountTurnover)) {
        newAccountTurnover.forEach((account, index) => {
          if (account && !isEqual(account, oldAccountTurnover[index])) {
            submissionData?.accountTurnover?.push(account)
          }
        })
      }

      if (newLargestAccounts && oldLargestAccounts && !isEqual(oldLargestAccounts, newLargestAccounts)) {
        newLargestAccounts.forEach((account, index) => {
          if (account && !isEqual(account, oldLargestAccounts[index])) {
            submissionData?.largestAccounts?.push(account)
          }
        })
      }

      if (newAccountRanges && oldAccountRanges && !isEqual(oldAccountRanges, newAccountRanges)) {
        newAccountRanges.forEach((account, index) => {
          if (account && !isEqual(account, oldAccountRanges[index])) {
            submissionData?.accountRanges?.push(account)
          }
        })
      }

      this.props.onChange(submissionData)
      this.setState({ currentState: newCurrentState, stateDiff: submissionData })
    }
  }

  loadMore = () => {
    if(this.state.loadingMore){
      return
    }
    this.setState({ loadingMore: true})
    this.props.fetchMore({
      variables: {
        id: this.state.currentState.assets?.id,
        startDate: this.state.historicalDate.format(DATE_API_FORMAT),
        endDate: moment(this.state.historicalDate).subtract(5, "years").format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult:AssetsByAccountQuery, { fetchMoreResult } :any) => {
        if (!fetchMoreResult) return previousResult;

        const previousAssets = previousResult.assets
        const newAssets = fetchMoreResult.assets

        if (previousAssets?.__typename !== "Manager" || this.state.currentState.assets?.__typename !== "Manager"){
          return
        }

        const returnedState = {
          assets: {
            id: this.state.currentState.assets?.id,
            accountTurnover: uniqBy([...(previousAssets.accountTurnover || []), ...newAssets.accountTurnover], 'quarterEndDate'),
            accountRanges: uniqBy([...(previousAssets.accountRanges || []), ...newAssets.accountRanges], 'quarterEndDate'),
            largestAccounts: uniqBy([...(previousAssets.largestAccounts || []), ...newAssets.largestAccounts], 'quarterEndDate'),
            managerTypes: newAssets.managerTypes,
            __typename: previousAssets.__typename
          },
        }
        const currentState = {
          assets: {
            id: this.state.currentState.assets?.id,
            accountTurnover: uniqBy([...(this.state.currentState.assets.accountTurnover || []), ...newAssets.accountTurnover],'quarterEndDate'),
            accountRanges: uniqBy([...(this.state.currentState.assets.accountRanges || []), ...newAssets.accountRanges], 'quarterEndDate'),
            largestAccounts: uniqBy([...(this.state.currentState.assets.largestAccounts || []), ...newAssets.largestAccounts], 'quarterEndDate'),
            managerTypes: newAssets.managerTypes,
            __typename: previousAssets.__typename
          },
        }

        this.setState({
          initialState: returnedState,
          currentState: currentState,
          historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
          loadingMore: false
        })

        return returnedState;
      }
    })
  }

  render() {
    const { editMode, historicView, searchDate } = this.props

    let accountTurnover:ManagerAccountTurnover[] = get(this.state.currentState, 'assets.accountTurnover', [])
    let largestAccounts:ManagerAssetsByAccountsLargestFragment[] = get(this.state.currentState, 'assets.largestAccounts', [])
    let accountRanges:ManagerAccountRanges[] = get(this.state.currentState, 'assets.accountRanges', [])

    if (!historicView) {
      accountTurnover = !isEmpty(accountTurnover) ? [find(accountTurnover, { quarterEndDate: searchDate }) as ManagerAccountTurnover] : []
      largestAccounts = !isEmpty(largestAccounts) ? [find(largestAccounts, { quarterEndDate: searchDate }) as ManagerAssetsByAccountsLargestFragment] : []
      accountRanges = !isEmpty(accountRanges) ? [find(accountRanges, { quarterEndDate: searchDate }) as ManagerAccountRanges] : []
    }

    const managerTypes = get(this.state.currentState, 'assets.managerTypes', [])
    const managerType = managerTypeBreakdown(managerTypes)
    
    const tooltipsData = this.props.data?.tooltips || []
    const defaultProps = { editMode, historicView, searchDate, historicalDate: this.state.historicalDate, name: this.props.data.assets?.name, tooltipsData}
    return (
      <div className="pane pane-table">
        <div className="pane-title-alt">
          <h3 id="assetsAccountsTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsAccountsTooltip">
              Accounts
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
                id={"generic-tooltip-assetsAccountsTooltip"}
              />
              {/* Slideout, ignore for now */}
              {/* <UncontrolledTooltip
                className="generic-tooltip"
                target={"generic-tooltip-assetsAccountsTooltip"}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                  defaultProps.tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerAssetsAccountsTooltipsIdsMapping["assetsAccountsTooltip" as ManagerAssetsAccountsTooltipType] || "")?.text || ""
                  )}}
                delay={{hide: 1000, show: 0}}
              /> */}
            </div>
          </h3>
        </div>
        <Row>
          <Col md="12">
          </Col>

        </Row>
        <Row>
          <Col md="6"><h4 className='text-center'>Accounts &amp; Assets Gained</h4></Col>
          <Col md="6"><h4 className='text-center'>Accounts &amp; Assets Lost</h4></Col>
        </Row>
        <AccountsGainedLostTable
          accountTurnover={accountTurnover}
          onChange={((newTable:ManagerAccountTurnover[]) => this.handleInputChange('accountTurnover', newTable))}
          {...defaultProps}
        />
        <Row>
          <Col md={6} className="mt-4">
            <h4 className='text-center'>5 Largest Accounts by Aggregate Size ($M)</h4>
            <AccountsLargestTable
              largestAccounts={largestAccounts}
              onChange={((newTable:ManagerAssetsByAccountsLargestFragment[]) => this.handleInputChange('largestAccounts', newTable))}
              {...defaultProps}
            />
          </Col>
          <Col md={6} className="mt-4">
            <h4 className='text-center'>Assets &amp; Accounts by Mandate Size ($M)</h4>
            <AccountsManadateSizeTable
              accountRanges={accountRanges}
              onChange={((newTable:ManagerAccountRanges[]) => this.handleInputChange('accountRanges', newTable))}
              managerType={managerType}
              {...defaultProps}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

const AccountsGainedFields:FormInputField[] = [
  { property: "gained.accounts", label: "", type: "number", placeholder: '', required: false  },
  { property: "gained.assets", label: "", type: "float", subtype: "currency", placeholder: '' },
  { property: "gained.percentChange", label: "", type: "float", subtype: 'percent', placeholder: '' },
]
const AccountsLostFields:FormInputField[] = [
  { property: "lost.accounts", label: "", type: "number", placeholder: ''  },
  { property: "lost.assets", label: "", type: "float", subtype: "currency", placeholder: '' },
  { property: "lost.percentChange", label: "", type: "float", subtype: 'percent', placeholder: '' },
]

const AccountsGainedLostTable = ({ accountTurnover, editMode, historicView, onChange, searchDate, historicalDate, name, tooltipsData}: {
  accountTurnover:ManagerAccountTurnover[],
  editMode: boolean,
  historicView: boolean,
  searchDate: string,
  historicalDate: Moment,
  name: string | undefined,
  tooltipsData: Maybe<TooltipFragment>[],
  onChange: (newTable:ManagerAccountTurnover[]) => void
} ) => {

  const generateBlankTurnover = (date:string):ManagerAccountTurnover => {
    return {
      __typename: 'ManagerAccountTurnover',
      quarterEndDate: date,
      gained: {
        __typename: 'ManagerAccountTurnoverItem',
        accounts: null,
        assets: null,
        percentChange: null
      },
      lost: {
        __typename: 'ManagerAccountTurnoverItem',
        accounts: null,
        assets: null,
        percentChange: null
      }
    }
  }

  if (accountTurnover.length === 0) {
    let blankData:ManagerAccountTurnover = generateBlankTurnover(searchDate)

    accountTurnover.push(blankData)
  } else {
    accountTurnover = sortBy(accountTurnover, [a => a?.quarterEndDate]).reverse()
  }

  const handleChange = (quarter:any, property:string, value:any) => {
    const dateIndex = findIndex(accountTurnover, (acc) => { return acc.quarterEndDate === quarter })
    let newTable = cloneDeep(accountTurnover)

    if (dateIndex < 0) {
      let blankTurnover = generateBlankTurnover(quarter)
      set(blankTurnover, property, value)
      newTable.push(blankTurnover)
    } else {
      newTable = iassign(
        newTable,
        [dateIndex],
        (acc:ManagerAccountTurnover | undefined) => {

          let newAcc = cloneDeep(acc)
          if (newAcc) {
            set(newAcc, property, value)
            return newAcc
          }
        }
      )
    }
    onChange(newTable)
  }

  const accountsGainedRowEntry = (turnover:ManagerAccountTurnover, row:number) => {
    return (
      <tr key={`aseet-turnover-${turnover.quarterEndDate}`}>
        { historicView ? (<td className='text-nowrap'>{turnover.quarterEndDate}</td>) : <></>}
        {AccountsGainedFields.map(({property, label, type, subtype, placeholder, optionSource, readonly, subClasses, required}, idx) => {
          let propertyVal, onChangeCallback
          propertyVal = get(turnover, property)
          propertyVal = isNil(propertyVal) ? "" : propertyVal
          onChangeCallback = (value:any) => { handleChange(turnover.quarterEndDate, property, value)}
          if (property === "quarterEndDate" && !historicView) return <></>

          return(
            <td key={idx}>
              <FormInput
                showZero={true}
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={idx + (row*AccountsGainedFields.length)}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
                subClasses={subClasses}
                required={required}
              />
            </td>
          )
        })}
      </tr>
    )
  }

  const accountsLostRowEntry = (turnover:ManagerAccountTurnover, row:number) => {
    return (
      <tr key={`aseet-turnover-${turnover.quarterEndDate}`}>
        {AccountsLostFields.map(({property, label, type, subtype, placeholder, optionSource, readonly, subClasses}, idx) => {
          let propertyVal, onChangeCallback
          propertyVal = get(turnover, property)
          onChangeCallback = (value:any) => { handleChange(turnover.quarterEndDate, property, value)}
          if (property === "quarterEndDate" && !historicView) return <></>

          return(
            <td key={idx}>
              <FormInput
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={idx + (row*AccountsLostFields.length)}
                editMode={editMode}
                showZero={true}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
                subClasses={subClasses}
              />
            </td>
          )
        })}
      </tr>
    )
  }

  const allDates = listQuarters(historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

  return (
    <Row>
      <Col md={6}>
        <Table hover className="table-bordered-internal exportable" data-export-name={`${name} Accounts & Assets Gained`}>
          <thead>
            <tr className="row-border-olive-100">
              { historicView ? <th>Date</th> : <></> }
              <th>Accounts</th>
              <th>Assets ($M)</th>
              <th id="assetsPercentGainedTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="assetsPercentGainedTooltip">
                  % Gained
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                    id={"generic-tooltip-assetsPercentGainedTooltip"}
                  />
                  <UncontrolledTooltip
                    className="generic-tooltip"
                    target={"generic-tooltip-assetsPercentGainedTooltip"}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                      tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerAssetsAccountsTooltipsIdsMapping["assetsPercentGainedTooltip" as ManagerAssetsAccountsTooltipType] || "")?.text || ""
                      )}}
                    delay={{hide: 1000, show: 0}}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            { historicView &&
              allDates.map((date:string, row:number) => {
                let turnover = accountTurnover.find(t => t.quarterEndDate === date)
                if (isNil(turnover)){
                  turnover = generateBlankTurnover(date)
                }
                return accountsGainedRowEntry(turnover, row)
              })
            }

            { !historicView &&
              accountTurnover.map( (turnover:ManagerAccountTurnover, row:number) => {
                return accountsGainedRowEntry(turnover, row)
              })
            }
          </tbody>
        </Table>
      </Col>
      <Col md={6}>
        <Table hover className="table-bordered-internal exportable" data-export-name={`${name} Accounts & Assets Lost`}>
          <thead>
            <tr className="row-border-olive-100">
              <th>Accounts</th>
              <th>Assets ($M)</th>
              <th id="assetsPercentLostTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="assetsPercentLostTooltip">
                  % Lost
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                    id={"generic-tooltip-assetsPercentLostTooltip"}
                  />
                  <UncontrolledTooltip
                    className="generic-tooltip"
                    target={"generic-tooltip-assetsPercentLostTooltip"}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                      tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerAssetsAccountsTooltipsIdsMapping["assetsPercentLostTooltip" as ManagerAssetsAccountsTooltipType] || "")?.text || ""
                      )}}
                    delay={{hide: 1000, show: 0}}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          { historicView &&
              allDates.map((date:string, row:number) => {
                let turnover = accountTurnover.find(t => t.quarterEndDate === date)
                if (isNil(turnover)){
                  turnover = generateBlankTurnover(date)
                }

                return accountsLostRowEntry(turnover, row)
              })
            }

            { !historicView &&
              accountTurnover.map( (turnover:ManagerAccountTurnover, row:number) => {
                return accountsLostRowEntry(turnover, row)
              })
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

const AccountsLargestFields:FormInputField[] = [
  { property: "type.code", label: "", type: "select", placeholder: '', subtype: "single", optionSource: "ClientType"},
  { property: "size", label: "", type: "float", subtype: 'currency', placeholder: '' },
]

type ManagerAssetsByAccountsLargestFragment = {
  __typename: 'ManagerLargestAccounts';
  quarterEndDate?: Maybe<Scalars['Date']>;
  accounts?: Maybe<Maybe<{
    __typename: 'ManagerLargestAccountItem';
    rank: number;
    size?: number;
    type: {
        __typename: 'ClientTypeLookup';
        code: any;
        value?: Maybe<string>;
    };
  }>[]> | undefined;
}

const AccountsLargestTable = ({largestAccounts, editMode, historicView, onChange, searchDate, historicalDate, tooltipsData}: {
  largestAccounts:ManagerAssetsByAccountsLargestFragment[],
  editMode:boolean,
  historicView: boolean,
  historicalDate: Moment,
  searchDate: string,
  tooltipsData: Maybe<TooltipFragment>[],
  onChange: (newTable:ManagerAssetsByAccountsLargestFragment[]) => void
}) => {

  const generateBlankAccounts = (date:string):ManagerAssetsByAccountsLargestFragment => {
    return {
      __typename: 'ManagerLargestAccounts',
      quarterEndDate: date,
      accounts: [1,2,3,4,5].map( key => {
        return {
          __typename: 'ManagerLargestAccountItem',
          rank: key,
          type: { __typename: 'ClientTypeLookup', code: 'Please Select', value: null },
          size: undefined}
        }
      )
    }
  }

  const fillRanks = (managerLargestAccounts: ManagerAssetsByAccountsLargestFragment): ManagerAssetsByAccountsLargestFragment => {
    let managerLargestAccountsEdited = cloneDeep(managerLargestAccounts) || {}
    let accountArray = cloneDeep(sortBy(managerLargestAccountsEdited.accounts, [a => a?.rank])) || []
    const maxRank = maxBy(managerLargestAccounts.accounts, (account) => account?.rank)

    for (let i = 1; i <= (Math.max(5, maxRank?.rank || 0)); i++){
      if (accountArray.length < i) {
        accountArray.push({
          __typename: 'ManagerLargestAccountItem',
          rank: i,
          type: { __typename: 'ClientTypeLookup', code: 'Please Select', value: null },
          size: undefined
        })
      } else if (accountArray[i - 1]?.rank !== i) {
        accountArray.splice(i-1,0,{
          __typename: 'ManagerLargestAccountItem',
          rank: i,
          type: { __typename: 'ClientTypeLookup', code: 'Please Select', value: null },
          size: undefined
        })
      }
    }
    managerLargestAccountsEdited.accounts = accountArray
    return managerLargestAccountsEdited
  }

  if (largestAccounts.length === 0) {
    let blankData:ManagerAssetsByAccountsLargestFragment = generateBlankAccounts(searchDate)

    largestAccounts.push(blankData)
  } else {
    largestAccounts = sortBy(largestAccounts, [a => a?.quarterEndDate]).reverse()
  }

  const handleChange = (quarter:any, rank:number, property:string, value:any) => {
    const dateIndex = findIndex(largestAccounts, (acc) => { return acc.quarterEndDate === quarter })
    let newTable = cloneDeep(largestAccounts)

    if (dateIndex < 0) {
      const updatedAccounts = generateBlankAccounts(quarter)
      const accIndex = findIndex(updatedAccounts.accounts, (acc) => { return acc?.rank === rank} )
      set(updatedAccounts, flatten(['accounts', accIndex, property.split('.')]), value)
      newTable.push(updatedAccounts)
    } else {
      const updatedAccounts = fillRanks(newTable[dateIndex])
      const accIndex = findIndex(updatedAccounts.accounts, (acc) => { return acc?.rank === rank} )

      const newAccount = iassign(
        updatedAccounts,
        ['accounts', accIndex],
        (acc:ManagerAssetsByAccountsLargestFragment | undefined) => {
          let newAcc = cloneDeep(acc)
          if (newAcc) {
            set(newAcc, property, value)
            return newAcc
          }
        }
      )
      newTable[dateIndex] = newAccount;
    }
    onChange(newTable)
  }

  const rowEntry = (accountSet:ManagerAssetsByAccountsLargestFragment, count:number) => {
    let accounts = accountSet.accounts

    if (!accounts) return <></>
    accounts = sortBy(accounts, [a => a?.rank])

    return accounts.map((account, subCount) => {
      if (!account) return <></>

      return (
        <tr key={subCount} className={classnames({ 'row-border-olive-100': historicView && count !== 0 && subCount === 0})}>
          { historicView ? (<td className='text-nowrap'>{accountSet.quarterEndDate}</td>) : <></>}
          <td>{ account.rank }</td>
          {AccountsLargestFields.map(({property, label, type, subtype, placeholder, optionSource, readonly, subClasses}, idx) => {
            let propertyVal, onChangeCallback
            propertyVal = get(account, property)
            onChangeCallback = (value:any) => { handleChange(accountSet.quarterEndDate, account.rank, property, value)}
            if (property === "quarterEndDate" && !historicView) return <></>

            return(
              <td key={`largest-${subCount}-${idx}`}>
                <FormInput
                  property={property}
                  displayName={label}
                  type={type}
                  showZero={true}
                  subtype={subtype}
                  placeholder={placeholder}
                  idx={idx + (subCount*AccountsLargestFields.length)}
                  editMode={editMode}
                  propertyVal={propertyVal}
                  updateValue={onChangeCallback}
                  optionSource={optionSource}
                  readonly={readonly}
                  subClasses={subClasses}
                />
              </td>
            )
          })}
        </tr>
      )
    })
  }

  const allDates = listQuarters(historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

  return (
    <Table className={'exportable'} data-export-name={`${name} 5 Largest Accounts by Aggregate Size`}>
      <thead>
        <tr className="row-border-olive-100">
          { historicView ? <th>Date</th> : <></> }
          <th id="assetsRankTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsRankTooltip">
              Rank
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                size="sm"
                id={"generic-tooltip-assetsRankTooltip"}
              />
              <UncontrolledTooltip
                className="generic-tooltip"
                target={"generic-tooltip-assetsRankTooltip"}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                  tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerAssetsAccountsTooltipsIdsMapping["assetsRankTooltip" as ManagerAssetsAccountsTooltipType] || "")?.text || ""
                  )}}
                delay={{hide: 1000, show: 0}}
              />
            </div>
          </th>
          <th>Account Type</th>
          <th className='text-nowrap'>Aggregate Account Size</th>
        </tr>
      </thead>
      <tbody>
      { historicView &&
          allDates.map((date:string, row:number) => {
            let account = largestAccounts.find(t => t.quarterEndDate === date)
            if (isNil(account)){
              account = generateBlankAccounts(date)
            }
            account = fillRanks(account)
            return rowEntry(account, row)
          })
        }

        { !historicView &&
          largestAccounts.map((accountSet: ManagerAssetsByAccountsLargestFragment, row: number) => {
            const account = fillRanks(accountSet)
            return rowEntry(account, row)
          })
        }
      </tbody>
    </Table>
  )
}

const AccountsMandateSizeFields:AccountRangeInputField[] = [
  { property: "assetsInRange", label: "", type: "float", subtype: "currency", placeholder: ''},
  { property: "accountsInRange", label: "", type: "number", placeholder: ''},
]

const AccountsMandateRangeToName:{[key:string]: string} = {
  "lessThanOneMillion": "< $1 Million",
  "oneMillionToTenMillion": "$1 Million - $10 Million",
  "tenMillionToOneHundredMillion": "$10 Million - $100 Million",
  "oneHundredMillionToFiveHundredMillion": "$100 Million - $500 Million",
  "moreThanFiveHundredMillion": "> $500 Million"
}

const AccountsManadateSizeTable = ({accountRanges, editMode, historicView, onChange, managerType, searchDate, historicalDate}:{
  accountRanges: ManagerAccountRanges[],
  editMode: boolean,
  historicView: boolean,
  historicalDate: Moment,
  onChange: (newTable:ManagerAccountRanges[]) => void,
  managerType: ManagerBreakdownType,
  searchDate: string
}) => {

  const generateBlankRange = (date:string):ManagerAccountRanges => {
    return {
      __typename: 'ManagerAccountRanges',
      quarterEndDate: date,
      lessThanOneMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ManagerAccountRangeItem' },
      oneMillionToTenMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ManagerAccountRangeItem' },
      tenMillionToOneHundredMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ManagerAccountRangeItem' },
      oneHundredMillionToFiveHundredMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ManagerAccountRangeItem' },
      moreThanFiveHundredMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ManagerAccountRangeItem' }
    }
  }

  if (accountRanges.length === 0) {
    let blankData:ManagerAccountRanges = generateBlankRange(searchDate)
    accountRanges.push(blankData)
  } else {
    accountRanges = sortBy(accountRanges, [a => a?.quarterEndDate]).reverse()
  }

  const handleChange = (quarter:any, rangeName:string, property:string, value:any) => {
    const rangeIndex = findIndex(accountRanges, (range) => { return range.quarterEndDate === quarter })
    let newTable = cloneDeep(accountRanges)

    if (rangeIndex < 0) {
      let blankRange = generateBlankRange(quarter)
      set(blankRange, [rangeName, property], value)
      newTable.push(blankRange)
    } else {
      newTable = iassign(
        accountRanges,
        [rangeIndex],
        (range:ManagerAccountRanges | undefined) => {

          let newRange = cloneDeep(range)
          if (newRange) {
            set(newRange, [rangeName,property], value)
            return newRange
          }
        }
      )
    }
    onChange(newTable)
  }

  const managerCheck = () => {
    if (managerType.RA || managerType.LGSH || managerType.HF || managerType.HFFOF) return true;
    return false
  }

  const rowEntry = (accountRange:ManagerAccountRanges, count:number) => {
    if (!accountRange) return <></>

    const { lessThanOneMillion, oneMillionToTenMillion, tenMillionToOneHundredMillion, oneHundredMillionToFiveHundredMillion, moreThanFiveHundredMillion } = accountRange

    const ranges = {lessThanOneMillion, oneMillionToTenMillion, tenMillionToOneHundredMillion, oneHundredMillionToFiveHundredMillion, moreThanFiveHundredMillion}

    return (
      <React.Fragment key={`account-range-set-${count}`}>
        {
          map(ranges, (rangeData, rangeName) => {
            return (
              <tr key={`account-ranges-${rangeName}`} className={classnames({ 'row-border-olive-100': historicView && rangeName === "lessThanOneMillion"})}>
                { historicView ? (<td key={`${rangeName}-date`} className='text-nowrap'>{accountRange.quarterEndDate}</td>) : <></>}
                <td key={`${rangeName}-name`} className='text-left'>{ AccountsMandateRangeToName[rangeName] }</td>

                {AccountsMandateSizeFields.map(({property, label, type, subtype, placeholder, optionSource, readonly }, idx) => {
                  let propertyVal, onChangeCallback
                  propertyVal = get(accountRange, `${rangeName}.${property}`)
                  onChangeCallback = (value:any) => { handleChange(accountRange.quarterEndDate, rangeName, property, value)}

                  if (property === "accountsInRange" && !managerCheck()) { return <></> }

                  return (<td key={`${rangeName}-${idx}`}>
                    <FormInput
                      property={`${rangeName}.${property}`}
                      type={type}
                      showZero={true}
                      subtype={subtype}
                      placeholder={placeholder}
                      idx={parseFloat(`${count}${idx}`)}
                      editMode={editMode}
                      propertyVal={propertyVal}
                      updateValue={onChangeCallback}
                      optionSource={optionSource}
                      readonly={readonly}
                    />
                  </td>)
                })}
              </tr>
            )
          })
        }
      </React.Fragment>
    )
  }
  const allDates = listQuarters(historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

  return (
    <Table className={'exportable'} data-export-name={`${name} Assets & Accounts by Mandate Size`}>
      <thead>
        <tr className="row-border-olive-100">
          { historicView ? <th>Date</th> : <></> }
          <th>Range</th>
          <th className='text-nowrap'>Total Assets in Range</th>
          { managerCheck() ? <th className='text-nowrap'>Total Accounts in Range</th> : <></> }
        </tr>
      </thead>
      <tbody>
        { historicView &&
          allDates.map((date:string, row:number) => {
            let account = accountRanges.find(t => t.quarterEndDate === date)
            if (isNil(account)){
              account = generateBlankRange(date)
            }

            return rowEntry(account, row)
          })
        }

        { !historicView &&
          accountRanges.map((accountRange:ManagerAccountRanges, count:number) => {
            return rowEntry(accountRange, count)
          })
        }
      </tbody>
    </Table>
  )
}

export default ManagerAssetsAccounts