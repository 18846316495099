import classnames from 'classnames'
import { get, set, cloneDeep, unset, has } from 'lodash'
import React, { Component } from 'react'
import { Table, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import iassign from 'immutable-assign'
import moment from 'moment'
import omitDeep from 'omit-deep-lodash'

import {  ManagerAssetsByVehicleQuery, RealAssetsStrategyCode, RealAssetsVehicleTypeCode, RealAssetsAssetsByStrategyType, ManagerRealAssetsAssetsByStrategyTypeInput, Strategy, StrategyCode, ManagerType, VehicleCategoryCode, VehicleCategoryLookup} from '../../../__generated__/graphql'
import { listQuarters } from "../../../helpers/helpers"
import { appDate } from '../../../Context/CalendarContext'
import { DATE_API_FORMAT, FormInputField } from '../../../helpers/constant'
import { FormInput } from '../../ui/Forms/FormInput'
import { GroupedAsset, GroupedAssets, AssetsByAssetClassTableProps, HistoricalAssetsByAssetClassTableProps, HistoricalGroupedAssets } from '../ManagerAssetsVehicles'

const firstHistoricalDate = moment(appDate).subtract(5,"years")

const convertRAByStrategyCellToInput = (updatedCell:RealAssetsAssetsByStrategyType):ManagerRealAssetsAssetsByStrategyTypeInput => {
  let newInputCell = omitDeep(updatedCell, '__typename')
  set(newInputCell, 'strategy', newInputCell.strategy.code)
  set(newInputCell, 'vehicleType', newInputCell.vehicleType.code)
  unset(newInputCell, 'updatedDate')
  return newInputCell
}

type HistoricalDisplayValueType = "assetsUnderManagement.gross" | "assetsUnderManagement.net" | "numberOfAccounts"

const vehicleTypeMap:{[key:string]: string} = {
  "co": "Commingled Open End",
  "ns": "Non-Discretionary Separate Account",
  "c": "Commingled Closed End",
  "ds": "Discretionary Separate Account"
}

export class RealAssetsAssetsByStrategyTypeTable extends Component<AssetsByAssetClassTableProps, { currentState: GroupedAssets, initialState: GroupedAssets }> {
  constructor(props: any) {
    super(props)

    const assets:RealAssetsAssetsByStrategyType[] = get(props.data, "assets.realAssetsAssetsByStrategyType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:RealAssetsAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let vehicleType = entry.vehicleType.code

      if (strategyType && vehicleType) {
        set(groupedData, [strategyType, vehicleType], entry)
      }
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData
    }
  }

  static getDerivedStateFromProps(props: AssetsByAssetClassTableProps, state:{ currentState: GroupedAsset, initialState: GroupedAsset }) {
    const assets:RealAssetsAssetsByStrategyType[] = get(props.data, "assets.realAssetsAssetsByStrategyType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:RealAssetsAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let vehicleType = entry.vehicleType.code

      if (strategyType && vehicleType) {
        set(groupedData, [strategyType, vehicleType], entry)
      }
    })
    return {
      currentState: props.editMode ? state.currentState : groupedData,
      initialState: groupedData,
    }
  }

  handleInputChange = (
    value: any,
    property: string,
    vehicleType:RealAssetsVehicleTypeCode,
    strategyType: RealAssetsStrategyCode
  ) => {
    let updatedCell:RealAssetsAssetsByStrategyType
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:GroupedAssets = cloneDeep(selectedRow)
        let cell:RealAssetsAssetsByStrategyType
        let selectedCell = get(selectedRow, [strategyType, vehicleType]) as RealAssetsAssetsByStrategyType
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            strategy: {code: strategyType as RealAssetsStrategyCode, __typename: "RealAssetsStrategyLookup"},
            vehicleType: {code: vehicleType as RealAssetsVehicleTypeCode, __typename: "RealAssetsVehicleTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            __typename: "RealAssetsAssetsByStrategyType",
          }
        }

        set(cell, property, value)
        set(row, [strategyType, vehicleType], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () => {
      this.props.onChange(convertRAByStrategyCellToInput(updatedCell),"RealAssetsAssetsByStrategyType")
    })
  }




  render() {
    const { editMode, managerType} = this.props
    const rowOrder:{ code: RealAssetsStrategyCode, display: string}[] = [
      { code: RealAssetsStrategyCode.CORE, display: 'Core'},
      { code: RealAssetsStrategyCode.OPT, display: 'Opportunistic' },
      { code: RealAssetsStrategyCode.REIT, display: 'Real Estate Securities' },
      { code: RealAssetsStrategyCode.TIMB, display: 'Timberland' },
      { code: RealAssetsStrategyCode.FARM, display: 'Farmland' },
      { code: RealAssetsStrategyCode.RED, display: 'Real Estate Debt'},
      { code: RealAssetsStrategyCode.INFRA, display: 'Infrastructure'},
      { code: RealAssetsStrategyCode.VAL, display: 'Value Added'},
      { code: RealAssetsStrategyCode.DIV, display: 'Diversified incl. Core' },
      { code: RealAssetsStrategyCode.CORP, display: 'Core Plus' },
    ]

    return(
      <>
        <Row>
          <Col md={10}>
            <div className="table-container">
            <Table hover className="table-bordered-internal exportable" data-export-name={"Discretionary & non-discretionary Separate Account"}>
              <thead>
                <tr className="table-title row-border-olive-100">
                  <th className="border-bottom-0"></th>
                  <th colSpan={3} className="py-2">Discretionary Separate Account</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th colSpan={3} className="py-2">Non-Descretionary Separate Account</th>
                </tr>
                <tr>
                  <th>Strategy</th>
                  <th className="text-right"># Accounts</th>
                  <th className="text-right width-120">Gross Assets $(M)</th>
                  <th className="text-right width-120">Net Assets $(M)</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th className="text-right"># Accounts</th>
                  <th className="text-right width-120">Gross Assets $(M)</th>
                  <th className="text-right width-120">Net Assets $(M)</th>
                </tr>
              </thead>
              <tbody>
                {rowOrder.map((row, idx) => {
                  let entry = get(this.state.currentState, [row.code as string])
                  return(
                    <AssetClassRow
                      data={entry}
                      row={idx}
                      key={idx}
                      editMode={editMode}
                      clientType={row}
                      updateValue={(value:any, property:string, vehicleType:RealAssetsVehicleTypeCode) => this.handleInputChange(value, property, vehicleType, row.code)}
                      inputList={RealAssetsDiscretionaryListInputList}
                    />
                  )
                })}
              </tbody>
            </Table>
          </div>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div className="table-container">
            <Table hover className="table-bordered-internal exportable" data-export-name={"Commingled -open & closed end"}>
              <thead>
                <tr className="table-title row-border-olive-100">
                  <th className="border-bottom-0"></th>
                  <th colSpan={3} className="py-2">Commingled - Closed-End</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th colSpan={3} className="py-2">Commingled - Open-End</th>
                </tr>
                <tr>
                  <th>Strategy</th>
                  <th className="text-right"># Accounts</th>
                  <th className="text-right width-120">Gross Assets $(M)</th>
                  <th className="text-right width-120">Net Assets $(M)</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th className="text-right"># Accounts</th>
                  <th className="text-right width-120">Gross Assets $(M)</th>
                  <th className="text-right width-120">Net Assets $(M)</th>
                </tr>
              </thead>
              <tbody>
                {rowOrder.map((row, idx) => {
                  let entry = get(this.state.currentState, [row.code as string])
                  return(
                    <AssetClassRow
                      data={entry}
                      row={idx}
                      key={idx}
                      editMode={editMode}
                      clientType={row}
                      updateValue={(value:any, property:string, vehicleType:RealAssetsVehicleTypeCode) => this.handleInputChange(value, property, vehicleType, row.code)}
                      inputList={RealAssetsCommingledListInputList}
                    />
                  )
                })}
              </tbody>
            </Table>
          </div>
          </Col>
        </Row>
      </>
    )
  }
}

interface AssetClassRowProps {
  data: GroupedAsset
  row: number
  editMode: boolean
  clientType: {code: string, display: string}
  updateValue: (value:any, property:string, vehicleType:RealAssetsVehicleTypeCode) => void
  managerType?: ManagerType
  historicalList?: boolean
  inputList: RealAssetsInputField[]
}

interface RealAssetsInputField extends FormInputField  {
  // strategyType?: RealAssetsStrategyCode,
  vehicleType?:RealAssetsVehicleTypeCode
}

const RealAssetsDiscretionaryListInputList:RealAssetsInputField[] = [
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: RealAssetsVehicleTypeCode.ds },
  { property: "assetsUnderManagement.gross", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.ds},
  { property: "assetsUnderManagement.net", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.ds},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: RealAssetsVehicleTypeCode.ns  },
  { property: "assetsUnderManagement.gross", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.ns},
  { property: "assetsUnderManagement.net", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.ns},
]

const RealAssetsCommingledListInputList:RealAssetsInputField[] = [
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: RealAssetsVehicleTypeCode.c },
  { property: "assetsUnderManagement.gross", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.c},
  { property: "assetsUnderManagement.net", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.c},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: RealAssetsVehicleTypeCode.co  },
  { property: "assetsUnderManagement.gross", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.co},
  { property: "assetsUnderManagement.net", label: "", type: "number", subtype: "currency", vehicleType: RealAssetsVehicleTypeCode.co},
]

const AssetClassRow = ({data, row, editMode, clientType, updateValue, managerType, historicalList, inputList}: AssetClassRowProps) => {
  return (
    <tr key={`AssetsByVehicle-${row}`}>
      <td className="text-nowrap text-left">
        { clientType.display }
      </td>
      {
        inputList.map(({property, label, type, subtype, vehicleType}, idx) => {
          if(type === "boundary"){
            return (
              <React.Fragment key={idx}>
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
              </React.Fragment>
            )
          }

          let entry = get(data, [vehicleType as string])
          let propertyVal = get(entry, property.split('.'))
          let onChangeCallback = (value:any) => vehicleType ? updateValue(value, property, vehicleType) : null


          return (
            <td key={idx} className="text-right">
              <FormInput
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                idx={`RealAssetsByStrategy-${property}.${row}.${vehicleType}`}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
              />
            </td>
          )
        })
      }
    </tr>
  )
}

interface HistoricalRealAssetsAssetsByStrategyTypeTableState {
  currentState: HistoricalGroupedAssets
  initialState: HistoricalGroupedAssets
  displayValue: HistoricalDisplayValueType
  vehicleType: RealAssetsVehicleTypeCode
  historicalDate: moment.Moment
  dropdownOpen: boolean
}
export class HistoricalRealAssetsAssetsByStrategyTypeTable extends Component<HistoricalAssetsByAssetClassTableProps, HistoricalRealAssetsAssetsByStrategyTypeTableState> {

  constructor(props: any) {
    super(props)

    const assets:RealAssetsAssetsByStrategyType[] = get(props.data, "assets.realAssetsAssetsByStrategyType", [])
    let groupedData:HistoricalGroupedAssets = {}
    assets.map((entry:RealAssetsAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate
      let vehicleType = entry.vehicleType.code

      if (strategyType && vehicleType) {
        set(groupedData, [vehicleType, quarterEndDate, strategyType], entry)
      }
    })
    this.state = {
      currentState: groupedData,
      initialState: groupedData,
      displayValue: "assetsUnderManagement.gross",
      vehicleType: RealAssetsVehicleTypeCode.ds,
      historicalDate: moment(firstHistoricalDate),
      dropdownOpen: false
    }
  }

  static getDerivedStateFromProps(props: HistoricalAssetsByAssetClassTableProps, state:HistoricalRealAssetsAssetsByStrategyTypeTableState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const assets:RealAssetsAssetsByStrategyType[] = get(props.data, "assets.realAssetsAssetsByStrategyType", [])
    let groupedData:HistoricalGroupedAssets = {}
    assets.map((entry:RealAssetsAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate
      let vehicleType = entry.vehicleType.code

      if (strategyType && vehicleType) {
        set(groupedData, [vehicleType, quarterEndDate, strategyType], entry)
      }
    })
    return {
      currentState: resetDate && !props.editMode ? groupedData : state.currentState,
      initialState: resetDate ? groupedData : state.initialState,
      vehicleType: state.vehicleType,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
      dropdownOpen: state.dropdownOpen
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    quarterEndDate: string,
    strategyType: RealAssetsStrategyCode,
    vehicleType: RealAssetsVehicleTypeCode
  ) => {
    let updatedCell:RealAssetsAssetsByStrategyType
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:HistoricalGroupedAssets = cloneDeep(selectedRow)
        let cell:RealAssetsAssetsByStrategyType
        let selectedCell = get(selectedRow, [vehicleType, quarterEndDate, strategyType]) as RealAssetsAssetsByStrategyType
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            strategy: {code: strategyType as RealAssetsStrategyCode, __typename: "RealAssetsStrategyLookup"},
            vehicleType: {code: vehicleType as RealAssetsVehicleTypeCode, __typename: "RealAssetsVehicleTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: quarterEndDate,
            __typename: "RealAssetsAssetsByStrategyType",
          }
        }

        set(cell, property, value)
        set(row, [vehicleType, quarterEndDate, strategyType], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () => {
      this.props.onChange(convertRAByStrategyCellToInput(updatedCell), "RealAssetsAssetsByStrategyType")
    })
  }
  setVehicleType = (vehicleType:RealAssetsVehicleTypeCode) => {
    this.setState({
      vehicleType: vehicleType
    })
  }
  setDisplayValue = (displayValue:HistoricalDisplayValueType) => {
    this.setState({
      displayValue: displayValue
    })
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  loadMore = (fetchMoreResult:ManagerAssetsByVehicleQuery) => {
    const newInitialState = this.state.initialState
    const newCurrentState = cloneDeep(this.state.currentState)

    const newAssets:RealAssetsAssetsByStrategyType[] = get(fetchMoreResult, "assets.realAssetsAssetsByStrategyType", [])

    newAssets.map((entry:RealAssetsAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate
      let vehicleType = entry.vehicleType.code

      if (strategyType && !has(this.state.currentState, [vehicleType, quarterEndDate, strategyType])) {
        set(newInitialState, [vehicleType, quarterEndDate, strategyType], entry)
        set(newCurrentState, [vehicleType, quarterEndDate, strategyType], entry)
      }
    })

    this.setState({
      initialState: newInitialState,
      currentState: newCurrentState,
      historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
    })
  }

  render() {
    const { vehicleType, displayValue} = this.state
    const headingOrder:{ code: RealAssetsStrategyCode, display: string}[] = [
        { code: RealAssetsStrategyCode.CORE, display: 'Core'},
        { code: RealAssetsStrategyCode.OPT, display: 'Opportunistic' },
        { code: RealAssetsStrategyCode.REIT, display: 'Real Estate Securities' },
        { code: RealAssetsStrategyCode.TIMB, display: 'Timberland' },
        { code: RealAssetsStrategyCode.FARM, display: 'Farmland' },
        { code: RealAssetsStrategyCode.RED, display: 'Real Estate Debt'},
        { code: RealAssetsStrategyCode.INFRA, display: 'Infrastructure'},
        { code: RealAssetsStrategyCode.VAL, display: 'Value Added'},
        { code: RealAssetsStrategyCode.DIV, display: 'Diversified incl. Core' },
        { code: RealAssetsStrategyCode.CORP, display: 'Core Plus' },
      ]


    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))
    const heading = () =>{
      return(
      <div className="w-100 d-flex justify-content-between">
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret>
            {vehicleTypeMap[this.state.vehicleType]}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.setVehicleType(RealAssetsVehicleTypeCode.co)}>{vehicleTypeMap[RealAssetsVehicleTypeCode.co]}</DropdownItem>
            <DropdownItem onClick={() => this.setVehicleType(RealAssetsVehicleTypeCode.ns)}>{vehicleTypeMap[RealAssetsVehicleTypeCode.ns]}</DropdownItem>
            <DropdownItem onClick={() => this.setVehicleType(RealAssetsVehicleTypeCode.c)}>{vehicleTypeMap[RealAssetsVehicleTypeCode.c]}</DropdownItem>
            <DropdownItem onClick={() => this.setVehicleType(RealAssetsVehicleTypeCode.ds)}>{vehicleTypeMap[RealAssetsVehicleTypeCode.ds]}</DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement.gross" })}  onClick={() => this.setDisplayValue("assetsUnderManagement.gross")}>Gross Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement.net" })}  onClick={() => this.setDisplayValue("assetsUnderManagement.net")}>Net Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>)
    }


    return (
      <>
        <div className="pane pane-table">
          <Table hover className="table-condensed">
            <thead>
              <tr>
                <td colSpan={1000}>{heading()}</td>
              </tr>
            </thead>
          </Table>
          <div className="table-container">
            <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={"Real Assets by Strategy Type"}>
              <thead>
                <tr>
                  <th>Date</th>
                  {headingOrder.map((heading) => {
                    return(<th key={heading.code}>{heading.display}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {allDates.map((date:string, row:number) => {
                  return (
                    <tr key={`RealAssetsAssetsByStrategyType-${displayValue}-${date}`} className="fadein">
                      <td className="nowrap">
                        {date}
                      </td>
                      {headingOrder.map((heading, idx) => {
                        let row = get(this.state.currentState, [vehicleType, date, heading.code])
                        let propertyVal = get(row, displayValue)

                        return(
                          <td key={`RealAssetsAssetsByStrategyType-${idx}`}>
                            <FormInput
                              property={displayValue}
                              displayName={""}
                              type={"number"}
                              subtype={displayValue === "numberOfAccounts" ? "" : "currency" }
                              placeholder={""}
                              idx={`RealAssetsAssetsByStrategyType-${date}-${heading.code}`}
                              editMode={this.props.editMode}
                              propertyVal={propertyVal}
                              updateValue={(value:any) => this.handleInputChange(value,displayValue,date, heading.code, vehicleType)}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  )
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}

