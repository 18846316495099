import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'reactstrap'

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import {
  Compliance,
  Maybe,
  OrgUpdateFields,
  TooltipFragment,
  UpdateOrgInput,
  useManagerComplianceQuery,
  useUpdateManagerComplianceMutation
} from '../../__generated__/graphql'
import { FormInputField } from '../../helpers/constant'
import { getNewStateObject } from '../../helpers/helpers'
import { excludePropertyArray, reShapeObject } from '../../helpers/object'
import { FormInput } from '../ui/Forms/FormInput'
import PlaceHolder from './../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { useHistory } from 'react-router-dom'
import ErrorDisplay from '../Shared/ErrorDisplay'

interface Props {
  managerId: number
  auth: Auth
}

interface DisplayProps {
  compliance?: Maybe<Compliance>
  id: number
  auth?: Auth
  tooltips?: Maybe<TooltipFragment>[]
}

interface GovernanceInputField extends FormInputField {
  displayData?: {
    property: string
    value: any
  }
}

enum ManagerGovernanceTooltipType {
  crdNumberTooltip = 'crdNumberTooltip',
} 

const ManagerGovernanceTooltipsIdsMapping = {
  [ManagerGovernanceTooltipType.crdNumberTooltip]: 14,
}

const GovernanceInput: GovernanceInputField[] = [
  {
    property: "regulatoryBody.name",
    label: "Regulatory Body(s) that supervise the firm",
    type: "text",
    placeholder: "Name of Firm",
    required: true,
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "regulatoryBody.registrationNumber",
    label: "Regulatory Body(s) Registration Number(s)",
    type: "text",
    placeholder: "Registration Number",
    required: true,
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "regulatoryBody.crdNumber",
    label: "CRD #",
    type: "number",
    noFormat: true,
    required: true,
    placeholder: "CRD #",
    tooltip: {
      icon: "question-circle",
      id: "crdNumberTooltip"
    },
    subClasses: {
      labelClasses: "col-sm-8 pl-4",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "regulatoryBody.secNumber",
    label: "SEC 801 #",
    type: "number",
    noFormat: true,
    placeholder: "801 #",
    subClasses: {
      labelClasses: "col-sm-8 pl-4",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "interestedInSecuritiesBrokerage",
    label: "Firm, or Parent Firm, interest in a Securities Brokerage Firm",
    type: "radio",
    required: true,
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "interestedInRealEstateBrokerage",
    label: "Firm, or Parent Firm, interest in a Real Estate Brokerage Firm",
    type: "radio",
    required: true,
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "registeredInvestmentAdvisor",
    label: "Registered Investment Adviser",
    type: "radio",
    required: true,
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "secAudit.lastAuditDate",
    label: "Date of Last SEC Audit/Exam",
    required: true,
    type: "date",
    placeholder: "Exam Date",
    subClasses: {
      wrapperClasses: "pl-4",
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-2",
      inputClasses: "text-left"
    }
  },
  {
    property: "secAudit.scopeOfAudit",
    label: "Scope of Exam",
    type: "textarea",
    subClasses: {
      wrapperClasses: "pl-4",
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
  {
    property: "regulatoryEnforcement.elevatedToEnforcement",
    label: "Open Regulatory Investigations Escalated to Enforcement",
    type: "radio",
    required: true,
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "regulatoryEnforcement.latestEnforcementDate",
    label: "If Yes, Latest Enforcement Date",
    type: "date",
    required: true,
    placeholder: "Test Date",
    subClasses: {
      wrapperClasses: "pl-4",
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-2",
      inputClasses: "text-left"
    },
    displayData: {
      property: "regulatoryEnforcement.elevatedToEnforcement",
      value: true
    }
  },
  {
    property: "gipsCompliance.isGipsCompliant",
    label: "Firm GIPS Compliant",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "gipsCompliance.complianceDate",
    label: "Compliance Date",
    type: "date",
    required: true,
    placeholder: "Compliance Date",
    subClasses: {
      wrapperClasses: "pl-4",
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-2",
      inputClasses: "text-left"
    }
  },
  {
    property: "gipsCompliance.explanation",
    label: "If No, explain",
    type: "textarea",
    subClasses: {
      wrapperClasses: "pl-4",
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
  {
    property: "gipsCompliance.accountingFirmName",
    label: "GIPS Verification Accounting Firm or Auditor",
    type: "text",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "registeredCFA",
    label:
      "Firm Registered as Compliant with CFA Institute's Asset Manager Code (AMC) of Personal Conduct",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "litigation.litigation",
    label: "Prior or Pending Litigation",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "litigation.explanation",
    label: "If Yes, explain",
    type: "textarea",
    rows: 5,
    subClasses: {
      wrapperClasses: "pl-4",
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
  {
    property: "omissionsInsurance.covered",
    label: "Firm, or Parent Firm, has Errors and Omissions Insurance",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "omissionsInsurance.amount",
    label: "Amount",
    type: "number",
    subtype: "currency",
    noFormat: true,
    subClasses: {
      labelClasses: "col-sm-8 pl-4",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "liabilityInsurance.covered",
    label: "Firm, or Parent Firm, has Fiduciary Liability Insurance",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "liabilityInsurance.amount",
    label: "Amount",
    type: "number",
    subtype: "currency",
    noFormat: true,
    subClasses: {
      labelClasses: "col-sm-8 pl-4",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "bonded.covered",
    label: "Firm, or Parent Firm, is Bonded",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "bonded.amount",
    label: "Amount",
    type: "number",
    subtype: "currency",
    noFormat: true,
    subClasses: {
      labelClasses: "col-sm-8 pl-4",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "disasterRecovery.plan",
    label: "Firm has disaster recovery plan",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean"
    }
  },
  {
    property: "disasterRecovery.date",
    label: "Date of last test",
    type: "date",
    required: true,
    placeholder: "Test Date",
    subClasses: {
      wrapperClasses: "pl-4",
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4 pl-2",
      inputClasses: "text-left"
    }
  },
  {
    property: "disasterRecovery.summary",
    label: "Summary of Key Points Covered by Disaster Recovery Plan",
    type: "textarea",
    rows: 5,
    subClasses: {
      wrapperClasses: "pl-4",
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
]
const inlineHash: { [idx: string]: string } = {
  "1": "col-sm-6",
  "2": "col-sm-2",
  "3": "col-sm-2",
  "10": "col-sm-6",
  "11": "col-sm-3",
  "18": "col-sm-6",
  "19": "col-sm-3",
  "21": "col-sm-6",
  "22": "col-sm-3",
  "24": "col-sm-6",
  "25": "col-sm-3",
  "27": "col-sm-6",
  "28": "col-sm-3"
}

const getInitialData = (data: React.PropsWithChildren<DisplayProps>) => {
  const { compliance } = data
  return (compliance ? reShapeObject(_.cloneDeep(compliance)) : {}) as {
    [name: string]: any
  }
}

const Display: React.FC<DisplayProps> = data => {
  const { compliance, id, auth } = data
  const [currentState, setState] = useState(() => getInitialData(data))
  const [editMode, setEditMode] = useState(false)
  const history = useHistory()
  const [initialState, setInitial] = useState(
    compliance as { [name: string]: any }
  )
  const [updateManagerCompliance] = useUpdateManagerComplianceMutation()

  useEffect(() => {
    setState(getInitialData(data))
  }, [data])

  const handleSubmit = () => {
    if(!auth?.checkPermissions(["edit:manager"])){
      return
    }
    const complianceData = excludePropertyArray(currentState, ["__typename"])
    const updateData = {
      patch: {
        compliance: { ...complianceData }
      } as OrgUpdateFields
    }
    const input = { id, ...updateData } as UpdateOrgInput
    updateManagerCompliance({ variables: { input } })
      .then(result => {
        let data = result.data?.updateOrg?.org
        if (data) {
          console.log("updateManagerGovernance succeed")
          let formattedData = getInitialData(data)
          setInitial(formattedData)
          setState(formattedData)
          setEditMode(false)
        }
      })
      .catch(err => {
        console.log("Error updateGov", err.message)
        // throw new Error(`${err.message}`)
      })
  }
  const handleReset = () => {
    setState(initialState)
  }
  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    // prevent reloading if press enter key.
    event.preventDefault()
  }

  const toggleEditMode = () => {
    handleReset()
    setEditMode(!editMode)
  }

  const handleInputChange = (
    value: any,
    property: string,
    type: string = "string"
  ) => {
    let parseValue: any
    switch (type) {
      case "string":
        parseValue = value
        break
      default:
        parseValue = value
        break
    }
    const newState = getNewStateObject({
      state: currentState || {},
      newValue: parseValue,
      property,
      type
    }) as typeof currentState
    setState(newState)
  }

  return (
    <>
    <RouteLeavingGuard
      when={editMode}
      navigate={path => history.push(path)}
    />
    <Form onSubmit={handleEnterKeyDown}>
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-toolbar sticky-top">
              {auth?.checkPermissions(["edit:manager"]) &&
                <EditButtons editMode={editMode} setEditMode={toggleEditMode} onSubmit={handleSubmit} saving={false}/>
              }
            </div>
            <div className="pane">
              <Row>
                <Col sm="7" className="px-4">
                  <div className="row form-section-title headline underline small-font-size pb-2 mb-2 text-left">Governance</div>
                  {GovernanceInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        rows,
                        placeholder,
                        optionSource,
                        subClasses,
                        required,
                        noFormat,
                        tooltip,
                        displayData,
                      },
                      idx
                    ) => {
                      if (
                        (displayData && _.get(currentState, displayData.property) != displayData.value)
                      ) {
                        return
                      }
                      let propertyVal: any = _.get(currentState, property)
                      let onChangeCallback = (value: any) =>
                        handleInputChange(value, property, type)
                      let inline = inlineHash.hasOwnProperty(idx)

                      if (property === "") {
                        return (
                          <div
                            className="mx-0 my-0 px-0 py-0"
                            key={`manager-governance-${idx}`}
                            style={{
                              width: "100%",
                              flexBasis: "row wrap",
                              overflow: "hidden",
                              height: "0px"
                            }}
                          ></div>
                        )
                      }
                      const tooltipId = tooltip?.id? ManagerGovernanceTooltipsIdsMapping[tooltip?.id as ManagerGovernanceTooltipType]: ""
                      const tooltipText = tooltipId? data?.tooltips?.find((tooltip:any) => tooltip?.id === tooltipId)?.text : ""
                      return (
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          rows={rows}
                          editMode={editMode}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          inline={inline}
                          tooltip={tooltip? {...tooltip , text: tooltipText}: tooltip}
                          subClasses={subClasses}
                          // can't set css to hide reset cross in firefox, had to set required in view mode.
                          required={required}
                          noFormat={noFormat || false}
                        />
                      )
                    }
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
    </>
  )
}

const ManagerGovernance: React.FC<Props> = ({ managerId, auth }) => {
  const tooltip_ids = useMemo(() => Object.values(ManagerGovernanceTooltipsIdsMapping) || [], [])
  const { loading, error, data } = useManagerComplianceQuery({
    variables: { id: managerId, tooltip_ids },
  })
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              {auth.checkPermissions(["edit:manager"]) &&
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              }
            </div>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (error) {
    return (
      <div>
        <ErrorDisplay error={error}/>
      </div>
    )
  } else if (data) {
    if (data.org && data.org.__typename === "Manager") {
      return <Display auth={auth} tooltips={data?.tooltips || []} {...data.org} />
    } else {
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className="pane pane-toolbar">
                {auth.checkPermissions(["edit:manager"]) &&
                  <Button disabled className="ml-auto">
                    <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                    Edit
                  </Button>
                }
              </div>
              <div className="pane">
                <p>
                  Please check your managerId input{managerId} and try again.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
  } else {
    return <div>org not found</div>
  }
}

export default ManagerGovernance
