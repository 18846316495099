import classnames from "classnames"
import React, { EventHandler, useContext, useEffect, useState } from "react"
import { CustomInput, FormGroup, Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import _ from "lodash"
import { EditButtonContext } from "../../../Context/EditButtonContext"
import { InputTooltip } from "./TextFieldInput"

interface RadioSelectInputProps {
  idx: string | number
  property: string
  displayName: string
  propertyValue: string | boolean | undefined
  editMode: boolean
  updateValue: (event: any) => void
  wrapperClasses?: string
  labelClasses?: string
  inputWrapperClasses?: string
  inputClasses?: string
  required?: boolean
  tooltip?: InputTooltip
  options?: JSX.Element | {code: string|number, value: string}[] | null
  disabled?: boolean
}

// for radio single select only, not test for boolean yet
export const RadioSelectInput: React.FC<RadioSelectInputProps> = (props) => {
  const { idx, property, displayName, propertyValue, editMode, labelClasses, inputWrapperClasses, inputClasses, tooltip, disabled } = props
  let options: {code: string|number, value: string}[]
  let isBoolean: boolean = false
  if(!props?.options || !Array.isArray(props?.options)) {
    options = [{code: "true", value: "Yes"}, {code: "false", value: "No"}]
    isBoolean = true
  }else {
    options = props.options
  }

  let updateValue: EventHandler<any> = props.updateValue
  let booleanValue: boolean | null = propertyValue === "true" || propertyValue === true
  if (_.isNull(propertyValue) || _.isUndefined(propertyValue)) {
    // no preCheck if no data provided
    booleanValue = null
  }
  let required = props.required || false
  let shortProperty = property.split(".").pop()
  shortProperty = shortProperty ? shortProperty : property

  const handleBoolRadioClick = (value: string | boolean | null | undefined | number, updateValue: (value: any) => void) => {
    if (!shortProperty) {
      shortProperty = property
    }
    updateValue(value)
  }

  const { setError } = useContext(EditButtonContext)
  const [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || "")

  useEffect(() => {
    if (editMode && required) {
      if (propertyValue === undefined || propertyValue === null) {
        setWrapperClasses(wrapperClasses + " has-error")
        setError(`${idx}-${shortProperty}`, ["Presence"])
      } else {
        setError(`${idx}-${shortProperty}`, [])
        setWrapperClasses(wrapperClasses.replace(/has-error/g, ""))
      }
    } else if (!editMode) {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g, ""))
    }
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [editMode, idx, propertyValue, required])

  return (
    <FormGroup className={classnames("form-group row", wrapperClasses || "")} key={idx} name={`${idx}-${shortProperty}`} validate={{ required: { value: true, errorMessage: "Please select" } }}>
      <Label className={classnames("col-form-label", labelClasses || "", { "col-sm-4": !labelClasses })} htmlFor={`${idx}-${shortProperty}`} id={property}>
        <div
          className={classnames("d-flex w-100", { "tooltip-icon": tooltip, "justify-content-start": tooltip?.onClick})}
          id={tooltip ? tooltip.id : ""}
        >
          {displayName}
          {tooltip && (
            <FontAwesomeIcon
              icon={tooltip.icon as IconName}
              size="sm"
              onClick={() => {
                tooltip.onClick && tooltip.onClick()
              }}
            />
          )}
        </div>
      </Label>
      <div className={classnames("exportable-form-input d-flex align-items-center justify-content-between", inputWrapperClasses || "", { "col-sm-4": !inputWrapperClasses })}>
        {options?.map(({code, value}, index) => {
          return (
            <CustomInput
              className={classnames(inputClasses, "boolean-radio custom-control-inline", {
                hidden: false, // (!editMode && propertyValue !== true)
              })}
              key={`${idx}-${index}-${shortProperty}`}
              id={`${idx}-${index}-${shortProperty}`}
              bsSize="sm"
              type="radio"
              value={value}
              checked={isBoolean ? booleanValue?.toString() === code : propertyValue === code}
              onChange={() => {
                handleBoolRadioClick(code, updateValue)
              }}
              label={value}
              inline
              disabled={!editMode || disabled}
            />)
        })}
      </div>
    </FormGroup>
  )
}
