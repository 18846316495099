import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { default as classNames, default as classnames } from 'classnames'
import iassign from 'immutable-assign'
import { cloneDeep, compact, filter, find, findIndex, first, flatMap, get, isArray, map, remove, set, some, sortBy, uniqBy, uniqueId, xor } from 'lodash'
import moment, { Moment } from 'moment'
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, ButtonGroup, Col, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Nav, NavItem, NavLink, PopoverBody, Row, UncontrolledDropdown, UncontrolledPopover } from 'reactstrap'
import Auth from '../../../Auth/Auth'
import { appDate } from '../../../Context/CalendarContext'

import { EditButtonContext } from '../../../Context/EditButtonContext'
import { Client, ClientPlanOrderFragment, ClientPortfolioDetailComponentFragment, ClientPortfolioDetailComponentSettingsFragment, ClientPortfolioDetailLayoutFragment, ClientPortfolioDetailSectionFragment, ClientReportOrderFragment, ComponentApprovalCode, ComponentType, LayoutSectionType, Maybe, MeFragment, ReportCategoryCode, ReportListHeaderFragment, ReportsContactsFragment, ReportsFragment, ReportSimpleListFragment, ReportsListClientPortfolioFragment, useClientAllReportsQuery } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField } from '../../../helpers/constant'
import { listReportExpanded } from '../../../helpers/report'
import { StoplightEdit } from '../../Consultant/Dashboard/Stoplight'
import SearchAddStatic from '../../Search/SearchAddStatic'
import { FormInput } from '../../ui/Forms/FormInput'
import { section2Col, section2ColLeftSidebar, section2ColRightSidebar, section3Col, section3ColWithSidebars } from '../../ui/Icon'
import EmployeePicker from '../../ui/Pickers/EmployeePicker'
import ListPicker from '../../ui/Pickers/ListPicker'
import { AssetAllocationEdit } from '../Components/AssetAllocation'
import { AssetDistributionEdit } from '../Components/AssetDistribution'
import { AttributionEdit } from '../Components/Attribution'
import { ManagerPerformanceEdit } from '../Components/ManagerPerformance'
import { PerformanceComparisonEdit } from '../Components/PerformanceComparison'
import { TabOrderSelector } from '../Components/TabOrderSelector'
import { TextEdit } from '../Components/Text'
import { ReportApprovals } from './ReportApprovals'
import { IconMapping, selectedComponentProp } from './ReportComponent'

interface ReportEditProps {
  reportTabOrder: ClientReportOrderFragment[]
  setReportTabOrder: Dispatch<SetStateAction<ClientReportOrderFragment[]>>
  planTabOrder: ClientPlanOrderFragment[]
  setPlanTabOrder: Dispatch<SetStateAction<ClientPlanOrderFragment[]>>
  editedDraftLayout: ClientPortfolioDetailLayoutFragment | undefined
  selectedComponentId?: selectedComponentProp
  setSelectedComponentId: (value:selectedComponentProp) => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editNavbar: string
  setEditNavbar: (value:string) => void
  portfolio: ReportsListClientPortfolioFragment | ReportListHeaderFragment
  clientId: number
  reportId: number
  editMode: boolean
  report: ReportsFragment
  setReport: (value:React.SetStateAction<ReportsFragment>) => void
  user?: MeFragment
  list: listReportExpanded[]
  auth: Auth
}

export type AggregatedReportEditProps = {
  component: ClientPortfolioDetailComponentFragment
  portfolio: ReportsListClientPortfolioFragment | ReportListHeaderFragment
  handleInputChange: (value: any, property: string) => void
  clientId: number
  reportId: number
  report: ReportsFragment
  user?: MeFragment
  auth: Auth
  readonly?: boolean
}

const ReportEdit: React.FC<ReportEditProps> = ({ reportTabOrder, setReportTabOrder, planTabOrder, setPlanTabOrder, editedDraftLayout, selectedComponentId, setEditedDraftLayout, setSelectedComponentId, editNavbar, setEditNavbar, portfolio, clientId, reportId, editMode, report, setReport, user, list, auth}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const ComponentEditMapping: { [key in ComponentType]?: React.FC<AggregatedReportEditProps>} = {
    [ComponentType.AssetAllocation]: AssetAllocationEdit,
    [ComponentType.AssetDistribution]: AssetDistributionEdit,
    [ComponentType.Attribution]: AttributionEdit,
    [ComponentType.ManagerPerformance]: ManagerPerformanceEdit,
    [ComponentType.PerformanceComparison]: PerformanceComparisonEdit,
    [ComponentType.Stoplight]: StoplightEdit,
    [ComponentType.Text]: TextEdit,
  }
  const allComponents = flatMap(editedDraftLayout?.sections, (section) => section?.components) as ClientPortfolioDetailComponentFragment[]
  const selectedComponent = find(allComponents, (component) => component?.id === selectedComponentId?.id && selectedComponentId?.type === "component")
  const selectedSection = find(editedDraftLayout?.sections, (section,idx) => selectedComponentId?.type === "section" && idx === selectedComponentId.id)
  const showPage = false
  const showApprovals = auth.checkPermissions(['edit:component_approval'])
  const { resetErrors } = useContext(EditButtonContext)

  useEffect(() => {
    const firstComponent = first(allComponents)
    if(selectedComponentId === undefined && firstComponent){
      setSelectedComponentId({id: firstComponent.id, type: "component"})
    }
  }, [selectedComponentId, allComponents, setSelectedComponentId])


  useEffect(() => {
    if(!editMode && editNavbar === "Component"){
      setEditNavbar("Report")
      resetErrors()
    }
    if(editMode && editNavbar !== "Component"){
      setEditNavbar("Component")
      resetErrors()
    }
  }, [editMode])

  const containerMaxHeight = useMemo(() => {
    if (!wrapperRef.current) return null;

    return wrapperRef.current.clientHeight;
  }, [wrapperRef.current]);

  const type = selectedComponent?.type
  let component, handleComponentChange, handleSectionChange
  if(type){
    component = ComponentEditMapping[type]
    handleComponentChange = (value: any, property:string) => {
      setEditedDraftLayout((prevState) => {
        let oldState = prevState? cloneDeep({...prevState}): ({} as any)
        let newState = iassign(
          oldState,
          currentState => currentState?.sections,
          sectionsTable => {
            let sections: ClientPortfolioDetailLayoutFragment["sections"] = cloneDeep(sectionsTable)
            var selectedSection = findIndex(sections, (o) => {return !!find(o?.components, {id: selectedComponent?.id})})
            if(sections && selectedSection >= 0){
              sections = iassign(
                sections,
                currentSection => currentSection[selectedSection]?.components,
                componentsTable => {
                  let components = cloneDeep(componentsTable)
                  var editedComponent = find(components, {id: selectedComponent?.id})
                  if (editedComponent && property === "name"){
                    set(editedComponent, 'name', value);
                  } else if (editedComponent && property === "approval"){
                    set(editedComponent, 'approval.code', value)
                  } else if (editedComponent && property === "componentId"){
                    set(editedComponent, 'id', value)
                    const newReportsUsedIn = filter(editedComponent?.reportsUsedIn, (report) => report?.report?.id === reportId)
                    set(editedComponent, 'reportsUsedIn', newReportsUsedIn)
                    setSelectedComponentId({id: value, type: "component"})
                  } else if (editedComponent) {
                    set(editedComponent, 'draftSettings.' + property, value);
                    set(editedComponent, 'approval.code', ComponentApprovalCode._1)
                  }
                  return components
                }
              )
            }
            return sections
          }
        )
        return newState
      })
    }
  }
  let handleReportChange = (value: any, property:string) => {
    setReport((prevState) => {
      let updatedState = cloneDeep(prevState)
      const splitProperty = property.split('.')
      if(splitProperty && splitProperty[0] === "exportSettings" && !updatedState.exportSettings){
        updatedState.exportSettings = {
          filename: "",
          footerName: "",
          titleSlide: {
            title: "",
            subtitle: "",
            __typename: "TitleSlide"
          },
          __typename: "ReportExportSettings",
        }
      }
      let newState = iassign(
        updatedState,
        property.split('.'),
        draft => {
          return value
        }
      )
      return newState
    })
  }
  if(selectedComponentId){
    handleSectionChange = (value: any, property: string) => {
      setEditedDraftLayout((prevState) => {
        let newState = iassign(
          prevState,
          currentState => currentState?.sections,
          sectionsTable => {
            let sections: ClientPortfolioDetailLayoutFragment["sections"] = cloneDeep(sectionsTable)
            if(sections){
              var selectedSection = sections[selectedComponentId.id]
              if(selectedSection) set(selectedSection, property, value);
            }
            return sections
          }
        )
        return newState
      })
    }
  }

  // If report is not a fund profile and it is linked to a fund profile don't allow editing
  let readonly = false
  if(report.category?.code !== ReportCategoryCode.FPROF && selectedComponent && "reportsUsedIn" in selectedComponent && some(selectedComponent?.reportsUsedIn, (report) => report?.report?.category?.code === ReportCategoryCode.FPROF)){
    readonly = true
  }
  console.log("readonly", readonly, selectedComponent, report.category?.code)

  return (
    <div>
      <div className='sticky-top background-white'>
        <Nav className="sub-nav sub-nav-primary collapsed" tabs role="group" aria-label="Client Edit Report Component">
          <UncontrolledDropdown className="nav-tabs-dropdown pr-0" direction="down">
            <DropdownToggle caret>
              {editNavbar}
            </DropdownToggle>
            <DropdownMenu>
              <NavItem>
                <NavLink
                  className={classnames("px-3 mr-0",{
                    active: editNavbar === "Report"
                  })}
                  onClick={() => setEditNavbar("Report")}
                >
                  Report
                </NavLink>
              </NavItem>
              {showPage &&
                <NavItem>
                  <NavLink
                    className={classnames("px-3 mr-0",{
                      active: editNavbar === "Page"
                    })}
                    onClick={() => setEditNavbar("Page")}
                  >
                    Page
                  </NavLink>
                </NavItem>
              }
              {editMode &&
                <NavItem>
                  <NavLink
                    className={classnames("px-3 mr-0",{
                      active: editNavbar === "Component"
                    })}
                    onClick={() => setEditNavbar("Component")}
                  >
                    Component
                  </NavLink>
                </NavItem>
              }
              {!editMode && showApprovals &&
                <NavItem>
                  <NavLink
                    className={classnames("px-3 mr-0",{
                      active: editNavbar === "Approvals"
                    })}
                    onClick={() => setEditNavbar("Approvals")}
                  >
                    Approvals
                  </NavLink>
                </NavItem>
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </div>
      <div className='overflow-y-auto overflow-x-hidden' ref={wrapperRef} style={{ height: 'calc(100vh - 85px)' }}>
        {editNavbar === "Report" &&
          <ReportEditReport
            report={report}
            editMode={editMode}
            user={user}
            list={list}
            auth={auth}
            clientId={clientId}
            handleReportChange={handleReportChange}
            reportTabOrder={reportTabOrder}
            setReportTabOrder={setReportTabOrder}
            planTabOrder={planTabOrder}
            setPlanTabOrder={setPlanTabOrder}
          />
        }
        {editNavbar === "Approvals" && showApprovals &&
          <ReportApprovals allComponents={allComponents} portfolio={portfolio}/>
        }
        {editNavbar === "Page" && showPage &&
          <ReportEditPage portfolio={portfolio} handleInputChange={() => {}} editMode={editMode}/>
        }
        {editMode && editNavbar === "Component" &&
          <>
            {selectedComponent && component && handleComponentChange && React.createElement(component, {component: selectedComponent, handleInputChange: handleComponentChange, portfolio, clientId, reportId, user, auth, report, key: selectedComponentId?.id, readonly })}
            {selectedComponentId && selectedSection && handleSectionChange && <ReportEditSection section={selectedSection} sectionNumber={selectedComponentId.id} handleInputChange={handleSectionChange} />}
            {!selectedComponent && !selectedSection && <>Please {!editedDraftLayout?.sections || editedDraftLayout.sections.length === 0 ? "Add" : "Select"} a Component</>}
          </>
        }
      </div>
    </div>
  )
}

interface ReportEditTemplateProps {
  name?: string
  retractable?: boolean
  className?: string
}

export const ReportEditTemplate: React.FC<ReportEditTemplateProps> = ({ name, children, retractable, className}) => {
  const [retracted, setRetracted] = useState(false)
  const handleRetract = () => {
    if(retractable) setRetracted(!retracted)
  }
  return (
    <div className={classNames(className, "p-2 client-report-edit-template")}>
      {(!!name || retractable) &&
        <div className="d-flex justify-content-between cursor-pointer" onClick={handleRetract}>
          <h4 className="client-report-edit-title">{name}</h4>
          {retractable &&
            <div className="">
              <FontAwesomeIcon
                icon={ retracted ? 'chevron-up' : 'chevron-down'}
                size="sm"
                className="ml-auto mr-0"
              />
            </div>
          }
        </div>
      }
      {!retracted && children}
    </div>
  )
}

interface ReportEditReportProps {
  report: ReportsFragment
  editMode: boolean
  user?: MeFragment
  list: listReportExpanded[]
  auth: Auth
  clientId: number
  handleReportChange: (value: any, property: string) => void
  reportTabOrder: ClientReportOrderFragment[]
  setReportTabOrder: Dispatch<SetStateAction<ClientReportOrderFragment[]>>
  planTabOrder: ClientPlanOrderFragment[]
  setPlanTabOrder: Dispatch<SetStateAction<ClientPlanOrderFragment[]>>
}

const ApprovalTypes = [
  {
    name: 'Needs Review',
    value: ComponentApprovalCode._1,
    className: 'text-approval3',
  }, {
    name: 'Reviewed',
    value: ComponentApprovalCode._2,
    className: 'text-approval2',
  }, {
    name: 'Approved',
    value: ComponentApprovalCode._3,
    className: 'text-approval1',
  }
]

const ReportEditReport: React.FC<ReportEditReportProps> = ({ report, editMode, user, list, auth, clientId, handleReportChange, reportTabOrder, setReportTabOrder, planTabOrder, setPlanTabOrder }) => {
  let approvalCount:{[key in ComponentApprovalCode]?: number } = {}
  list.forEach(group => {
    Object.keys(group.approvalCount || {}).forEach(inkey  => {
      const key = inkey as ComponentApprovalCode
      if(key in ComponentApprovalCode){
        approvalCount[key] = (approvalCount[key] || 0) + (!!group?.approvalCount ? group.approvalCount[key] || 0 : 0)
      }
    })
  })

  const { data: clientReports } = useClientAllReportsQuery({
    variables: { id: clientId }
  })
  const client = clientReports?.org as Client
  const plans = compact(client?.plans) as Maybe<ClientPlanOrderFragment[]>
  const currentPlan = find(plans, ['id', report.plans?.[0]?.id])
  const reports = compact(currentPlan?.report?.filter((report) => report?.active && report.category?.code === ReportCategoryCode.FPROF)) as Maybe<ClientReportOrderFragment[]>
  const isFundReport = report.category?.code === ReportCategoryCode.FPROF

  const dueDate = report.dueDates?.find(dueDate => dueDate.quarterDate === appDate.format(DATE_API_FORMAT)) || {quarterDate: appDate.format(DATE_API_FORMAT), dueDate: null}

  useEffect(() => {
    if (reports) {
      setReportTabOrder(sortBy(reports, 'order'))
      setPlanTabOrder(sortBy(plans, 'order'))
    }
  }, [clientReports])

  return(
    <>
      <ReportEditTemplate className={classNames('text-white background-blue-100')}>
        <div className="d-flex my-2">
          <div className="d-flex">
            <FontAwesomeIcon
              icon={["fal", "book"]}
              className="text-white unlock-icon-height mr-2 fa-2-5x"
            />
          </div>
          <div>
            <strong>{report.name}</strong>
            <br />
            <span className='small-text text-blue-50'>
              {report.category?.value}
            </span>
            <br />
            <small className='small-text text-blue-50'>
              Portfolios for
              <span className='ml-1 cursor-pointer text-blue-50 tooltip-icon hover-transition-underline' onClick={(e) => {e.stopPropagation(); window.open(`/plans/${get(report,"plans[0].id")}`,'_blank')}}>
                {get(report,"plans[0].name")}
                <FontAwesomeIcon icon={"external-link"} className="ml-2 light-blue-color"/>
              </span>
            </small>
          </div>
        </div>
      </ReportEditTemplate>
      {auth.checkPermissions(['edit:component_approval']) &&
        <div className='background-gray-10 d-flex justify-content-center py-2'>
          {ApprovalTypes.map(type => (
            <div key={type?.value} className={classNames('approval-counts background-white d-flex flex-column', type.className)}>
              <div className='approval-counts-count align-self-center'>
                {approvalCount[type.value] || 0}
              </div>
              <div className='approval-counts-title align-self-center'>{type.name}</div>
            </div>
          ))}
        </div>
      }
      <ReportEditTemplate name="Info" retractable={true}>
        <FormInput
          property={"published"}
          displayName={"Last Published"}
          type={"text"}
          idx={1}
          editMode={editMode}
          propertyVal={report.lastPublished ? moment(report.lastPublished).format(DATE_DISPLAY_FORMAT) : "-"}
          updateValue={() => {}}
          readonly={true}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        <FormInput
          property={"name"}
          displayName={"Name"}
          type={"text"}
          idx={2}
          editMode={editMode}
          propertyVal={report.name}
          required={true}
          updateValue={(value) => handleReportChange(value, "name")}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        {isFundReport &&
          <FormInput
            property={"dueDates"}
            displayName={"Due Date"}
            type={"date"}
            idx={4}
            editMode={editMode}
            propertyVal={dueDate.dueDate}
            updateValue={(value) => {
              let dueDates = cloneDeep(report.dueDates || [])
              remove(dueDates, ['quarterDate', appDate.format(DATE_API_FORMAT)])
              handleReportChange([...dueDates, {dueDate: value, quarterDate: appDate.format(DATE_API_FORMAT)}], "dueDates")
            }}
            subClasses={{wrapperClasses: "no-gutters", inputClasses: "text-left"}}
          />
        }
        <FormInput
          property={"frequency"}
          displayName={"Frequency"}
          type={"select"}
          idx={5}
          editMode={editMode}
          propertyVal={report.frequency?.code}
          optionSource={"TrainingFrequencyCode"}
          updateValue={(value) => handleReportChange(value, "frequency.code")}
          required={true}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        <ListPicker
          dropdownClass="z-index-1203"
          onClick={(value) => {
            if(!report.draftList){
              handleReportChange({id: value, __typename: "List"}, "draftList")
            } else {
              handleReportChange(value, "draftList.id")
            }
          }}
          clientId={clientId}
          key={6}
          selectedListId={report.draftList?.id}
          editMode={isFundReport && editMode}
        />
        <FormInput
          property={"active"}
          displayName={"Status"}
          type={"select"}
          idx={7}
          editMode={editMode}
          propertyVal={report.active.toString()}
          options={[{value: "Active", code: "true"}, {value: "Inactive", code: "false"}]}
          updateValue={(value) => handleReportChange(value === "true", "active")}
          required={true}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        <FormInput
          property={"category"}
          displayName={"Category"}
          type={"select"}
          idx={8}
          editMode={editMode}
          propertyVal={report.category?.code}
          optionSource={"ReportCategoryCode"}
          updateValue={(value) => handleReportChange(value, "category.code")}
          readonly={true}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        <FormInput
          property={"subCategory"}
          displayName={"Sub-category"}
          type={"select"}
          idx={9}
          editMode={editMode}
          propertyVal={report.subCategory?.code}
          optionSource={"ReportSubcategoryCode"}
          updateValue={(value) => handleReportChange(value, "subCategory.code")}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        <EmployeePicker
          managerId={244}
          onClick={(person) => {handleReportChange(person, "owner")}}
          dropdownClass={"form-dropdown not-full-width"}
          disabled={!editMode}
        >
          <DropdownToggle caret className={classNames('data-dropdown', {'hide-caret': !editMode})}>
            <Row className="w-100 no-gutters form-group">
              <Label
                className={"col-form-label col-sm-4"}
                for={`1-listselect`}
                id={"list.id"}
              >
                <div
                  className={"d-flex w-100 justify-content-start tooltip-icon"}
                >
                  Owner
                </div>
              </Label>
              <div className={"col-sm-8"}>
                <div className={classNames("fake-input form-control form-control-sm", {"disabled": !editMode})} title={`${user?.person?.id === report.owner?.id ? "(Me) " : ""} ${report.owner?.firstName} ${report.owner?.lastName}`}>
                  {`${user?.person?.id === report.owner?.id ? "(Me) " : ""} ${report.owner?.firstName} ${report.owner?.lastName}`}
                </div>
              </div>
            </Row>
          </DropdownToggle>
        </EmployeePicker>
        {isFundReport &&
          <EmployeePicker
            managerId={244}
            onClick={(person) => {handleReportChange(person, "dataEntryAnalyst")}}
            dropdownClass={"form-dropdown not-full-width"}
            disabled={!editMode}
          >
            <DropdownToggle caret className={classNames('data-dropdown', {'hide-caret': !editMode})}>
              <Row className="w-100 no-gutters form-group">
                <Label
                  className={"col-form-label col-sm-4"}
                  for={`1-listselect`}
                  id={"list.id"}
                >
                  <div
                    className={"d-flex w-100 justify-content-start tooltip-icon"}
                  >
                    Data entry analyst
                  </div>
                </Label>
                <div className={"col-sm-8"}>
                  <div className={classNames("fake-input form-control form-control-sm", {"disabled": !editMode})} title={`${user?.person?.id === report.dataEntryAnalyst?.id ? "(Me) " : ""} ${report.dataEntryAnalyst?.firstName || ""} ${report.dataEntryAnalyst?.lastName || ""}`}>
                    {`${user?.person?.id === report.dataEntryAnalyst?.id ? "(Me) " : ""} ${report.dataEntryAnalyst?.firstName || ""} ${report.dataEntryAnalyst?.lastName || ""}`}
                  </div>
                </div>
              </Row>
            </DropdownToggle>
          </EmployeePicker>
        }
        <TabOrderSelector
          orgId={clientId}
          selectedReport={report}
          editMode={editMode}
          setReportTabOrder={setReportTabOrder}
          reportTabOrder={reportTabOrder}
          currentPlan={currentPlan}
          planTabOrder={planTabOrder}
          setPlanTabOrder={setPlanTabOrder}
        />
      </ReportEditTemplate>
      <ReportEditTemplate name="Export" retractable={true}>
        <FormInput
          property={"filename"}
          displayName={"File name"}
          type={"text"}
          idx={10}
          editMode={editMode}
          propertyVal={report.exportSettings?.filename}
          updateValue={(value) => handleReportChange(value, "exportSettings.filename")}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
        <FormInput
          property={"title"}
          displayName={"Title"}
          type={"text"}
          subtype={"textarea"}
          idx={11}
          editMode={editMode}
          propertyVal={report.exportSettings?.titleSlide?.title}
          updateValue={(value) => handleReportChange(value, "exportSettings.titleSlide.title")}
          subClasses={{wrapperClasses: "no-gutters", inputClasses: "fake-input"}}
        />
        <FormInput
          property={"subtitle"}
          displayName={"Subtitle"}
          type={"text"}
          subtype={"textarea"}
          idx={12}
          editMode={editMode}
          propertyVal={report.exportSettings?.titleSlide?.subtitle}
          updateValue={(value) => handleReportChange(value, "exportSettings.titleSlide.subtitle")}
          subClasses={{wrapperClasses: "no-gutters", inputClasses: "fake-input"}}
        />
        <FormInput
          property={"footer"}
          displayName={"Page footer"}
          type={"text"}
          subtype={"textarea"}
          idx={13}
          editMode={editMode}
          propertyVal={report.exportSettings?.footerName}
          updateValue={(value) => handleReportChange(value, "exportSettings.footerName")}
          subClasses={{wrapperClasses: "no-gutters", inputClasses: "fake-input"}}
        />
      </ReportEditTemplate>
      <ReportEditTemplate name="Contacts" retractable={true}>
        {report.contacts?.map((contact, idx) => {
          const handleContactChange = (value: any, property: string) => {
            let newContacts = cloneDeep(report.contacts || []);
            set(newContacts, `[${idx}].${property}`, value)
            handleReportChange(newContacts, "contacts")
          }
          const handleContactDelete = () => {
            let newContacts = cloneDeep(report.contacts || []);
            newContacts.splice(idx, 1);
            handleReportChange(newContacts, "contacts")
          }
          return <ReportContactRow
            key={idx}
            contact={contact}
            editMode={editMode}
            user={user}
            clientId={clientId}
            handleContactChange={handleContactChange}
            handleDelete={handleContactDelete}
          />
        })}
        {/* Add Button */}
        {editMode && <Button onClick={() => handleReportChange([...(report.contacts || []), {contact: {__typename: "Person"}, deliveryMethod: {__typename: "ReportDeliveryMethodLookup"}}],"contacts")} color="secondary" className="mt-1 ml-1">Add</Button>}
      </ReportEditTemplate>
    </>
  )
}

interface ReportContactRowProps {
  contact: ReportsContactsFragment
  editMode: boolean
  user?: MeFragment
  clientId: number
  handleContactChange: (value: any, property: string) => void
  handleDelete?: () => void
}

const ReportContactRow: React.FC<ReportContactRowProps> = (props) => {
  const {contact, editMode, user, clientId, handleContactChange, handleDelete} = props
  const selectedName = `${user?.person?.id === contact.contact?.id ? "(Me) " : ""} ${contact.contact?.firstName || ""} ${contact.contact?.lastName || ""}`
  return <Row noGutters className='align-items-center'>
    <Col xs={6}>
      <div className="form-group">
        <EmployeePicker
          managerId={clientId}
          onClick={(person) => {handleContactChange(person, "contact")}}
          dropdownClass={"form-dropdown not-full-width"}
          disabled={!editMode}
          dropup
        >
          <DropdownToggle caret className={classNames('data-dropdown non-eol-caret', {'hide-caret': !editMode})}>
            <div className={classNames("fake-input form-control form-control-sm", {"disabled": !editMode})} title={selectedName}>
              {selectedName}
            </div>
          </DropdownToggle>
        </EmployeePicker>
      </div>
    </Col>
    <Col xs={5}>
      <FormInput
        property={"deliveryMethod"}
        displayName={""}
        type={"select"}
        idx={8}
        editMode={editMode}
        propertyVal={contact.deliveryMethod?.code}
        optionSource={"ReportDeliveryMethodCode"}
        updateValue={(value) => handleContactChange(value, "deliveryMethod.code")}
        subClasses={{wrapperClasses: "no-gutters my-0"}}
      />
    </Col>
    <Col xs={1} className="d-flex justify-content-center">
      {editMode &&
        <div className='ml-1'>
          <FontAwesomeIcon icon="trash" className="ml-2 mt-1 fake-link fa-1-2x" onClick={handleDelete}/>
        </div>
      }
    </Col>
  </Row>
}

interface ReportEditPageProps {
  portfolio: ReportsListClientPortfolioFragment | ReportListHeaderFragment
  handleInputChange: (value: any, property: string) => void
  editMode: boolean
  user?: MeFragment
}

const ReportEditPage: React.FC<ReportEditPageProps> = ({ portfolio, handleInputChange, editMode }) => {
  // TODO add footnote check and use input change
  let name = ""
  if(portfolio.__typename === "ClientPortfolio"){
    name = portfolio.name || ""
  } else {
    name = portfolio.text || ""
  }
  return(
    <>
      <ReportEditTemplate>
        <div className="d-flex my-2">
          <div className="d-flex">
            <FontAwesomeIcon
              icon="file-alt"
              size="3x"
              className="text-gray-100"
            />
          </div>
          <div>
            <strong>{name}</strong>
            <br />
            Portfolio ID {portfolio.id}
          </div>
        </div>
      </ReportEditTemplate>
      {/* <ReportEditTemplate name="Component Approvals" retractable={true}>
      </ReportEditTemplate> */}
      <ReportEditTemplate name="Footnotes" retractable={true}>
        <FormInput
          property={"showFootnote"}
          displayName={"PID Footnote"}
          type={"checkbox"}
          subtype={"show"}
          idx={1}
          required={true}
          editMode={editMode}
          propertyVal={false}
          updateValue={(value) => handleInputChange(value, "showFootnote")}
          subClasses={{wrapperClasses: "no-gutters"}}
        />
      </ReportEditTemplate>
    </>
  )
}

interface ReportEditSectionProps {
  sectionNumber: number
  section: ClientPortfolioDetailSectionFragment
  handleInputChange: (value: any, property: string) => void
}

const ReportEditSection: React.FC<ReportEditSectionProps> = ({ sectionNumber, handleInputChange, section }) => {
  const currentLayout = section.type
  return(
    <>
      <ReportEditTemplate /*className={classNames('text-white', {'background-blue-100': ownedComponent, 'background-gray-50': !ownedComponent })} */>
        <div className="d-flex my-2">
          <div className="d-flex">
            <FontAwesomeIcon
              icon={section2Col}
              className="unlock-icon-height mr-2 fa-2-5x icon-fill-none" //text-white
            />
          </div>
          <div>
            <strong>Columns</strong>
            <br />
            ID {sectionNumber}
            <br />
          </div>
        </div>
      </ReportEditTemplate>
      <ReportEditTemplate name="Info" retractable={true}>
          <Row className="justify-content-between">
            <Col sm={4}>
              <div
                className={classNames("p-2 my-1")}
              >
                Layout
              </div>
            </Col>
            <Col sm={8} className="align-self-center">
              <ButtonGroup className="">
                <Button
                  color={currentLayout === LayoutSectionType.TwoColumnSection ? "primary" : "light"}
                  onClick={() => handleInputChange(LayoutSectionType.TwoColumnSection, 'type')}
                  className="p-2 btn-approval"
                >
                  <FontAwesomeIcon icon={section2Col} size={"xs"} className="icon-fill-none"/>
                </Button>
                <Button
                  color={currentLayout === LayoutSectionType.ThreeColumnSection ? "primary" : "light"}
                  onClick={() => handleInputChange(LayoutSectionType.ThreeColumnSection, 'type')}
                  className="p-2 btn-approval"
                >
                  <FontAwesomeIcon icon={section3Col} size={"xs"} className="icon-fill-none"/>
                </Button>
                <Button
                  color={currentLayout === LayoutSectionType.TwoColumnSectionWithRightSideBar ? "primary" : "light"}
                  onClick={() => handleInputChange(LayoutSectionType.TwoColumnSectionWithRightSideBar, 'type')}
                  className="p-2 btn-approval"
                >
                  <FontAwesomeIcon
                    icon={section2ColRightSidebar}
                    size={"xs"}
                    className="icon-fill-none"
                  />
                </Button>
                <Button
                  color={currentLayout === LayoutSectionType.TwoColumnSectionWithLeftSideBar ? "primary" : "light"}
                  onClick={() => handleInputChange(LayoutSectionType.TwoColumnSectionWithLeftSideBar, 'type')}
                  className="p-2 btn-approval"
                >
                  <FontAwesomeIcon
                    icon={section2ColLeftSidebar}
                    size={"xs"}
                    className="icon-fill-none"
                  />
                </Button>
                <Button
                  color={currentLayout === LayoutSectionType.ThreeColumnSectionWithSideBars ? "primary" : "light"}
                  onClick={() => handleInputChange(LayoutSectionType.ThreeColumnSectionWithSideBars, 'type')}
                  className="p-2 btn-approval"
                >
                  <FontAwesomeIcon
                    icon={section3ColWithSidebars}
                    size={"xs"}
                    className="icon-fill-none"
                  />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
      </ReportEditTemplate>
    </>
  )
}

export type ReportEditField = FormInputField & {
  optionFilterRule?: { [name: string]: boolean | number }
  afterChange?: (value:any) => void
  filterOptionsMethod?: (value:any) => boolean
}

interface SimpleReportEditProps {
  name?: string
  retractable?: boolean
  fields: ReportEditField[]
  handleInputChange: (value: any, property: string) => void
  currentState: ClientPortfolioDetailComponentSettingsFragment
  clientId?: number
  children?: React.ReactNode
}

export const SimpleReportEdit: React.FC<SimpleReportEditProps> = ({ name, retractable, fields, handleInputChange, currentState, clientId, children }) => {
  return (
    <ReportEditTemplate name={name} retractable={retractable}>
      {fields.map(
        (
          {
            property,
            label,
            type,
            subtype,
            placeholder,
            optionSource,
            optionsMutateLabelFunction,
            readonly,
            tooltip,
            subClasses,
            required,
            options,
            optionFilterRule,
            afterChange,
            debounceInput,
            sortOptions,
            filterOptionsMethod
          },
          idx
        ) => {
          let propertyVal: any = get(currentState, property)
          let onChangeCallback = (value: any) =>{
            if (value === "true" || value === "false") {
              value = JSON.parse(value);
            }
            if (["totalFundPid", "list.id", "target.targetId"].includes(property)){
              value = parseInt(value)
              // If the property is a list, we need to convert the value to a list object if it was null
              if(property === "list.id" && !get(currentState, "list")){
                value = {id: value, __typename: "List"}
                property = "list"
              }
            }
            if(afterChange) afterChange(value)
            handleInputChange(value, property)
          }
          // Only one child type can be used per component
          if (type === 'child') {
            return children
          }

          if (["list.id"].includes(property) && clientId){
            return(
              <ListPicker
                dropdownClass="string"
                onClick={onChangeCallback}
                clientId={clientId}
                key={idx}
                selectedListId={propertyVal}
                editMode={true}
                filterMethod={filterOptionsMethod}
                required={required}
              />
            )
          } else if (["useListOrder"].includes(property)){
            propertyVal = propertyVal.toString()
          } else if (type === "searchSelect" && isArray(options)) {
            return (
              <SearchAddStatic
                handleAdd={onChangeCallback}
                includedRows={[propertyVal?.name]}
                searchedTypes={options?.map((option) => option.code)}
                dropdown
                inlineField
                forPerformanceComparison
              />
            )
          }

          return (
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={idx}
              editMode={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              optionsMutateLabelFunction={optionsMutateLabelFunction}
              sortOptions={sortOptions}
              readonly={readonly}
              tooltip={tooltip}
              subClasses={{...subClasses, wrapperClasses: classnames("no-gutters", subClasses?.wrapperClasses)}}
              required={required}
              options={options}
              optionFilterRule={optionFilterRule}
              debounceInput={debounceInput}
            />
          )
        }
      )}
    </ReportEditTemplate>
  )
}

type ReportEditInfoOptions = {
  hideDate?: boolean
  hideList?: boolean
  hideName?: boolean
  resetDates?: boolean
}

export const ReportEditInfoFields = (handleInputChange:(value: any, property: string) => void, options?:ReportEditInfoOptions):ReportEditField[] => {
  let {hideDate, hideList, hideName, resetDates} = options || {}
  return compact([
    hideName ? undefined : {
      property: "name",
      label: "Name",
      type: "text",
      required: true,
      debounceInput: true,
    },
    hideDate ? undefined : {
      property: "date",
      label: "As of date",
      type: "date",
      subtype: "quarter",
      required: true,
      afterChange: (value:any)=> {
        if(resetDates) handleInputChange([], "monthlyOptions.dates")
      },
    },
    hideList ? undefined : {
      property: "list.id",
      label: "List",
      type: "select",
      required: true,
    }
  ])
}

export const ReportEditInfoTotalField = (list: ReportSimpleListFragment | undefined):ReportEditField[] => {
  if(list){
    const options = compact(map(list.items, (item) => {
      if(item.item?.__typename === "ClientPortfolio"){
        return {code: item.item.id.toString(), value: `${item.item.name} (${item.item.id})`}
      }
      return undefined
    }))
    return [
      {
        property: "totalFundPid",
        label: "Total",
        type: "select",
        options: options,
        required: true,
      }
    ]
  } else {
    return [
      {
        property: "totalFundPid",
        label: "Total",
        type: "select",
        options: [{code: "", value: "Loading..."}],
        required: true,
      }
    ]
  }
}

export const ReportEditFootnoteFields:ReportEditField[] = [
  {
    property: "showFootnote",
    label: "Portfolio",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "showTargetFootnote",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

interface AdditionalReportingEditProps {
  handleInputChange: (value: any, property: string) => void
  currentState: ClientPortfolioDetailComponentSettingsFragment
  hideMonthly?: boolean
  readonly?: boolean
}

export const AdditionalReportingEdit: React.FC<AdditionalReportingEditProps> = ({ handleInputChange, currentState, hideMonthly, readonly }) => {
  const months = moment.monthsShort()
  const currentDates = get(currentState, 'monthlyOptions.dates', [] as string[]) || []
  const earliestDate = moment.min(currentDates.map(date => moment(date).startOf('quarter').endOf('month')))
  const asOfDate = moment(get(currentState, 'date') || '')
  const usedEarliestDate =  moment.min([earliestDate, asOfDate])
  const beginAvailableDate = moment(asOfDate).startOf('quarter').endOf('month')
  const endAvailableDate = moment(asOfDate).add(1, 'quarter').endOf('quarter')
  const multiYear = asOfDate.month() > 8 || usedEarliestDate.month() > 8
  const toggleMonth = (month: Moment) => {
    if(readonly) return
    const active = currentDates?.includes(month.format(DATE_API_FORMAT))
    if(!active && (month.diff(beginAvailableDate) < 0 || month.diff(endAvailableDate) > 0)){
      return
    }
    if(!currentDates){
      handleInputChange([month.format(DATE_API_FORMAT)], 'monthlyOptions.dates')
    } else {
      const updatedDates = xor(currentDates, [month.format(DATE_API_FORMAT)])
      handleInputChange(sortBy(updatedDates, (date:string) => moment(date).valueOf()), 'monthlyOptions.dates')
    }
  }
  let previousDate:Moment | undefined = undefined
  let currentDatesNiceString = currentDates?.reduce((workingString:string, cd:string) => {
    const cdMoment = moment(cd)
    let returnString = workingString
    if(!previousDate){
      returnString = cdMoment.format("MMM")
    } else if(previousDate.year() !== cdMoment.year()){
      returnString = workingString + ` ${previousDate.format("YYYY")} ${cdMoment.format("MMM")}`
    } else {
      returnString = workingString + `,${cdMoment.format("MMM")}`
    }
    previousDate = cdMoment
    return returnString
  }, "")
  if(previousDate){
    currentDatesNiceString += ` ${(previousDate as Moment)?.format("YYYY")}`
  }
  return (
    <ReportEditTemplate name={"Additional Reporting"} retractable={true}>
      { !hideMonthly &&
        <>
          <FormInput
            property={'monthlyOptions.show'}
            displayName={"Monthly data"}
            type={"checkbox"}
            subtype={"show"}
            idx={1}
            editMode={true}
            required={true}
            propertyVal={get(currentState, 'monthlyOptions.show')}
            updateValue={(value) => handleInputChange(value, 'monthlyOptions.show')}
            subClasses={{wrapperClasses: classnames("no-gutters")}}
            readonly={readonly}
          />
          {get(currentState, 'monthlyOptions.show') &&
            <FormGroup
              className={"form-group row no-gutters"}
            >
              <Label
                className={"col-form-label col-sm-4"}
                for={`_dates`}
                id={'monthlyOptions.dates'}
              >
                <div
                  className={"d-flex w-100 justify-content-between"}
                >
                  Months included
                </div>
              </Label>
              <div
                className={"col-sm-8"}
              >
                  <Input
                    id={`_dates`}
                    name={'monthlyOptions.dates'}
                    label={'monthlyOptions.dates'}
                    value={currentDatesNiceString}
                    onChange={() => {}}
                    bsSize="sm"
                    type="text"
                    className="month-picker-input editing"
                    readonly={readonly}
                    disabled={readonly}
                  />
                <UncontrolledPopover
                  placement="bottom"
                  target={`_dates`}
                  trigger="legacy"
                  popperClassName="month-picker"
                  // enforce popover shows above current container
                  container={"root"}
                  className="calendar-dropdown"
                >
                  <PopoverBody className="border-0">
                    <div className="month calendar-options mb-2">
                      {usedEarliestDate.year()}
                      <Row>
                        {months.map((monthObject, idx) => {
                          const monthMoment = moment(`${monthObject} ${usedEarliestDate.year()}`, "MMM YYYY").endOf('month')
                          const active = currentDates?.includes(monthMoment.format(DATE_API_FORMAT))
                          return(
                            <Col
                              key={idx}
                              xs="3"
                              onClick={() => toggleMonth(monthMoment)}
                              className={classnames({ 'calendar-item': true, active: active, disabled: !active && (monthMoment.diff(beginAvailableDate) < 0 || monthMoment.diff(endAvailableDate) > 0) })}
                            >
                              {monthObject}
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                    {multiYear &&
                      <div className="month calendar-options mb-2">
                        {usedEarliestDate.year() + 1}
                        <Row>
                          {months.map((monthObject, idx) => {
                            const monthMoment = moment(`${monthObject} ${usedEarliestDate.year() + 1}`, "MMM YYYY").endOf('month')
                            const active = currentDates?.includes(monthMoment.format(DATE_API_FORMAT))
                            return(
                              <Col
                                key={idx}
                                xs="3"
                                onClick={() => toggleMonth(monthMoment)}
                                className={classnames({ 'calendar-item': true, active: active, disabled: !active && (monthMoment.diff(beginAvailableDate) < 0 || monthMoment.diff(endAvailableDate) > 0) })}
                              >
                                {monthObject}
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    }
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </FormGroup>
          }
        </>
      }
    </ReportEditTemplate>
  )
}

export interface ReportEditHeadingProps {
  name: string
  ownedComponent: boolean
  componentType: ComponentType
  component: ClientPortfolioDetailComponentFragment
  portfolio: ReportsListClientPortfolioFragment | ReportListHeaderFragment
  reportId: number
  handleInputChange: (value: any, property: string) => void
}

export const ReportEditHeading: React.FC<ReportEditHeadingProps> = ({ name, ownedComponent, componentType, component, portfolio, handleInputChange, reportId }) => {
  const linked = some(component.reportsUsedIn, (report) => report?.report?.id !== reportId)
  const handleClick = () => {
    handleInputChange(parseInt(uniqueId()) * -1, 'componentId')
  }
  return (
    <>
      <ReportEditTemplate className={classNames('text-white', {'background-blue-100': ownedComponent, 'background-gray-50': !ownedComponent })}>
        <div className="d-flex my-2">
          <div className="d-flex">
            <FontAwesomeIcon
              icon={IconMapping[componentType] || 'table'}
              className="text-white unlock-icon-height mr-2 fa-2-5x"
            />
          </div>
          <div>
            <strong>{name}</strong> | ID {component.id}
            <br />
            Owned by {ownedComponent ? 'me' : `${component.owner?.firstName} ${component.owner?.lastName}`}
            <br />
            <small className={classNames('small-text', {'text-blue-50': ownedComponent, 'text-gray-30': !ownedComponent })}>
              {linked &&
                <>
                  <FontAwesomeIcon
                    icon={'link'}
                    className="text-white unlock-icon-height mr-1"
                  />
                  Linked
                </>
              }
              {portfolio.__typename === "ClientPortfolio" &&
              <>
                {linked ? " - Data from" : "Data from"}
                <span className='ml-1 cursor-pointer tooltip-icon hover-transition-underline' onClick={(e) => {e.stopPropagation(); window.open(`/clientportfolios/${portfolio.id}`,'_blank')}}>
                  Portfolio ID {portfolio.id}
                  <FontAwesomeIcon icon={"external-link"} className={classNames('ml-2', {'light-blue-color': ownedComponent, 'light-gray-color': !ownedComponent })}/>
                </span>
              </>
              }
            </small>
          </div>
        </div>
      </ReportEditTemplate>
      {linked &&
        <div className={classNames("p-2 client-report-edit-template background-gray-10")}>
          <Button color="secondary" onClick={() => handleClick()}>
            Unlink
            <FontAwesomeIcon
              icon={'unlink'}
              className="unlock-icon-height ml-1"/>
          </Button>
        </div>
      }
    </>
  )
}

export interface ReportEditInstancesProps {
  component: ClientPortfolioDetailComponentFragment
}

export const ReportEditInstances: React.FC<ReportEditInstancesProps> = ({ component }) => {
  const reportsUsedIn = uniqBy(component.reportsUsedIn || [], (report) => report?.report?.id)
  if(!reportsUsedIn.length) return null
  return (
    <ReportEditTemplate name={`Instances (${reportsUsedIn.length})`} retractable={true}>
      <div className='d-flex flex-column'>
        {reportsUsedIn.map((report, idx) => {
          if(!report){
            return (<React.Fragment key={idx}/>)
          }
          return(
            <a key={idx} href={`/reports/${report?.report?.id}`} rel="noreferrer" target="_blank" className='w-100'>
              {report?.report?.client?.name || ""} - {report?.report?.name}
            </a>
          )
        })}
      </div>
    </ReportEditTemplate>
  )
}


export default ReportEdit