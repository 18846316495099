import classnames from 'classnames'
import { find, get, set } from 'lodash'
import React, { EventHandler, useContext, useEffect, useState } from 'react'
import validate from 'validate.js'

import { FormGroup, Label, Input, InputProps, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { InputTooltip } from './TextFieldInput'
import DOMPurify from 'dompurify'

interface SelectInputProps {
  idx: string | number,
  property: string,
  displayName: string,
  // propertyValue format for single| multiple
  propertyValue: string | string[],
  editMode: boolean,
  multiple: boolean,
  onChange: (event:any) => void,
  disabled?: boolean,
  wrapperClasses?: string,
  labelClasses?: string,
  inputClasses?: string,
  inputWrapperClasses?: string,
  options?: React.ReactElement<any, any>,
  required?: boolean,
  tooltip?: InputTooltip,
  inputRef?: React.RefObject<HTMLInputElement>
  dataObject?: {[name: string]: string}
}

export const SelectInput: React.FC<SelectInputProps> = props => {
  const { idx, property, displayName, propertyValue, editMode, disabled, labelClasses, inputClasses, inputWrapperClasses, options, multiple, tooltip, inputRef, dataObject } = props
  const onChange: EventHandler<any> = props.onChange

  let shortProperty = property.split(".").pop() || property
  const context = useContext(EditButtonContext)
  const { setError } = context

  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || '')

  const displayLabel = (displayName !== "")
  let additionalInputProps: InputProps = {}
  let required = props.required || false

  useEffect(() => {
    runValidation(propertyValue)
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [props.editMode, props.propertyValue, required])

  const runValidation = (value:string | string[]) => {
    var inputs:{[key:string]: string | string[]} = {}
    var constraints:{[key:string]: any} = {}
    inputs[shortProperty] = value

    if (required) {
      set(constraints, [shortProperty, 'presence'], {allowEmpty: false})
    }

    let validations = validate(inputs, constraints)

    if (validations && props.editMode) {
      setError(`${idx}-${shortProperty}`, get(validations, shortProperty))
      setWrapperClasses(wrapperClasses + ' has-error')
    } else {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g,''))
    }
  }

  const handleBlur = (event:React.ChangeEvent<HTMLInputElement>) => {
    // let targetValue = event.target.value

    // runValidation(targetValue)
  }
  const selectedOption = find(options?.props?.children, {'props': {'value': propertyValue}})?.props?.children

  const defaultValue = multiple? []: ""
  if(disabled ||!editMode){
    additionalInputProps.disabled = true
  }
  const blankOption = (<option key={-1} value=""></option>)

  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses)}
      key={idx}
      {...dataObject}
    >
      {displayLabel &&
        <Label
          className={classnames("col-form-label", labelClasses, {"col-sm-4":!labelClasses})}
          for={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
            id={tooltip ? tooltip?.id : ""}
          >
            {displayName}
            {tooltip &&
            <>
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
                id={tooltip? "generic-tooltip-"+tooltip?.id : ""}
              />
              <UncontrolledTooltip
                className="generic-tooltip"
                target={"generic-tooltip-"+tooltip?.id || ""}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tooltip?.text || "")}}
                delay={{hide: 1000, show: 0}}
              />
            </>
            }
          </div>
        </Label>
      }
      <div className={classnames("exportable-form-input",inputWrapperClasses||"",{"col-sm-8":displayLabel &&!inputWrapperClasses,"col-sm-12":!(displayLabel||inputWrapperClasses)})}>
        <Input
          id={`${idx}-${shortProperty}`}
          bsSize="sm"
          name={property}
          label={property}
          type="select"
          value={propertyValue || defaultValue}
          onChange={onChange}
          className={classnames(inputClasses, {"editing": editMode})}
          multiple={multiple}
          required={required}
          onBlur={handleBlur}
          innerRef={inputRef}
          title={selectedOption}
          {...additionalInputProps}
        >
          { !editMode && !propertyValue && blankOption}
          { (editMode || propertyValue) && options }
        </Input>
      </div>
    </FormGroup>
  )
}