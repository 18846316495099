import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iassign from 'immutable-assign'
import _, { cloneDeep, compact, difference, differenceBy, find, get, isNull, omitBy, unionBy, uniqueId } from "lodash"
import React, { useEffect, useState } from "react"
import { match, RouteComponentProps, useHistory } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import Auth from "../../Auth/Auth"
import { FormInputField } from "../../helpers/constant"
import { getNewStateObject } from "../../helpers/helpers"
import { reShapeObject } from '../../helpers/object'
import { appProps } from "../../queries/extended/appProps"
import { DeletePlanServiceProviderInput, PlanDetailsFragment, SearchTypes, UpdatePlanInput, useCopyFootnoteHistoryMutation, useCreateFootnoteMutation, useDeleteFootnoteMutation, useDeletePlanServiceProviderMutation, useUpdateFootnoteMutation, useUpdatePlanMutation } from "../../__generated__/graphql"
import { DEFAULT_EMPTY_FOOTNOTE, FootnoteModifications, SubmitFootnoteChanges, FootnoteComponent } from '../ClientPortfolio/ClientPortfolioDefinition/Benchmark/TargetFootnotes'
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from "../ui/EditButtons"
import { FormInput } from "../ui/Forms/FormInput"
import exportTables from "../../helpers/exportTable"

interface PlanRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.planId
    planId: string
  }> {}

interface PlanSummaryDetailFragment {
  data: PlanDetailsFragment
  planId: number
}

type idProps = {
  planId: number
  data: PlanDetailsFragment
  auth: Auth
  match: match<{ planId: string }>
}

interface PlanSummaryInput extends FormInputField {}

const PlanInputList: PlanSummaryInput[] = [
  { property: "name", label: "Plan Name", type: "text", readonly: true },
  {
    property: "shortName",
    label: "Plan Short Name",
    type: "text",
    charactersLimit: 30,
  },
  { property: "id", label: "Plan ID", type: "text", readonly: true },
  {
    property: "fundType.value",
    label: "Plan Type",
    type: "text",
    readonly: true,
  },
  {
    property: "fundSubtype.value",
    label: "Plan Sub Type",
    type: "text",
    readonly: true,
  },
  {
    property: "fiscalMonth",
    label: "Fiscal Month End",
    type: "select",
    optionSource: "Month",
    tooltip: {
      icon: "question-circle",
      id: "fiscalMonthTooltip"
    }
  },
  {
    property: "custodian",
    label: "Custodian",
    type: "search",
    subtype: "single",
    searchTypes: [SearchTypes.Bank],
    tooltip: {
      icon: "question-circle",
      id: "custodianTooltip"
    }
  },
  {
    property: "recordKeeper",
    label: "Record Keeper",
    type: "search",
    subtype: "single",
    searchTypes: [SearchTypes.RecordKeeper],
    tooltip: {
      icon: "question-circle",
      id: "recordKeeperTooltip"
    }
  },
  {
    property: "isComposite",
    label: "Aggregate",
    type: "radio",
    subtype: "boolean",
    tooltip: {
      icon: "question-circle",
      id: "aggregateTooltip"
    }
  },
  {
    property: "plans",
    label: "Aggregated Plans",
    type: "custom",
    subClasses: {
      labelClasses:
        "col-sm-4 align-items-start",
    },
  },
  {
    property: "revalueTolerance",
    label: "Revalue Tolerance",
    type: "select",
    subtype: "single",
    required: true,
    options: [
      {code: 0.00, value: "0%"},
      {code: 0.01, value: "1%"},
      {code: 0.02, value: "2%"},
      {code: 0.03, value: "3%"},
      {code: 0.04, value: "4%"},
      {code: 0.05, value: "5%"},
      {code: 0.06, value: "6%"},
      {code: 0.07, value: "7%"},
      {code: 0.08, value: "8%"},
      {code: 0.09, value: "9%"},
      {code: 0.10, value: "10%"},
    ],
    optionsSortFunction: (a, b) => {console.log(a,b); return parseFloat(a?.code) - parseFloat(b?.code)},
    subClasses: {
      inputClasses:
        "text-left",
    },
  },
]

const AddressInputList: PlanSummaryInput[] = [
  {
    property: "",
    label: "",
    type: "",
  },
  { property: "client.address.street[0]", label: "Street 1", type: "text", readonly: true },
  { property: "client.address.street[1]", label: "Street 2", type: "text", readonly: true },
  { property: "client.address.city", label: "City", type: "text", readonly: true },
  { property: "client.address.state", label: "State", type: "select", subtype: "single", optionSource: "StateCode", readonly: true },
  { property: "client.address.zip", label: "Zip Code", type: "text", readonly: true },
]

const ConsultantsInputList: PlanSummaryInput[] = [
  {
    property: "",
    label: "Consultants",
    type: "",
    subClasses: {
      wrapperClasses:
        "row form-section-subtitle headline underline small-font-size py-2 mb-2 text-uppercase"
    }
  },
  { property: "consultant", label: "Primary Consultant", type: "text", readonly: true },
  { property: "backupConsultant", label: "Backup Consultant", type: "text", readonly: true },
]

const TermsInputList: PlanSummaryInput[] = [
  {
    property: "",
    label: "Terms",
    type: "",
    subClasses: {
      wrapperClasses:
        "row form-section-subtitle headline underline small-font-size py-2 mb-2 text-uppercase"
    }
  },
  { property: "serviceInfo.serviceStartDate", label: "Date Hired", type: "date", readonly: true, subClasses: { inputClasses: "text-left" } },
  { property: "serviceInfo.lengthOfService", label: "Length of Service", type: "text", readonly: true, tooltip: {icon: "question-circle",id: "lengthOfServiceTooltip"}},
  { property: "confidential", label: "Confidential", type: "radio", subtype: "boolean", readonly: true },
  { property: "serviceInfo.relationship", label: "Relationship", type: "text", readonly: true },
  { property: "discretionary.code", label: "Discretionary", type: "radio", subtype: "boolean", readonly: true },
  { property: "serviceInfo.terminationDate", label: "Terminated Date", type: "date", readonly: true, subClasses: { inputClasses: "text-left" } },
  { property: "serviceInfo.reasonForTermination", label: "Reason for Termination", type: "text", readonly: true },
]

const FootnoteInputList: PlanSummaryInput[] = [
  {
    property: "",
    label: "Footnote Library",
    type: "",
    subClasses: {
      wrapperClasses:
        "row form-section-subtitle headline underline small-font-size py-2 mb-2 text-uppercase"
    }
  },
]

const LeftColumnInputs = [
  ...PlanInputList,
  ...AddressInputList,
  ...TermsInputList,
]

const RightColumnInputs = [
  ...ConsultantsInputList,
  ...FootnoteInputList,
]

const PreShapeActions = {
  "client.address.street": {
    destinationPath: "client.address.street",
    action: (address: string) => {
      const splitAddress = (address || "").split(/\r?\n/)
      return [splitAddress[0], splitAddress.slice(1).join("")]
    }
  },
  "consultant": {
    destinationPath: "consultant",
    action: (person: any) => {
      if(person)
      return [person.firstName, person.lastName].join(" ")
    }
  },
  "backupConsultant": {
    destinationPath: "backupConsultant",
    action: (person: any) => {
      if(person)
      return [person.firstName, person.lastName].join(" ")
    }
  },
  "serviceProvider": {
    destinationPath: "serviceProvider",
    action: (serviceProvider: any) => {
      return serviceProvider || {}
    }
  }
}

const getInitialState = (data: PlanDetailsFragment) => {
  return reShapeObject(_.cloneDeep(data), PreShapeActions) as typeof data
}

const getFootnoteInitialState = (data: PlanDetailsFragment) => {
  return data.footnotes?.map((footnote) => ({
    footnote: footnote || DEFAULT_EMPTY_FOOTNOTE,
    previousFootnote: footnote,
    change: "none",
    relation: {
      plan: [data.id]
    }
  }) as FootnoteModifications) || []
}

const PlanSummaryMain: React.FC<idProps> = (props) => {
  const { data, planId, auth } = props
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const formattedData = getInitialState(data)
  const [initialState, setInitial] = useState(formattedData)
  const [currentState, setState] = useState(formattedData)
  const [footnoteModifications, setFootnoteModifications] = useState(getFootnoteInitialState(data))
  const history = useHistory()

  const [updatePlanSummary] = useUpdatePlanMutation()
  const [deletePlanServiceProvider] = useDeletePlanServiceProviderMutation()

  const [createFootnote] = useCreateFootnoteMutation()
  const [updateFootnote] = useUpdateFootnoteMutation()
  const [deleteFootnote] = useDeleteFootnoteMutation()
  const [copyFootnoteHistory] = useCopyFootnoteHistoryMutation()

  useEffect(() => {
    if (data) {
      const formattedData = getInitialState(data)
      setInitial(formattedData)
      setState(formattedData)
      setFootnoteModifications(getFootnoteInitialState(data))
    }
  }, [data])

  const handleInputChange = (value: any, property: any) => {
    let newState = getNewStateObject({
      state: currentState,
      newValue: value,
      property,
    }) as typeof currentState
    setState(newState)
  }

  const getEditableFields = (data: PlanDetailsFragment, previousData: PlanDetailsFragment) => {
    const currentPlans = compact(data.plans?.map((plan) => plan?.id)) || []
    const previousPlans = compact(previousData.plans?.map((plan) => plan?.id)) || []
    return {
      shortName: get(data, "shortName") || null,
      serviceProvider: omitBy({ custodianId: get(data, "custodian.id") || null, recordKeeperId: get(data, "recordKeeper.id") || null }, isNull),
      fiscalMonth: get(data, "fiscalMonth") || null,
      isComposite: get(data, "isComposite"),
      revalueTolerance: get(data, "revalueTolerance") || null,
      compositeMembers: { add: difference(currentPlans, previousPlans),  remove: difference(previousPlans, currentPlans) },
    }
  }

  const handleSubmit = () => {
    setSaving(true)
    const updatePlanFields = () => {
      let patch = getEditableFields(currentState, initialState)
      let input:UpdatePlanInput = {
        id: planId,
        patch,
      }
      updatePlanSummary({
        variables: {
          input,
        },
      })
        .then((result) => {
          setSaving(false)
          if (result.data?.updatePlan?.plan) {
            let newData = getInitialState(result.data?.updatePlan?.plan)
            setInitial(newData)
            setState(newData)
            setFootnoteModifications(getFootnoteInitialState(result.data.updatePlan.plan))
            setEditMode(false)
          }
        })
        .catch((err) => {
          setSaving(false)
          console.error(err.message)
        })
    }

    const deleteOrg = (type: "custodian" | "recordKeeper", callback: () => void) => {
      const orgId = get(initialState, `${type}.id`)
      const currentOrgId = get(currentState, `${type}.id`)
      if(orgId && !currentOrgId){
        let input:DeletePlanServiceProviderInput = {
          id: planId,
          patch: {orgId},
        }

        deletePlanServiceProvider({
          variables: {
            input,
          },
        })
          .then((result) => {
            callback()
          })
          .catch((err) => {
            callback()
            console.error(err.message)
          })
      } else {
        callback()
      }
    }

    SubmitFootnoteChanges({modifications: footnoteModifications, createFootnote, updateFootnote, deleteFootnote, copyFootnoteHistory})

    deleteOrg("custodian", () => deleteOrg("recordKeeper", updatePlanFields))
  }

  const addFootnote = () => {
    setFootnoteModifications([...footnoteModifications, {
      footnote: {...DEFAULT_EMPTY_FOOTNOTE, id: parseInt(uniqueId()) * -1},
      previousFootnote: undefined,
      change: "create",
      relation: {
        plan: [data.id]
      }
    }])
  }
  return (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <Container fluid className={"px-0"}>
        <Row>
          <Col>
            <div className="pane pane-toolbar sticky-top">
              <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
              Export CSV
              <img src='/assets/CSV.svg' className="ml-2"/>
              </Button>
              {auth.checkPermissions(["edit:manager"]) && (
                <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} disableOnError cancelEdit={() => {setState(initialState)
                  setFootnoteModifications(getFootnoteInitialState(data))}} />
              )}
            </div>
            <div className="pane mb-4 exportable-form" data-export-name="Plan Details">
              <div className='row form-section-title headline underline small-font-size pb-2 mb-2 text-left' >
                Plan Details
              </div>
              <Row>
                <Col sm="5" className="px-3" key={0}>
                  {LeftColumnInputs.map(({ property, label, type, subtype, placeholder, subClasses, readonly, required, tooltip, optionSource, options, searchTypes, charactersLimit, optionsSortFunction}, idx) => {
                    let propertyVal: any = _.get(currentState, property)
                    let inputChildren = <></>
                    let onChangeCallback = (value: any) => handleInputChange(value, property)
                    const setRequired = required || (type ==="date" && !!propertyVal)
                    let usedReadonly = readonly
                    if(property === "plans" && type === "custom") {
                      if(!currentState.isComposite){
                        return <React.Fragment key={idx}></React.Fragment>
                      }
                      inputChildren = <>
                        {currentState.client?.__typename === "Client" && currentState.client?.plans?.map((plan:any , planNum: number) => {
                          const isAggregated = !!find(currentState.plans, {'id': plan?.id})
                          if(currentState.id === plan?.id){
                            return (<React.Fragment key={planNum}></React.Fragment>)
                          } else if (editMode) {
                            onChangeCallback = (value: any) => {
                              const currentPlans = currentState.plans
                              let newPlans
                              if(value) {
                                newPlans = unionBy(currentPlans, [plan], 'id')
                              } else {
                                newPlans = differenceBy(currentPlans, [plan], 'id')
                              }
                              handleInputChange( newPlans, 'plans')
                            }
                            return (
                              <div key={planNum} className="row w-100">
                                <FormInput
                                  key={planNum}
                                  property={property}
                                  displayName={plan.name}
                                  subClasses={{
                                    labelClasses: "order-12",
                                    inputWrapperClasses: "order-1 pl-3",
                                    wrapperClasses: "flex-nowrap",
                                  }}
                                  type={"checkbox"}
                                  subtype={"boolean"}
                                  idx={planNum}
                                  editMode={editMode}
                                  propertyVal={isAggregated}
                                  updateValue={onChangeCallback}
                                />
                              </div>
                            )
                          } else {
                            if(isAggregated){
                              return (
                                <div key={planNum} className="row">
                                  <Col>
                                    <div className='mx-1 px-2 py-1'>
                                      {plan.name}
                                    </div>
                                  </Col>
                                </div>
                              )
                            }
                          }
                          return <React.Fragment key={planNum}></React.Fragment>
                        })}
                      </>
                    }
                    if(property === "revalueTolerance") {
                      usedReadonly = !auth.checkPermissions(["edit:tolerance"])
                      onChangeCallback = (value: any) => handleInputChange(parseFloat(value), property)
                    }else if(property === "discretionary.code") {
                      propertyVal = propertyVal && propertyVal === "DISC" || propertyVal === "BOTH" ? "true" : "false"
                    }
                    return (
                      <div key={idx}>
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          subClasses={subClasses}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          editMode={editMode}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          options={options}
                          optionsSortFunction={optionsSortFunction}
                          readonly={usedReadonly}
                          required={setRequired}
                          tooltip={tooltip}
                          defaultOptions={propertyVal}
                          searchTypes={searchTypes}
                          clearValueCallback={() => handleInputChange({id:"", name:""}, property)}
                          optionHasLink={true}
                          charactersLimit={charactersLimit}
                        >
                          {inputChildren}
                        </FormInput>
                      </div>
                    )
                  })}
                </Col>
                <Col sm="7" className="pl-5 pr-3" key={1}>
                  {RightColumnInputs.map(({ property, label, type, subtype, placeholder, subClasses, readonly, required, tooltip, optionSource, options, searchTypes }, idx) => {
                    let propertyVal: any = _.get(currentState, property)
                    let onChangeCallback = (value: any) => handleInputChange(value, property)
                    const setRequired = required || (type ==="date" && !!propertyVal)
                    return (
                      <div key={idx}>
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          subClasses={subClasses}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          editMode={editMode}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          options={options}
                          readonly={readonly}
                          required={setRequired}
                          tooltip={tooltip}
                          defaultOptions={propertyVal}
                          searchTypes={searchTypes}
                        />
                      </div>
                    )
                  })}
                  {footnoteModifications.map((footnoteModification, idx) => {
                    const onChangeCallback = (value: FootnoteModifications) => {
                      const newFootnoteModifications = iassign(
                        footnoteModifications,
                        list => list[idx],
                        obj => {
                          return value
                        }
                      )
                      setFootnoteModifications(newFootnoteModifications)
                    }
                    return (
                      <Row key={footnoteModification.previousFootnote?.id || footnoteModification.footnote.id || idx}>
                        <Col>
                          <FootnoteComponent
                            page={"plan"}
                            footnoteModification={footnoteModification}
                            editMode={editMode}
                            handleChange={(value: FootnoteModifications) => onChangeCallback(value)}
                          />
                        </Col>
                      </Row>
                    )
                  })}
                  {editMode &&
                    <Row>
                      <Button color="link btn-thin" className="mr-1 text-callan-blue mt-2" onClick={addFootnote}>
                        Add Footnote
                        <FontAwesomeIcon
                          icon="plus-circle"
                          className="ml-2 text-callan-blue"
                        />
                      </Button>
                    </Row>
                  }
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const PlanSummary: React.FC<appProps & PlanRouteProps & PlanSummaryDetailFragment> = (props) => {
  const { planId, auth, match, data } = props

  if (!!data) {
    return <PlanSummaryMain data={data} auth={auth} match={match} planId={planId} />
  } else {
    return <div>data doesn't exist.</div>
  }
}

export default PlanSummary
