import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { compact, debounce, first, get, uniqBy } from 'lodash'
import React, { useRef, useState } from 'react'
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupButtonDropdown, Label, ListGroup, ListGroupItem, Row } from 'reactstrap'
import Auth from '../../Auth/Auth'
import { AutocompleteFilters, AutocompleteQueryVariables, AutocompleteVehicleFragment, ClientPortfolio, ManagerAutocomplete, PlanAutocomplete, SearchAutocompleteFragment, SearchTypes, VehicleStatusCode, useAutocompleteQuery, useManagerVehiclesQuery, usePlanPortfoliosQuery } from '../../__generated__/graphql'
import { FormInput } from '../ui/Forms/FormInput'
import { AutocompleteItemTypes, SearchRows } from './SearchAutocomplete'
import { SearchDisplayToTypes, SearchTypeDisplays, SearchTypeListDisplaysOrder } from './SearchEnums'

interface SearchAddStaticProps {
  handleAdd: (addItem:AutocompleteItemTypes, linkUrl: string) => void
  includedRows?: string[]
  auth?: Auth
  searchedTypes?: string[]
  dropdown?: boolean
  forPerformanceComparison?: boolean
  filters?: AutocompleteFilters
  inlineField?: boolean
}

type SecondarySearchable = PlanAutocomplete | ManagerAutocomplete | null

const SearchAddStatic: React.FC<SearchAddStaticProps> = ({ auth, includedRows, handleAdd: parentHandleAdd, searchedTypes, dropdown, forPerformanceComparison, filters, inlineField }) => {
  const searchedTypesList = searchedTypes || SearchTypeListDisplaysOrder
  let [type, setType] = useState<SearchTypeDisplays>(first(searchedTypesList) as SearchTypeDisplays)
  let [search, setSearch] = useState<string>("")
  let [debouncedSearch, setDebouncedSearch] = useState<string>("")
  const [selectedSecondary, setSelectedSecondary]= useState<PlanAutocomplete | ManagerAutocomplete | null>(null)
  const [dropdownListOpen, setDropdownListOpen] = useState(false)
  const [dropdownTypeOpen, setDropdownTypeOpen] = useState(false)

  const toggleList = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    setDropdownListOpen(!dropdownListOpen)
  }

  const toggleType = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    setDropdownTypeOpen(!dropdownTypeOpen)
  }

  const handleAdd = (addItem:AutocompleteItemTypes, linkUrl: string) => {
    parentHandleAdd(addItem, linkUrl)
    if (inlineField) {
      setDropdownListOpen(false)
    }
  }

  const handleDebouncedSearch = useRef(debounce((nextValue:string) => setDebouncedSearch(nextValue), 200)).current
  const handleSearch = (value: string) => {
    setSearch(value)
    handleDebouncedSearch(value)
    if(dropdown) setDropdownListOpen(true)
  }

  let secondarySearch
  if(type === SearchTypeDisplays['Portfolio']){
    secondarySearch = SearchTypes.Plan
  } else if(forPerformanceComparison && type === SearchTypeDisplays['Vehicles']){
    secondarySearch = SearchTypes.Manager
  }

  const inputContent = (
    <InputGroup className={classNames({'pl-1': !inlineField})}>
      { inlineField && searchedTypes?.length === 1 && (
        <Label className="col-form-label col-sm-4" >{ searchedTypes[0] }</Label>
      )}
      { !inlineField && (
        <Dropdown isOpen={dropdownTypeOpen} className="dropdown-select search-bar" toggle={toggleType}>
          <DropdownToggle className="btn-toggle">
            { type }
            <FontAwesomeIcon
              icon="chevron-down"
              className="fontawesome-icon ml-2"
            />
          </DropdownToggle>
          <DropdownMenu>
            {
              searchedTypesList.map(displayType => {
                const checkPermission = (type:SearchTypeDisplays, permission:string[]) => {
                  return displayType === type && !auth?.checkPermissions(permission)
                }

                if (checkPermission(SearchTypeDisplays.Manager, ["view:all_managers"]) || checkPermission(SearchTypeDisplays.Client, ["view:all_clients"]) || checkPermission(SearchTypeDisplays.Documents, ["search:documents"])) {
                  return <React.Fragment key={`type-option-${displayType}`}></React.Fragment>
                }

                return (<DropdownItem onClick={() => setType(displayType as SearchTypeDisplays)} key={`type-option-${displayType}`} className={type === displayType ? 'active' : ''}>{displayType}</DropdownItem>)
              })
            }
          </DropdownMenu>
        </Dropdown>
      )}
      {secondarySearch &&
        <FormInput
          property={"plan-search"}
          displayName={""}
          type={"search"}
          subtype={"single"}
          placeholder={`First find ${secondarySearch} by name or ID`}
          staticText={`${secondarySearch}:`}
          idx={1}
          editMode={true}
          propertyVal={selectedSecondary ? selectedSecondary : null}
          updateValue={(value) => setSelectedSecondary(value || null)}
          searchTypes={[secondarySearch]}
          subClasses={{wrapperClasses: "no-gutters input-group list-fix-input-form my-0 mr-1", inputClasses: "search-bar placeholder-gray-50"}}
        />
      }
      {!secondarySearch &&
        <>
          <Input
            type="text"
            className="form-control search-bar"
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Find by Name or Id"
            value={search}
          />

          <InputGroupButtonDropdown addonType="append" className="o-88 absolute center-v right-1 pe-none">
            <FontAwesomeIcon
              icon={["fas", "search"]}
              size="2x"
              className="fontawesome-icon dark-icon-color text-gray-50"
            />
          </InputGroupButtonDropdown>
        </>
      }
    </InputGroup>
  )

  const searchContent = (
    <>
      {debouncedSearch !== "" && !secondarySearch &&
        <StaticSearch
          query={debouncedSearch}
          type={type}
          onClick={handleAdd}
          includedRows={includedRows}
          filters={filters}
        />
      }
      {secondarySearch && type === SearchTypeDisplays['Portfolio'] &&
        <StaticPortfolioSearch
          selectedPlan={selectedSecondary}
          onClick={handleAdd}
          includedRows={includedRows}
          forPerformanceComparison={forPerformanceComparison}
        />
      }
      {secondarySearch && type === SearchTypeDisplays['Vehicles'] &&
        <StaticVehicleSearch
          selectedManager={selectedSecondary}
          onClick={handleAdd}
          includedRows={includedRows}
          forPerformanceComparison={false}
        />
      }
    </>
  )

  if(dropdown){
    let dropDownInput = inputContent
    if (!inlineField) {
      dropDownInput = (
        <DropdownToggle caret className={classNames('data-dropdown', {'hide-caret': true})}>
          { inputContent }
        </DropdownToggle>
      )
    }

    return (
      <div className='h-100'>
        <Dropdown isOpen={dropdownListOpen} toggle={toggleList} className={classNames("headline-dropdown person-picker-dropdown pr-0", { 'inline-static-search-dropdown row no-gutters': inlineField })}>
          <DropdownToggle caret className={classNames('data-dropdown', {'hide-caret': true, 'border-0': inlineField })}>
            { inputContent }
          </DropdownToggle>
          <DropdownMenu>
            { searchContent }
          </DropdownMenu>
        </Dropdown>
        { inlineField && includedRows && includedRows?.length > 0 && (
          <div className='row pt-2'>
            <div className='col-sm-4'></div>
            <div className='col-sm-8'>
              <strong>Portfolio:</strong> { includedRows[0] }</div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="h-100">
      { inputContent }
      { searchContent }
    </div>
  )
}

interface StaticSearchProps {
  query: string,
  type: SearchTypeDisplays
  disabled?: boolean // to ensure dropdown closed in viewMode
  onClick: (addItem:AutocompleteItemTypes, linkUrl: string) => void
  includedRows?: string[]
  filters?: AutocompleteFilters
}

export const StaticSearch:React.FC<StaticSearchProps> = ({ query, type, disabled, onClick, includedRows, filters }) => {
  let searchType = get(SearchDisplayToTypes,type) as unknown as SearchTypes
  let autocompleteVars:AutocompleteQueryVariables = { q: query }
  autocompleteVars.types = [searchType]
  autocompleteVars.filters = (searchType === SearchTypes.Group && filters?.group) ? {group: filters.group} : {}

  const { data } = useAutocompleteQuery({ variables: autocompleteVars})

  if (!!data && data.autocomplete) {
    if(disabled) {
      return <></>
    }
    return (
      <div className="list-sidebar-container h-100 overflow-y-auto">
        <ListGroup className="horizontal with-category autocomplete has-includes">
          <SearchRows
            rows={data.autocomplete as SearchAutocompleteFragment[]}
            handleClick={onClick}
            includedRows={includedRows}
          />
        </ListGroup>
      </div>
    )
  }
  return <></>
}

interface StaticPortfolioSearchProps {
  selectedPlan: SecondarySearchable
  onClick: (addItem:AutocompleteItemTypes, linkUrl: string) => void
  includedRows?: string[]
  forPerformanceComparison?: boolean
  singleSelect?: boolean
  modalSelection?: boolean
}

export const StaticPortfolioSearch:React.FC<StaticPortfolioSearchProps> = ({ onClick, includedRows, selectedPlan, forPerformanceComparison, singleSelect, modalSelection = false }) => {
  const [portfolioQuery, setPortfolioQuery]= useState("")

  const { data } = usePlanPortfoliosQuery({ variables: {id: selectedPlan?.id || 0, includeTargetsAndGroup: forPerformanceComparison || false}, skip: selectedPlan === null})

  const onClickPortfolio = (portfolio:ClientPortfolio, linkUrl: string) => {
    onClick(portfolio, linkUrl)
    console.log(portfolio)
  }

  const searchBars = (
    <div className={classNames('list-add-connected-inputs', { 'mr-1': !modalSelection })}>
      {selectedPlan &&
        <div className='form-group input-group row no-gutters'>
          <Label
            className={classNames("col-form-label", `col-sm-${modalSelection ? 3 : 5}`)}
            for="portfolio-searchable"
          >
            <div
              className={classNames("d-flex w-100 justify-content-end mr-2", { "font-weight-bold": !modalSelection })}
            >
              { modalSelection ? "Filter portfolios:" : "Filter Results:" }
            </div>
          </Label>
          <div className={`col-sm-${modalSelection ? 9 : 7}`}>
            <Input
              type="text"
              id="portfolio-searchable"
              className={classNames("form-control search-bar ml-0 border-gray-30 placeholder-gray-50", { "mr-0": modalSelection })}
              onChange={(e) => setPortfolioQuery(e.target.value)}
              placeholder="Find portfolio by name or ID"
              value={portfolioQuery}
              name="portfolio-searchable"
            />
          </div>
        </div>
      }
    </div>
  )

  if (!!data && data.plan?.clientPortfolios) {
    const sortedPortfolios = data.plan?.clientPortfolios?.filter((portfolio)=>{
      if(!portfolio?.isActive){
        return false
      }
      if(portfolioQuery.length === 0){
        return true
      }
      if(portfolio?.name?.toLowerCase()?.includes(portfolioQuery.toLowerCase()) || portfolio?.id?.toString()?.includes(portfolioQuery.toLowerCase())){
        return true
      }
      return false
    })


    return (
      <>
        {searchBars}
        <div className={classNames("list-sidebar-container overflow-y-auto", { "mt-2 border border-gray-30": modalSelection, "h-100": !modalSelection })} style={{maxHeight: modalSelection ? "300px" : "auto"}}>
          <ListGroup className="horizontal with-category autocomplete has-includes">
            {sortedPortfolios.map((portfolio, idx) => {
              if(!portfolio) return (<React.Fragment key={idx}></React.Fragment>)
              const included = includedRows?.includes(`ClientPortfolio:${portfolio.id}`)
              return(
                <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => onClickPortfolio(portfolio, `clientportfolios/${portfolio?.id}/overview`)} key={`client-portfolio-${portfolio.id}`}>
                <div className="category portfolio">
                  <div className="category-text">
                    <h6>Portfolio</h6>
                  </div>
                </div>
                <div className="w-100">
                  <Row>
                    <Col md={6}>
                      <h3>{portfolio.name}</h3>
                      <p>Related to {data.plan?.name}</p>
                    </Col>
                    <Col md={3}>
                      <h5>Portfolio ID</h5>
                      <p>{portfolio.id}</p>
                    </Col>
                    <Col md={3}>
                      {included && !modalSelection && <h5>Included</h5>}
                    </Col>
                  </Row>
                </div>
                {!included && (
                  <div className="hover-overlay">
                    <Button className="add-button">
                      Add
                    </Button>
                  </div>
                )}
              </ListGroupItem>
              )
            })}
          </ListGroup>
        </div>
      </>
    )
  }
  return(
    <>
      {searchBars}
    </>
  )
}

interface StaticVehicleSearchProps {
  selectedManager: SecondarySearchable
  onClick: (addItem:AutocompleteItemTypes, linkUrl: string) => void
  includedRows?: string[]
  forPerformanceComparison?: boolean
}

export const StaticVehicleSearch:React.FC<StaticVehicleSearchProps> = ({ onClick, includedRows, selectedManager, forPerformanceComparison }) => {
  const [vehicleQuery, setVehicleQuery]= useState("")

  const { data } = useManagerVehiclesQuery({ variables: {id: selectedManager?.id || 0}, skip: selectedManager === null})

  const searchBars = (
    <div className='list-add-connected-inputs mr-1'>
      {selectedManager &&
        <div className='form-group input-group row no-gutters'>
          <Label
            className={"col-form-label col-sm-5"}
            for="vehicle-searchable"
          >
            <div
              className={"d-flex w-100 justify-content-end mr-2 font-weight-bold"}
            >
              Filter Results:
            </div>
          </Label>
          <div className='col-sm-7'>
            <Input
              type="text"
              id="vehicle-searchable"
              className="form-control search-bar ml-0 border-gray-30 placeholder-gray-50"
              onChange={(e) => setVehicleQuery(e.target.value)}
              placeholder="Find vehicle by name, ID, CUISP, or Ticker"
              value={vehicleQuery}
              name="vehicle-searchable"
            />
          </div>
        </div>
      }
    </div>
  )

  if (!!data && data.org?.__typename === "Manager" && data.org.products) {
    const allVehicles = uniqBy(compact(data.org.products?.flatMap((product)=>{
      return product?.product?.vehicles?.map((vehicle) => ({vehicle: vehicle?.vehicle, product: product.product, manager: data.org })) || []
    })), "vehicle.id")
    const sortedVehicles = allVehicles.filter((vehicle)=>{
      if(vehicle.vehicle?.status?.code === VehicleStatusCode.t){
        return false
      }
      if(vehicleQuery.length === 0){
        return true
      }
      if(
        vehicle?.vehicle?.name?.toLowerCase()?.includes(vehicleQuery.toLowerCase()) ||
        vehicle?.vehicle?.id?.toString()?.toLowerCase()?.includes(vehicleQuery.toLowerCase()) ||
        vehicle?.vehicle?.identifiers?.cusip?.toString()?.toLowerCase()?.includes(vehicleQuery.toLowerCase()) ||
        vehicle?.vehicle?.identifiers?.ticker?.toString()?.toLowerCase()?.includes(vehicleQuery.toLowerCase())
      ){
        return true
      }
      return false
    })

    return (
      <>
        {searchBars}
        <div className="list-sidebar-container h-100 overflow-y-auto">
          <ListGroup className="horizontal with-category autocomplete has-includes">
            {sortedVehicles.map((vehicle, idx) => {
              if(!vehicle.vehicle) return (<React.Fragment key={idx}></React.Fragment>)
              const included = includedRows?.includes(`Vehicle:${vehicle.vehicle.id}`)
              const fakeAutoComplete:AutocompleteVehicleFragment = {
                vehicleId: vehicle.vehicle.id,
                ticker: vehicle.vehicle.identifiers?.ticker,
                cusip: vehicle.vehicle.identifiers?.cusip,
                vehicleName: vehicle.vehicle.name,
                managerName: vehicle.manager?.name,
                productId: vehicle.product?.id,
                vehicleProductName: vehicle.product?.name || "",
                vehicleType: vehicle.vehicle.status?.code,
                __typename: "VehicleAutocomplete",
              }
              const cusip = (!!fakeAutoComplete.cusip || !!fakeAutoComplete.ticker) ? `${fakeAutoComplete.cusip || ""}/${fakeAutoComplete.ticker || ""}` : ''
              return(
                <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => onClick(fakeAutoComplete, `/products/${vehicle.product?.id}/vehicles/${vehicle.vehicle?.id}`)} key={`vehicle-${vehicle.vehicle.id}`}>
                <div className="category vehicle">
                  <div className="category-text">
                    <h6>Vehicle</h6>
                  </div>
                </div>
                <div className="w-100">
                  <Row>
                    <Col md={6}>
                      <h3>{ fakeAutoComplete.vehicleName }</h3>
                      <p>Related to {fakeAutoComplete.managerName}</p>
                    </Col>
                    <Col md={2}>
                      <h5>Vehicle ID</h5>
                      <p>{fakeAutoComplete.vehicleId}</p>
                    </Col>
                    <Col md="2" className="pr-0">
                      <h5>CUSIP</h5>
                      <p className="text-break">{cusip}</p>
                    </Col>
                    <Col md={2}>
                      {included && <h5>Included</h5>}
                    </Col>
                  </Row>
                </div>
                {!included &&
                  <div className="hover-overlay">
                    <Button className="add-button">
                      Add
                    </Button>
                  </div>
                }
              </ListGroupItem>
              )
            })}
          </ListGroup>
        </div>
      </>
    )
  }
  return(
    <>
      {searchBars}
    </>
  )
}

export default SearchAddStatic