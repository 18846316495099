import { disableFragmentWarnings, gql } from 'graphql-tag';

import {
  MANAGER_OWNERSHIP_FRAGMENT,
  MANAGER_ASSETS_CLIENT,
  MANAGER_EMPLOYEES_FRAGMENT,
  FILE_BASIC_INFO_FRAGMENT,
  MANAGER_EMPLOYEES_BREAKDOWN_OTHER_MINORITY_FRAGMENT,
} from "./Fragments"
import { TOOLTIP_FRAGMENT } from './Tooltip'
import { VEHICLE_SIMPLE_FRAGMENT } from "./VehicleFragments"

disableFragmentWarnings();
export const ORG_QUERY = gql`
  query Orgs($offset: Int, $limit: Int, $type: [OrgType!]) {
    orgs(filters: { offset: $offset, limit: $limit, type: $type }) {
      id
      name
      type {
        code
        value
      }
    }
  }
`;

export const MANAGER_OWNERSHIP_QUERY = gql`
  query ManagerOwnership($id: Int!, $tooltip_ids: [Int!]!) {
    org(id: $id) {
      id
      ... on Manager {
        ownership {
          id
          name
          title
          yearAdmittedAsOwner
          investmentTeamMember
          type {
            code
            value
          }
          ownershipAmount {
            year
            economicOwnershipPercent
            controllingOwnershipPercent
            updateDate
          }
        }
        ownershipExplanation
      }
    }
    tooltips(ids: $tooltip_ids) {
      ...TooltipFragment
    }
  }
  ${TOOLTIP_FRAGMENT}
`;

export const UPDATE_MANAGER_OWNERSHIP_MUTATION = gql`
  mutation UpdateManagerOwnership($input: UpdateOrgOwnershipInput!) {
    updateOrgOwnership(input: $input) {
      orgId
      ...ManagerOwnershipFragment
    }
  }
  ${MANAGER_OWNERSHIP_FRAGMENT}
`;

export const CREATE_MANAGER_OWNERSHIP_MUTATION = gql`
  mutation CreateManagerOwnership($input: CreateOrgOwnershipInput!) {
    createOrgOwnership(input: $input) {
      orgId
      ...ManagerOwnershipFragment
    }
  }
  ${MANAGER_OWNERSHIP_FRAGMENT}
`;

export const DELETE_MANAGER_OWNERSHIP_MUTATION = gql`
  mutation DeleteManagerOwnership($input: DeleteInput!) {
    deleteOrgOwnership(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_MANAGER_OWNERSHIP_EXPLANATION_MUTATION = gql`
  mutation UpdateManagerOwnershipExplanation(
    $input: OwnershipExplanationInput!
  ) {
    upsertOwnershipExplanation(input: $input) {
      org {
        id
        ... on Manager {
          ownershipExplanation
        }
      }
    }
  }
  ${MANAGER_OWNERSHIP_FRAGMENT}
`;

export const MANAGER_QUERY = gql`
  query AllManagers($offset: Int, $limit: Int, $type: [OrgType!]) {
    orgs(filters: { offset: $offset, limit: $limit, type: $type }) {
      name
      id
      ... on Manager {
        totalAssetsUnderManagement {
          assetsUnderManagement
        }
        address {
          city
          state
        }
        website
        # primary contact could be null
        primaryContact {
          firstName
          lastName
        }
        phone
        isInactive
        products {
          product {
            id
          }
        }
      }
    }
  }
`;

export const MANAGER_ASSETS_QUERY = gql`
  query AssetsByClient(
    $id: Int!
    $tooltip_ids: [Int!]!
    $startDate: Date
    $endDate: Date
    $clientType: ClientType
    $categoryType: CategoryType
  ) {
    assets: org(id: $id) {
      id
      name
      ...ManagerAssetsClientFragment
    }
    tooltips(ids: $tooltip_ids) {
      ...TooltipFragment
    }
  }
  ${MANAGER_ASSETS_CLIENT}
  ${TOOLTIP_FRAGMENT}
`;

export const UPDATE_MANAGER_ASSETS_CLIENT_MUTATION = gql`
  mutation UpdateAssetsByClient(
    $input: ManagerAssetsInput!
    $startDate: Date
    $endDate: Date
    $clientType: ClientType
    $categoryType: CategoryType
  ) {
    assets: upsertManagerAssets(input: $input) {
      org {
        id
        ...ManagerAssetsClientFragment
      }
    }
  }
  ${MANAGER_ASSETS_CLIENT}
`;

const MANAGER_STRUCTURE_FRAGMENT = gql`
  fragment ManagerStructure on Org {
    ... on Manager {
      structure {
        yearFounded
        parentCompany
        yearParentFounded
        firmStructureDescription
        employeeOwners
        minorityOwned
        minorityOwnerType {
          code
          value
          definition
        }
        womanOwned
        minorityWomanOwned
        disabledOwned
        history {
          callanDescription
          managerDescription
        }
        jointVentureAffiliations
        ownershipType {
          code
          value
        }
      }
      hedgeFundOfFunds {
        primeBroker
        hedgeAffiliation
        numberOfDistinctManagers {
          count
          year
        }
      }
      materialChanges {
        date
        body
      }
      documents {
        ...FileBasicInfoFragment
      }
    }
  }
  ${FILE_BASIC_INFO_FRAGMENT}
`;

const MANAGER_GENERALINFO_FRAGMENT = gql`
  fragment ManagerGeneralInfo on Org {
    name
    shortName
    # phone
    # fax
    website
    isConfidential
    legal_firm_type: type {
      code
      value
    }
    industry {
      code
      value
    }
    address {
      street
      city
      state
      zip
      country
    }
    isInactive
    owner {
      firstName
      lastName
    }
  }
`;

export const MANAGER_SUMMARY_QUERY = gql`
  query ManagerSummary($id: Int!, $tooltip_ids: [Int!]!) {
    generalInfo: org(id: $id) {
      id
      ...ManagerGeneralInfo
    }
    structure: org(id: $id) {
      id
      ...ManagerStructure
    }
    tooltips(ids: $tooltip_ids) {
      ...TooltipFragment
    }
  }
  ${MANAGER_GENERALINFO_FRAGMENT}
  ${MANAGER_STRUCTURE_FRAGMENT}
  ${TOOLTIP_FRAGMENT}
`;

export const MANAGER_DETAIL_QUERY = gql`
  query ManagerDetails($id: Int!) {
    overview: org(id: $id) {
      id
      name
      isInactive
      ... on Manager {
        managerTypes {
          code
        }
      }
    }
  }
`;

export const UPDATE_MANAGER_DETAIL_MUTATION = gql`
  mutation UpdateManagerDetails($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      generalInfo: org {
        id
        ...ManagerGeneralInfo
      }
      structure: org {
        id
        ...ManagerStructure
      }
    }
  }
`;

export const MANAGER_EMPLOYEE_QUERY = gql`
  query EmployeeBreakdown($id: Int!, $startDate: Date, $endDate: Date, $tooltip_ids: [Int!]!) {
    org(id: $id) {
      ...ManagerEmployeesFragment
    }
    tooltips(ids: $tooltip_ids) {
      ...TooltipFragment
    }
  }
  ${MANAGER_EMPLOYEES_FRAGMENT}
  ${TOOLTIP_FRAGMENT}
`;

export const UPDATE_MANAGER_EMPLOYEES_MUTATION = gql`
  mutation updateManagerEmployees(
    $input: EmployeeTurnoverInput!
    $startDate: Date
    $endDate: Date
  ) {
    upsertEmployeeTurnover(input: $input) {
      org {
        ...ManagerEmployeesFragment
      }
    }
  }
  ${MANAGER_EMPLOYEES_FRAGMENT}
`;

export const UPDATE_MANAGER_EMPLOYEES_BREAKDOWN_DETAIL_MUTATION = gql`
  mutation updateManagerEmployeeBreakdownDetail(
    $input: EmployeeBreakdownDetailInput!
    $startDate: Date
    $endDate: Date
  ) {
    upsertEmployeeBreakdownDetail(input: $input) {
      org {
        ...ManagerEmployeesFragment
      }
    }
  }
  ${MANAGER_EMPLOYEES_FRAGMENT}
`;

export const CREATE_ORG_OWNERSHIP_MUTATION = gql`
  mutation CreateOrgOwnership($input: CreateOrgOwnershipInput!) {
    createOrgOwnership(input: $input) {
      orgId
    }
  }
`;

export const DELETE_ORG_ESG_VENDORS_MUTATION = gql`
  mutation DeleteManagerEsgVendors($input: deleteEsgOrgVendorInput!) {
    deleteEsgOrgVendors(input: $input) {
      status
      message
    }
  }
`;

export const DELETE_ORG_ESG_SIGNATORY_BODIES_MUTATION = gql`
  mutation DeleteOrgEsgSignatoryBodies(
    $input: deleteEsgOrgSignatoryBodiesInput!
  ) {
    deleteEsgOrgSignatoryBodies(input: $input) {
      status
      message
    }
  }
`;

export const DELETE_ORG_ESG_DIVERSITY_OVERSIGHT_MUTATION = gql`
  mutation DeleteOrgEsgDiversityOversight(
    $input: DeleteOrgDiversityOversightTypesInput!
  ) {
    deleteOrgDiversityOversightTypes(input: $input) {
      status
      message
    }
  }
`;

export const DELETE_ORG_MATERIAL_CHANGES = gql`
  mutation DeleteOrgMaterialChanges($input: DeleteOrgMaterialChangesInput!) {
    deleteOrgMaterialChanges(input: $input) {
      status
      message
    }
  }
`

export const MANAGER_VEHICLES_QUERY = gql`
  query ManagerVehicles($id: Int!) {
    org(id: $id) {
      id
      name
      ... on Manager {
        products {
          product {
            id
            name
            vehicles {
              vehicle {
                ...VehicleSimpleFragment
              }
            }
          }
        }
      }
    }
  }
  ${VEHICLE_SIMPLE_FRAGMENT}
`
