import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import iassign from 'immutable-assign'
import _, { cloneDeep } from 'lodash'
import React, { FormEvent, Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, FormGroup, Progress, Row, Table } from 'reactstrap'

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import {
  CallanDeiScores,
  DeleteUnpriModuleScoreItemInput,
  EsgSignatoryBodyType,
  DiversityOversightTypeCode,
  ManagerDiversityFragment,
  Maybe,
  OrgUpdateFields,
  TooltipFragment,
  UnpriModuleScoreItem,
  UpdateOrgInput,
  useDeleteUnpriMutation,
  useManagerDiversityQuery,
  useGetManagerEsgLookupQuery,
  useUpdateManagerDiversityMutation,
  useDeleteOrgEsgDiversityOversightMutation,
} from '../../__generated__/graphql'
import { FormInputField } from '../../helpers/constant'
import { CheckBoxes, optionProps } from '../ui/CheckBoxes'
import { excludePropertyArray, InitialStateFormat, sortArrayByColumn, convertLookupToString } from '../../helpers/object'
import { FormInput } from '../ui/Forms/FormInput'
import PlaceHolder from './../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { useHistory } from 'react-router-dom'
import numbro from 'numbro'

// constants setting here
const EXPLANATION_CHARACTER_LIMIT = 60

type Props = {
  managerId: number
  auth: Auth
}

type ManagerEsgProps = {
  __typename: "Manager"
  id: number
  options: { [name: string]: optionProps[] }
  auth: Auth
  tooltipsData: Maybe<TooltipFragment>[]
} & ManagerDiversityFragment

const getInitialData = (data: React.PropsWithChildren<ManagerEsgProps>) => {
  let { managerEsgDiversityInclusionPolicy: originalData, callanDeiScores } = data
  let managerEsgDiversityInclusionPolicy = cloneDeep(originalData)
  if (!callanDeiScores) {
    callanDeiScores = {
      weightedScore: null,
      numberAnswered: null,
      completeAnswer: null
    } as CallanDeiScores
  }
  if (!managerEsgDiversityInclusionPolicy){
    managerEsgDiversityInclusionPolicy = (InitialStateFormat(DiversityInput, {}) as any).managerEsgDiversityInclusionPolicy
  }
  if(managerEsgDiversityInclusionPolicy?.freqUpdatesToCode === null){
    managerEsgDiversityInclusionPolicy.freqUpdatesToCode = {code: null, value: null, __typename: "DiversityFrequencyLookup"}
  }
  if(managerEsgDiversityInclusionPolicy?.freqEmpSignOrReview === null){
    managerEsgDiversityInclusionPolicy.freqEmpSignOrReview = {code: null, value: null, __typename: "DiversityFrequencyLookup"}
  }
  return {managerEsgDiversityInclusionPolicy, callanDeiScores} as any
}

const getColor = (value: number | string) => {
  let colorClass: string = ""
  if(value < 1 || value === "N/A") {
    colorClass = "lower-than-one"
  }else if (value < 2) {
    colorClass = "lower-than-two"
  }else {
    colorClass = "lower-than-three"
  }
  return colorClass
}


const DEI_SCORE_DESCRIPTION = (
  <span className="progressbar-description pl-4">
    {"The DEI score is a quantitative measure that is based on an organization's response to a set of eight DEI questions as well as ownership and total employee demographics. The DEI score ranges from 0 to 3, with 3 being the maximum for the degree to which the organization has demonstrated its commitment to DEI."}
    <br/>
    <br/>
    {"Of note, the score is meant to be a high level assessment of where a firm lies in addressing DEI and is not meant to be a precise measure nor to capture all of the elements that we would consider if doing a qualitative in-depth assessment. Scores are calculated only for organizations that respond to all questions and demographics to ensure an even playing field. Callan does not guarantee that the manager responses are accurate."}
  </span>)

const Display: React.FC<ManagerEsgProps> = data => {
  const { id, options, auth } = data
  const history = useHistory()
  const [currentState, setState] = useState(() => getInitialData(data))
  const [initialState, setInitial] = useState(
    currentState as { [name: string]: any }
  )

  const [tooltipsData, setTooltipsData] = useState(data?.tooltipsData)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateManagerDiversity] = useUpdateManagerDiversityMutation()
  const [deleteOrgEsgDiversityOversight] = useDeleteOrgEsgDiversityOversightMutation()
  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }
  const toggleEditMode = () => {
    setState(initialState)
    setEditMode(!editMode)
  }

  const handleSave = () => {
    setSaving(true)
    let diversityData = convertLookupToString(_.get(currentState, "managerEsgDiversityInclusionPolicy"),false, ["freqEmpSignOrReview", "freqUpdatesToCode", "frequencyOfTrainingOffered", "oversightType"])
    const updateData = {
      patch: {
        managerEsgDiversityInclusionPolicy: excludePropertyArray(diversityData, ["__typename"])
      } as OrgUpdateFields
    }

    const updateOrgInput = { id, ...updateData } as UpdateOrgInput

    // Perform list removals
    if (initialState.managerEsgDiversityInclusionPolicy.diversityOversightTypes) {
      const initialDiversityOversightTypes = initialState.managerEsgDiversityInclusionPolicy.diversityOversightTypes.map((component: any) => component.oversightType?.code)
      const currentDiversityOversightTypes = diversityData.diversityOversightTypes.map((o:any) => o?.oversightType)
      const diversityOversightTypesRemovals = _.difference(initialDiversityOversightTypes, currentDiversityOversightTypes)

      if (diversityOversightTypesRemovals.length > 0) {
        deleteOrgEsgDiversityOversight({
          variables: {
            input: {
              id: id,
              type: (diversityOversightTypesRemovals as DiversityOversightTypeCode[])
            }
          }
        })
      }
    }

    updateManagerDiversity({ variables: { input: updateOrgInput } })
      .then(result => {
        let data = result.data?.updateOrg?.org
        if (data) {
          let formattedData = getInitialData(data as ManagerEsgProps)
          setInitial(formattedData)
          setState(formattedData)
          setSaving(false)
          setEditMode(false)
        }
      })
      .catch(err => {
        console.log(err.message)
        setSaving(false)
      })
  }

  const handleSubmit = () => {
    if (editMode) {
      // preCheck
      if(!auth.checkPermissions(["edit:manager"])){
        return
      }
      //save
      handleSave()
    } else {
      // readonly
    }
  }

  const handleCheckboxesChangeToState = (
    input: optionProps[],
    subvalue: string
  ) => {
    if (subvalue === "esgSignatoryBodyTypeOptions") {
      const transformedInput = input.map(option => ({code: option.code, value: option.value}))

      const newState = iassign(
        currentState,
        currentState => currentState?.managerEsgPolicyDescription,
        process => ({
          ...process,
          signatoryBodyList: transformedInput
        })
      )
      setState(newState)
    } else if (subvalue === "diversityOversightTypeCode") {
      const transformedInput = input.map(option => {return {oversightType: {code: option.code, value: option.value}, oversightText: currentState.managerEsgDiversityInclusionPolicy?.diversityOversightTypes?.find((o:any) => o.oversightType?.code === option.code)?.oversightText || ""}})

      const newState = iassign(
        currentState,
        currentState => currentState?.managerEsgDiversityInclusionPolicy,
        process => ({
          ...process,
          diversityOversightTypes: transformedInput
        })
      )
      setState(newState)
    }
  }

  const handleInputChange = (value: any, property: string) => {
    let path = property.split(".")
    const newState = iassign(
      currentState,
      path,
      (previousValue) => {
        if((property === "managerEsgDiversityInclusionPolicy.freqEmpSignOrReview.code" || property === "managerEsgDiversityInclusionPolicy.freqUpdatesToCode.code") && previousValue === null && false){
          return {code: value, value: ""}
        } else {
          return value
        }
      }
    )
    setState(newState)
  }

  const handleTextboxOtherChange = (value: any, table:string, array:string,  property: string) => {
    const newState = iassign(
      currentState,
      [table, array],
      (rows) => {
        let newRows = cloneDeep(rows as any[])
        let currentRow = _.find(newRows, (row) => row.oversightType?.code === "OTH")
        if(currentRow){
          _.set(currentRow, property, value)
        }
        return newRows
      }
    )
    setState(newState)
  }

  const callanDEIScoreComponent =(callanDeiScoreProps: FormInputField[]) => {
    let {property} =  callanDeiScoreProps[0]
    let propertyVal: any = _.get(currentState, property)
    let {weightedScore, completeAnswer} = propertyVal
    let displayVal = (weightedScore ||0 )* 100
    let formattedValue
    let showScore = completeAnswer === 1

    if(showScore && weightedScore && weightedScore > 0){
      formattedValue = numbro(weightedScore as number).format("0.00")
    }
    else{
      formattedValue = "N/A"
    }

    let colorSetting = getColor(formattedValue)
    let showProgressBar = formattedValue !== "N/A"
    return(
      <div className="d-flex flex-row callanESGScore justify-content-between pb-2" key={`callanDeiScore`}>
      <div className={classnames("progressbar-container callanESGScore pr-3", colorSetting)}>
        <div className="progressbar-value-container mx-0 w-100">
          <div className="label w-100">
            <span>DEI</span>
          </div>
          <div className={"value w-100"}>
            <span>{formattedValue}</span>
          </div>
        </div>
        {showProgressBar ? (<Progress
          value={displayVal}
          max={3*100}
          style={{width:90}}
          className={"mb-3"}
        />) : (<div className="pb-1 no-progress"></div>)}
        <div className={"disclaimer w-100 pt-1 d-flex justify-content-center"}>
          {"Calculated by Callan"}
        </div>
      </div>
      {DEI_SCORE_DESCRIPTION}
    </div>
    )
  }

  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                {auth.checkPermissions(["edit:manager"]) &&
                  <EditButtons editMode={editMode} setEditMode={toggleEditMode} onSubmit={handleSubmit} saving={saving}/>
                }
              </div>
              <div className="pane">
                <Row>
                  <Col sm="7">
                    {callanDEIScoreComponent(ManagerDEIScoreInput)}
                    {DiversityInput.map(
                      (
                        {
                          property,
                          label,
                          type,
                          subtype,
                          placeholder,
                          optionSource,
                          subClasses,
                          displayData,
                          charactersLimit,
                          tooltip,
                        },
                        idx
                      ) => {
                        if (
                          (displayData && _.get(currentState, displayData.property) != displayData.value)
                        ) {
                          return
                        }
                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>{
                          if(type === "select" && value === ""){
                            handleInputChange(null, property)
                          } else {
                            handleInputChange(value, property)
                          }
                        }
                        if (type && type === "checkbox") {
                          if (subtype === "multiple" && optionSource) {
                            if (optionSource === "esgSignatoryBodyTypeOptions") {
                              return (
                                <CheckBoxes
                                  optionSource={options.esgSignatoryBodyTypeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "esgSignatoryBodyTypeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"esgSignatoryBodyTypeOptions"}
                                  label="If yes, please disclose which:"
                                />
                              )
                            } else if (optionSource === "DiversityOversightTypeCode") {
                              let otherEntry
                              if(!Array.isArray(propertyVal)){
                                propertyVal = []
                              } else {
                                otherEntry = _.find(propertyVal, (row) => row.oversightType === "OTH" || row.oversightType?.code === "OTH")
                                propertyVal = propertyVal.map((val) => val.oversightType)
                              }
                              return (
                                <React.Fragment key={idx}>
                                  <CheckBoxes
                                    optionSource={_.sortBy(options.diversityOversightTypeOptions, [i => i.code === "OTH" ? 1 : 0])}
                                    options={propertyVal || []}
                                    cb={(input: optionProps[]) =>
                                      handleCheckboxesChangeToState(
                                        input,
                                        "diversityOversightTypeCode"
                                      )
                                    }
                                    disabled={!editMode}
                                    key={"diversityOversightTypeCode"}
                                    label="Oversees diversity, equity, and inclusion efforts"
                                  />
                                  {!!otherEntry &&
                                    <FormInput
                                      property={property}
                                      displayName={"If other please specify"}
                                      type={"text"}
                                      idx={idx}
                                      editMode={editMode}
                                      propertyVal={otherEntry.oversightText}
                                      updateValue={(value) => handleTextboxOtherChange(value, "managerEsgDiversityInclusionPolicy", "diversityOversightTypes", "oversightText")}
                                      subClasses={{
                                        labelClasses: "col-sm-8",
                                        inputWrapperClasses: "col-sm-4"
                                      }}
                                      charactersLimit={60}
                                    />
                                  }
                                </React.Fragment>
                              )
                            }
                          }
                        }
                        const tooltipId = tooltip?.id? ManagerDiversityTooltipsIdsMapping[tooltip?.id as ManagerDiversityTooltipType]: ""
                        const tooltipText = tooltipId? tooltipsData?.find((tooltip:any) => tooltip?.id === tooltipId)?.text : ""
                        return (
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            type={type}
                            subtype={subtype}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            optionSource={optionSource}
                            subClasses={subClasses}
                            showZero={true}
                            charactersLimit={charactersLimit}
                            tooltip={tooltip? {...tooltip , text: tooltipText}: tooltip}
                          />
                        )
                      }
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

interface DiversityInputField extends FormInputField {
  displayData?: {
    property: string
    value: any
  }
  charactersLimit?: number
}

const ManagerDEIScoreInput: FormInputField[] = [
  {
    property: "callanDeiScores",
    label: "Callan DEI Score",
    type: "float",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "callanDEIScoreTooltip"
    }
  }
]

const DiversityInput: DiversityInputField[] = [
  {
    property: "",
    label: "Diversity & Inclusion",
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 mb-2",
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.diversityInclusionPolicy",
    label: "Formal Diversity, Equity, and Inclusion Policy",
    type: "radio",
    tooltip: {
      icon: "question-circle",
      id: "diversityInclusionPolicyTooltip",
    },
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgDiversityInclusionPolicy.diversityOversightTypes",
    label: "Oversees diversity, equity, and inclusion efforts",
    type: "checkbox",
    subtype: "multiple",
    optionSource: "DiversityOversightTypeCode"
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.payParityPolicy",
    label: "Formal pay-parity policy",
    type: "radio",
    tooltip: {
      icon: "question-circle",
      id: "diversityPayParityTooltip",
    },
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.recruitmentInitiatives",
    label: "Recruitment initiatives focused on women, people of color and/or other under-represented candidates",
    type: "radio",
    tooltip: {
      icon: "question-circle",
      id: "diversityRecruitmentInitiativeTooltip",
    },
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.recruitmentInitiativesDesc",
    label: "Please specify",
    type: "textarea",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-12"
    },
    displayData: {
      property: "managerEsgDiversityInclusionPolicy.recruitmentInitiatives",
      value: true
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.offersDandiTraining",
    label: "Offer training programs around diversity, equity & inclusion and/or unconscious bias",
    type: "radio",
    tooltip: {
      icon: "question-circle",
      id: "diversityOffersTrainingTooltip",
    },
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.frequencyOfTrainingOffered.code",
    label: "If yes, frequency of training offered",
    type: "select",
    subtype: "single",
    optionSource: "TrainingFrequencyCode",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.policiesToIncDiversity",
    label: "Policies intended to increase the level of gender, racial and ethnic diversity of senior leadership and investment teams",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.trackKpisOfPolicies",
    label: "If yes, track KPIs related to staff diversity initiatives",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.kpisAvailableExternal",
    label: "If yes, KPI reporting available to external parties",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.mentorshipProgram",
    label: "Mentorship program available for women, people of color and/or others that are under-represented in leadership",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.undertakenPayGapStudy",
    label: "Undertaken pay equity study",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.policyForDeficiencies",
    label: "If yes, policies in place to remedy any deficiencies found",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.cfaSignatory",
    label: "CFA Institute Diversity, Equity, and Inclusion Code Signatory",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.ethicsCodeOfConduct",
    label: "Ethics code and/or code of conduct",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.freqUpdatesToCode.code",
    label: "If yes, frequency of updates made",
    type: "select",
    subtype: "single",
    optionSource: "DiversityFrequencyCode",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.sexualHarassPolicy",
    label: "Explicit sexual harassment policy",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.freqEmpSignOrReview.code",
    label: "If yes, frequency employees required to review/sign it",
    type: "select",
    subtype: "single",
    optionSource: "DiversityFrequencyCode",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerEsgDiversityInclusionPolicy.suppliersMwdo",
    label: "Percent of DWDO companies currently doing business with",
    type: "float",
    subtype: "percent",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
]

enum ManagerDiversityTooltipType {
  diversityInclusionPolicyTooltip = 'diversityInclusionPolicyTooltip',
  diversityPayParityTooltip = 'diversityPayParityTooltip',
  diversityRecruitmentInitiativeTooltip = 'diversityRecruitmentInitiativeTooltip',
  diversityOffersTrainingTooltip = 'diversityOffersTrainingTooltip',
} 

const ManagerDiversityTooltipsIdsMapping = {
  [ManagerDiversityTooltipType.diversityInclusionPolicyTooltip]: 104,
  [ManagerDiversityTooltipType.diversityPayParityTooltip]: 105,
  [ManagerDiversityTooltipType.diversityRecruitmentInitiativeTooltip]: 106,
  [ManagerDiversityTooltipType.diversityOffersTrainingTooltip]: 107,
}

const ManagerOverviewDiversity: React.FC<Props> = ({ managerId, auth }) => {
  const tooltip_ids = useMemo(() => Object.values(ManagerDiversityTooltipsIdsMapping) || [], [])
  const { loading, error, data } = useManagerDiversityQuery({
    variables: { id: managerId, tooltip_ids }
  })
  const {
    loading: lookupLoading,
    error: lookupError,
    data: lookupData
  } = useGetManagerEsgLookupQuery()
  if (loading || lookupLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              {auth.checkPermissions(["edit:manager"]) &&
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              }
            </div>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (error || lookupError) {
    return (
      <div>
        <p>
          {(error && error.message) || (lookupError && lookupError.message)}
        </p>
      </div>
    )
  } else if (data && lookupData) {
    if (data.org && data.org.__typename === "Manager") {
      const { esgSignatoryBodyType, diversityOversightTypeCode } = lookupData
      const esgSignatoryBodyTypeOptions = esgSignatoryBodyType?.enumValues as optionProps[]
      const diversityOversightTypeOptions = diversityOversightTypeCode?.enumValues as optionProps[]

      return <Display options={{ esgSignatoryBodyTypeOptions, diversityOversightTypeOptions }} auth={auth} {...data.org} tooltipsData={data?.tooltips || []}/>
    } else {
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className="pane pane-toolbar">
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              </div>
              <div className="pane">
                <p>
                  Please check your managerId input{managerId} and try again.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
  } else {
    return <div>org not found</div>
  }
}

export default ManagerOverviewDiversity
