import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import iassign from "immutable-assign"
import _, { compact, filter } from "lodash"
import moment from 'moment'
import React, { FormEvent, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Col, Container, Form, FormGroup, Label, Row, Table, UncontrolledTooltip } from "reactstrap"

import { DocumentAccess, DocumentSubType, FileBasicInfoFragment, HedgeFundDistinctManagers, ManagerSummaryQuery, MaterialChange, Minority, OrgUpdateFields, Structure, UpdateFileMetadataInput, UpdateOrgInput, useDeleteOrgMaterialChangesMutation, useManagerSummaryQuery, useUpdateFileMetadataMutation, useUpdateManagerDetailsMutation } from "../../__generated__/graphql"
import Auth from "../../Auth/Auth"
import { DATE_API_FORMAT, FormInputField } from "../../helpers/constant"
import { ManagerType } from "../../helpers/helpers"
import { convertLookupToString, excludePropertyArray, reShapeObject } from "../../helpers/object"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from '../ui/EditButtons'
import { FormInput } from "../ui/Forms/FormInput"
import PlaceHolder from "./../ui/PlaceHolder"
import DocumentCompactList from "../Documents/DocumentCompactList"
import { MANAGER_SUMMARY_QUERY } from "../../queries/Org"
import exportTables from "../../helpers/exportTable"
import DOMPurify from "dompurify"

interface Props extends ManagerSummaryQuery {
  managerType?: ManagerType
  auth: Auth
}

// const s = printSchema
interface idProps {
  id: number
  managerType?: ManagerType
  auth: Auth
}

interface ManagerSummaryInput extends FormInputField {
  displayTypes?: string[]
  displayData?: {
    property: string
    value: any
  }
}

enum ManagerSummaryTooltipType {
  managerNameTooltip = 'managerNameTooltip',
  managerIdTTooltip = 'managerIdTTooltip',
  firmOwnershipTooltip = 'firmOwnershipTooltip',
  majorityWomanOwnedTooltip = 'majorityWomanOwnedTooltip',
  majorityDisableOwnedTooltip = 'majorityDisableOwnedTooltip',
  minorityOwnedTooltip = 'minorityOwnedTooltip',
  minorityWomanOwnedTooltip = 'minorityWomanOwnedTooltip',
  firmHistoryTooltip = 'firmHistoryTooltip',
  numberOfDistinctManagersTooltip = 'numberOfDistinctManagersTooltip',
} 

const ManagerSummaryTooltipsIdsMapping = {
  [ManagerSummaryTooltipType.managerNameTooltip]: 1,
  [ManagerSummaryTooltipType.managerIdTTooltip]: 2,
  [ManagerSummaryTooltipType.firmOwnershipTooltip]: 3,
  [ManagerSummaryTooltipType.majorityWomanOwnedTooltip]: 4,
  [ManagerSummaryTooltipType.majorityDisableOwnedTooltip]: 5,
  [ManagerSummaryTooltipType.minorityOwnedTooltip]: 6,
  [ManagerSummaryTooltipType.firmHistoryTooltip]: 7,
  [ManagerSummaryTooltipType.numberOfDistinctManagersTooltip]: 13,
  [ManagerSummaryTooltipType.minorityWomanOwnedTooltip]: 93,
}

/**
 * query ManagerSummary by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const QueryManagerSummary: React.FC<idProps> = ({ id, managerType, auth }: idProps) => {
  const tooltip_ids = useMemo(() => Object.values(ManagerSummaryTooltipsIdsMapping) || [], [])
  const { data, error, loading } = useManagerSummaryQuery({
    variables: { id, tooltip_ids},
  })
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              {auth.checkPermissions(["edit:manager"]) &&
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              }
            </div>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    console.log("error query managerSummary")
    return <>{error.message}</>
  }
  if (data && data.generalInfo && data.generalInfo.__typename === "Manager") {
    return <Result {...data} managerType={managerType} auth={auth} />
  }
  return <div>data doesn't exist</div>
}

const ManagerSummaryInputList: ManagerSummaryInput[] = [
  {
    property: "generalInfo.name",
    label: "Manager Name",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "managerNameTooltip"
    }
  },
  {
    property: "id",
    label: "Manager ID",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "managerIdTTooltip"
    }
  },
  {
    property: "generalInfo.isInactive",
    label: "Status",
    type: "text",
    readonly: true
  },
  { property: "", label: "", type: "" },
  { property: "generalInfo.address.street[0]", label: "Street1", type: "text", readonly: true },
  { property: "generalInfo.address.street[1]", label: "Street2", type: "text", readonly: true },
  { property: "generalInfo.address.city", label: "City", type: "text", readonly: true },
  { property: "generalInfo.address.state", label: "State", type: "select", subtype: "single", optionSource: "StateCode", readonly: true },
  { property: "generalInfo.address.zip", label: "Zip Code", type: "text", readonly: true },
  { property: "generalInfo.address.country", label: "Country", type: "select", subtype: "single", optionSource: "AddressCountryCode", readonly: true },
  { property: "", label: "", type: "" },
  {
    property: "structure.yearFounded",
    label: "Year Firm Founded",
    type: "number",
    subtype: "year",
  },
  {
    property: "structure.parentCompany",
    label: "Parent Company",
    type: "text",
  },
  {
    property: "structure.yearParentFounded",
    label: "Year Parent Founded",
    type: "number",
    subtype: "year",
  },
  { property: "generalInfo.website", label: "Firm Website", type: "text", subtype: "url"},
  {
    property: "structure.ownershipType.code",
    label: "Firm Ownership",
    type: "select",
    subtype: "single",
    optionSource: "OwnershipType",
    tooltip: {
      icon: "question-circle",
      id: "firmOwnershipTooltip"
    }
  },
  { property: "", label: "", type: "" },
  {
    property: "structure.womanOwned",
    label: "Majority Women Owned",
    type: "checkbox",
    subtype: "boolean",
    tooltip: {
      icon: "question-circle",
      id: "majorityWomanOwnedTooltip"
    }
  },
  {
    property: "structure.disabledOwned",
    label: "Majority Disabled Owned",
    type: "checkbox",
    subtype: "boolean",
    tooltip: {
      icon: "question-circle",
      id: "majorityDisableOwnedTooltip"
    }
  },
  {
    property: "structure.minorityOwned",
    label: "Majority Minority Owned",
    type: "checkbox",
    subtype: "boolean",
    tooltip: {
      icon: "question-circle",
      id: "minorityOwnedTooltip"
    }
  },
  {
    property: "structure.minorityType.code",
    label: "Race and/or Ethnicity",
    type: "select",
    subtype: "single",
    optionSource: "MinorityType",
    subClasses: {
      labelClasses: "offset-sm-1 col-sm-4",
      inputWrapperClasses: "col-sm-7"
    },
    displayData:{
      property: "structure.minorityOwned",
      value: true
    },
  },
  {
    property: "structure.minorityWomanOwned",
    label: "Majority Minority & Women Owned",
    type: "checkbox",
    subtype: "boolean",
    tooltip: {
      icon: "question-circle",
      id: "minorityWomanOwnedTooltip"
    }
  },
  { property: "", label: "", type: "", displayTypes: ["HFFOF"] },
  {
    property: "hedgeFundOfFunds.primeBroker",
    label: "Name of prime broker",
    type: "text",
    displayTypes: ["HFFOF"]
  },
  {
    property: "hedgeFundOfFunds.hedgeAffiliation",
    label: "Name of hedge affiliation",
    type: "text",
    displayTypes: ["HFFOF"]
  },
  // { // Moved to custom build at end as it does not suit this style of field
  //   property: "hedgeFundOfFunds.districtManagerCount",
  //   label:
  //     "Total Number of Distinct Managers Currently Hired within Discretionary Mandates",
  //   type: "text",
  //   displayTypes: ["HFFOF"]
  // }
]

const ManagerSummaryTextBlocks: FormInputField[] = [
  {
    property: "structure.history.managerDescription",
    label: "Firm History",
    type: "textarea",
    required: true,
    tooltip: {
      icon: "question-circle",
      id: "firmHistoryTooltip"
    }
  },
  {
    property: "structure.jointVentureAffiliations",
    label: "Joint Venture & Affiliations",
    type: "textarea",
    required: true

  }
]

const PreShapeActions = {
  // this action has side effect, it excludes structure.id
  "structure.structure": {
    destinationPath: "structure",
    action: (a: Structure) => a
  },
  // reshape for structure diff between manager structure and org structure
  "structure.structure.minorityOwnerType": {
    destinationPath: "structure.minorityType",
    action: (a: Minority) => a
  },
  "structure.documents": {
    destinationPath: "documents",
    action: (a: FileBasicInfoFragment) => a
  },
  "generalInfo.legal_firm_type": {
    destinationPath: "generalInfo.type",
    action: (v: any) => v
  },
  "generalInfo.address.street": {
    destinationPath: "generalInfo.address.street",
    action: (address: string) => {
      const splitAddress = (address || "").split(/\r?\n/)
      return [splitAddress[0], splitAddress.slice(1).join("")]
    }
  },
  "generalInfo.id": {
    destinationPath: "id",
    action: (v: any) => v
  },
  "structure.hedgeFundOfFunds.numberOfDistinctManagers": {
    destinationPath: "hedgeFundOfFunds.numberOfDistinctManagers",
    action: (managerCounts: HedgeFundDistinctManagers[]) => managerCounts.reverse()
  },
  "structure.hedgeFundOfFunds.primeBroker": {
    destinationPath: "hedgeFundOfFunds.primeBroker",
    action: (v: string) => v
  },
  "structure.hedgeFundOfFunds.hedgeAffiliation": {
    destinationPath: "hedgeFundOfFunds.hedgeAffiliation",
    action: (v: string) => v
  },
  "structure.materialChanges": {
    destinationPath: "materialChanges",
    action: (materialChanges: MaterialChange[]) => {
      return materialChanges.map((change, idx) => {
        return { key: idx, ...change }
      })
    }
  },
}

const Result: React.FC<Props> = propsData => {
  // propData here is the output data, different from inputData needed for updateOrg.
  const { managerType, auth, ...data } = propsData
  const formattedData = reShapeObject(_.cloneDeep(data), PreShapeActions)
  const history = useHistory()
  const [currentState, setState] = useState(
    formattedData as { [name: string]: any }
  )
  const [initialState, setInitial] = useState(
    formattedData as { [name: string]: any }
  )
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [updateManager] = useUpdateManagerDetailsMutation()
  const [deleteMaterial] = useDeleteOrgMaterialChangesMutation()
  // const [updateFileMutation] = useUpdateFileMetadataMutation()

  const handleSubmit = () => {//(event:any, errors:any, values:any) => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    if(hasError){
      return
    }
    setSaving(true)
    const { id, tooltips, ...rest } = currentState
    const patch = { ...rest }
    const previousMaterialDates = initialState.materialChanges.map((mc:any) => mc.date)
    const currentMaterialDates = currentState.materialChanges.map((mc:any) => mc.date)
    const customLookupTypenameList = ["Minority", "Ownership"]
    const formattedPatch = convertLookupToString(
      patch,
      false,
      customLookupTypenameList
    )

    // if (errors.length > 0) {
    //   console.info("Can't submit, errors in form found. ", { errors })
    //   return false
    // }
    // TODO: owner mutation needs additional query for person/firm lists
    _.unset(formattedPatch, "generalInfo.owner")
    // _.unset(formattedPatch, "generalInfo.ManagerType")
    _.set(
      formattedPatch,
      "structure.history",
      _.get(formattedPatch, "structure.history.managerDescription")
    )
    //TODO add back after fix HedgeFundOfFundsInput data format
    _.unset(formattedPatch, "hedgeFundOfFunds.districtManagerCount")
    // Rejoin address street to one field
    _.set(
      formattedPatch,
      "generalInfo.address.street",
      _.get(formattedPatch, "generalInfo.address.street").join("\n")
    )
    // Remove react-key values from materialChanges
    _.set(
      formattedPatch,
      "materialChanges",
      _.get(formattedPatch, "materialChanges").map((change: any) => {
        _.unset(change, "key")
        return change
      })
    )

    const removedMaterial = previousMaterialDates.filter((x:string) => !currentMaterialDates.includes(x));
    const updateData = {
      patch: excludePropertyArray(formattedPatch, [
        "__typename",
        "managerTypes",
        "minorityOwnerType",
        "name",
        "shortName",
        "address",
        "documents",
        "isInactive"
      ]) as OrgUpdateFields
    }
    const updateManagerFunc = () => {
      // updateDocumentsFunc()
      const input = { id, ...updateData } as UpdateOrgInput
      updateManager({ variables: { input } })
        .then(result => {
          if (result && result.data && result.data.updateOrg) {
            const formattedData = reShapeObject(_.cloneDeep(result.data.updateOrg), PreShapeActions)
            setState({...formattedData, tooltips})
            setInitial(formattedData)
            setEditMode(false)
            setSaving(false)
          }
        })
        .catch(err => {
          console.log("Error testManagerSummary", err.message)
          // throw new Error(`${err.message}`)
        })
    }

    if(removedMaterial.length > 0){
      deleteMaterial({ variables: {input: { id: id, date: removedMaterial}}})
        .then(result => {
          updateManagerFunc()
        })
        .catch(err => {
          console.log("Error testManagerSummary", err.message)
          // throw new Error(`${err.message}`)
        })

    } else {
      updateManagerFunc()
    }

  }

  // const allowedSubtypes = [
  //   DocumentSubType._1, // ODD Related
  //   DocumentSubType._3, // Firm Updates
  //   DocumentSubType._2, // ADV
  //   DocumentSubType._14, // Sustainability Report
  // ]

  // const allowedTypes = [
  //   DocumentType._1, // Research
  //   DocumentType._3, // ESG Research
  // ]

  // const filteredDocuments = compact(filter(currentState.documents, (doc) => {return (!doc?.type?.code || allowedTypes.includes(doc?.type?.code) && (!doc?.subType?.code || allowedSubtypes.includes(doc?.subType?.code)))})) as FileBasicInfoFragment[]

  const handleEdit = () => {
    setState(initialState)
    setEditMode(!editMode)
  }

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const handleInputChange = (value: any, property: string) => {
    let properties = property.split('.')
    let path = (properties.shift() || "").split(".")
    let oldState = _.cloneDeep(currentState)
    if(!_.get(oldState, path)){
      _.set(oldState, path, {} as any)
    }
    let newState = iassign(
      oldState,
      path,
      (mainProp) => {
        let clonedProp:any = _.cloneDeep(mainProp || {})
        _.set(clonedProp, properties.join('.'), value)
        return clonedProp
      }
    )
    setState(newState)
  }

  const addMaterialChangesRow = () => {
    let oldState = _.cloneDeep(currentState)
    if(!oldState?.materialChanges){
      _.set(oldState, "materialChanges", [] as any)
    }
    let newState = iassign(
      oldState,
      ["materialChanges"],
      (rows) => {
        let rowsClone = _.cloneDeep(rows as any[])
        const newRow = {
          __typename: "MaterialChange",
          date: moment().format(DATE_API_FORMAT),
          body: "",
          key: rowsClone.length
        }
        rowsClone.unshift(newRow)
        return rowsClone
      }
    )
    setState(newState)
  }

  const removeMaterialChangesRow = (idx:number) => {
    let oldState = _.cloneDeep(currentState)
    let newState = iassign(
      oldState,
      currentState => currentState?.materialChanges,
      (rows) => {
        let rowsClone = _.cloneDeep(rows as any[])
        rowsClone?.splice(idx, 1)
        return rowsClone
      }
    )
    setState(newState)
  }

  const addDistinctManagersRow = () => {
    let oldState = _.cloneDeep(currentState)
    let path = ["hedgeFundOfFunds", "numberOfDistinctManagers"]
    if(!_.get(oldState, path) ){
      _.set(oldState, path, [] as any)
    }
    let newState = iassign(
      oldState,
      path,
      (rows) => {
        let rowsClone = _.cloneDeep(rows as HedgeFundDistinctManagers[])
        const newRow: HedgeFundDistinctManagers = {
          __typename: "HedgeFundDistinctManagers",
          year: moment().year(),
          count: 0
        }
        rowsClone.push(newRow)
        return rowsClone
      }
    )
    setState(newState)
  }

  const removeDistinctManagersRow = (idx: number) => {
    let oldState = _.cloneDeep(currentState)
    let newState = iassign(
      oldState,
      currentState => currentState?.hedgeFundOfFunds?.numberOfDistinctManagers,
      (rows) => {
        let rowsClone = _.cloneDeep(rows as any[])
        rowsClone?.splice(idx, 1)
        return rowsClone
      }
    )
    setState(newState)
  }

  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                <Button color="secondary btn-thin" className="ml-1 text-callan-blue" onClick={()=> exportTables()}>
                  Export CSV
                  <img src='/assets/CSV.svg' className="ml-2"/>
                </Button>
              {auth.checkPermissions(["edit:manager"]) &&
                <EditButtons editMode={editMode} setEditMode={handleEdit} onSubmit={handleSubmit} saving={saving}/>
              }
              </div>
              <div className="pane mb-4">
                <div className="row form-section-title headline underline small-font-size pb-2 mb-2 text-left">Manager Details</div>
                <Row>
                  <Col sm="5" className="px-4 exportable-form" data-export-name= "ManagerDetails">
                    {ManagerSummaryInputList.map(
                      (
                        {
                          property,
                          label,
                          type,
                          subtype,
                          placeholder,
                          optionSource,
                          readonly,
                          displayTypes,
                          tooltip,
                          subClasses,
                          displayData,
                          required,
                        },
                        idx
                      ) => {
                        if (
                          (displayTypes && !_.find(displayTypes, (type: string) => _.get(managerType, type))) ||
                          (displayData && _.get(currentState, displayData.property) !== displayData.value)
                        ) {
                          return (<React.Fragment key={idx}> </React.Fragment>)
                        }
                        let propertyVal: any = _.get(currentState, property)
                        if(property === "generalInfo.isInactive") {
                          propertyVal = propertyVal? "Inactive" : "Active"
                        }
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        const tooltipId = tooltip?.id? ManagerSummaryTooltipsIdsMapping[tooltip?.id as ManagerSummaryTooltipType]: ""
                        const tooltipText = tooltipId? data?.tooltips?.find((tooltip:any) => tooltip?.id === tooltipId)?.text : ""
                        return (
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            type={type}
                            subtype={subtype}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            optionSource={optionSource}
                            readonly={readonly}
                            tooltip={tooltip? {...tooltip , text: tooltipText}: tooltip}
                            subClasses={subClasses}
                            required={required}
                          />
                        )
                      }
                    )}
                    { managerType?.HFFOF &&
                      <FormGroup className="form-group row">
                        <Label
                          className="col-form-label col-sm-4 align-items-start"
                          id="numberOfDistinctManagersTooltipLabel"
                        >
                          <div className="d-flex w-100 justify-content-between tooltip-icon" id="numberOfDistinctManagersTooltip">
                            Total Number of Distinct Managers Currently Hired within Discretionary Mandates
                            <FontAwesomeIcon
                              icon={"question-circle" as IconName}
                              size="sm"
                              id={"generic-tooltip-numberOfDistinctManagersTooltip"}
                            />
                            <UncontrolledTooltip
                              className="generic-tooltip"
                              target={"generic-tooltip-numberOfDistinctManagersTooltip"}
                              dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                                data?.tooltips?.find((tooltip:any) => tooltip?.id === ManagerSummaryTooltipsIdsMapping["numberOfDistinctManagersTooltip" as ManagerSummaryTooltipType] || "")?.text || ""
                                )}}
                              delay={{hide: 1000, show: 0}}
                            />
                          </div>

                        </Label>
                        <Col sm="8">
                          {editMode &&
                            <Col>
                              <Button color="link" className="mt-3 ml-auto" onClick={() => addDistinctManagersRow()}>
                                Add Row
                                <FontAwesomeIcon
                                  icon="plus-circle"
                                  className="ml-2 text-blue-100"
                                />
                              </Button>
                            </Col>
                          }
                          {currentState.hedgeFundOfFunds.numberOfDistinctManagers.map (({ year, count }:any, idx:number) => {
                            const reference = idx
                            const key = `distinct-manager-${year}-${idx}`
                            let onChangeCallback = (value: any, property:string) =>
                              handleInputChange(value, "hedgeFundOfFunds.numberOfDistinctManagers."+reference+ "." + property)
                            return(
                              <Row key={key}>
                                <Col sm="5">
                                  <FormInput
                                    key={"hedgeFundOfFunds.numberOfDistinctManagers.count"}
                                    property={"hedgeFundOfFunds.numberOfDistinctManagers.count"}
                                    displayName={""}
                                    type={"number"}
                                    idx={key}
                                    editMode={editMode}
                                    propertyVal={count}
                                    updateValue={(value:any) => {onChangeCallback(value, "count")}}
                                  />
                                </Col>
                                <Col className="text-center mt-2" sm="2">
                                  as of
                                </Col>
                                <Col sm="3">
                                  <FormInput
                                    key={"hedgeFundOfFunds.numberOfDistinctManagers.year"}
                                    property={"hedgeFundOfFunds.numberOfDistinctManagers.year"}
                                    displayName={""}
                                    type={"number"}
                                    subtype={"year"}
                                    idx={key}
                                    editMode={editMode}
                                    propertyVal={year}
                                    updateValue={(value:any) => {onChangeCallback(value, "year")}}
                                  />
                                </Col>
                                {editMode &&
                                  <Col sm="1" className="actions">
                                  <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeDistinctManagersRow(reference)}>
                                    <FontAwesomeIcon
                                      icon="trash"
                                    />
                                  </Button>
                                  </Col>
                                }
                              </Row>
                            )
                          })}
                        </Col>
                      </FormGroup>
                    }
                  </Col>
                  <Col sm="7" className="px-4 exportable-form" data-export-name="FirmInformation">
                    {ManagerSummaryTextBlocks.map(
                      (
                        {
                          property,
                          label,
                          type,
                          subtype,
                          placeholder,
                          optionSource,
                          required,
                          tooltip
                        },
                        idx
                      ) => {
                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        const tooltipId = tooltip?.id? ManagerSummaryTooltipsIdsMapping[tooltip?.id as ManagerSummaryTooltipType]: ""
                        const tooltipText = tooltipId? data?.tooltips?.find((tooltip:any) => tooltip?.id === tooltipId)?.text : ""
                        return (
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            type={type}
                            subtype={subtype}
                            optionSource={optionSource}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            rows={10}
                            tooltip={tooltip? {...tooltip , text: tooltipText}: tooltip}
                            required={required}
                          />
                        )
                      }
                    )}
                    {managerType?.HFFOF &&
                      <MaterialChanges
                        materialChanges={currentState.materialChanges}
                        editMode={editMode}
                        updateValue={handleInputChange}
                        addMaterialChangesRow={addMaterialChangesRow}
                        removeMaterialChangesRow={removeMaterialChangesRow}
                      />
                    }
                    {/* (auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["edit:documents"])) &&
                      <DocumentCompactList
                        associationId={currentState.id}
                        associationType="Manager"
                        documents={filteredDocuments}
                        auth={auth}
                        removeDocument={removeDocument}
                        editMode={editMode}
                        updateValue={handleDocumentChange}
                        forceValues={{access: DocumentAccess._1}}
                        allowedSubtypes={allowedSubtypes}
                        addDocument={addDocument}
                      />
                    */}
                  </Col>
                </Row>
                {!managerType?.HFFOF &&
                  <Row>
                    <Col>
                      <MaterialChanges
                        materialChanges={currentState.materialChanges}
                        editMode={editMode}
                        updateValue={handleInputChange}
                        addMaterialChangesRow={addMaterialChangesRow}
                        removeMaterialChangesRow={removeMaterialChangesRow}
                      />
                    </Col>
                  </Row>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

interface MaterialChangesProps {
  materialChanges: any
  editMode: boolean
  updateValue: (value:any, property:string) => void
  addMaterialChangesRow: () => void
  removeMaterialChangesRow: (idx:number) => void
}

const MaterialChanges = ({materialChanges, editMode, updateValue, addMaterialChangesRow, removeMaterialChangesRow}: MaterialChangesProps) => {
  return (
    <Row>
      <Col className="mt-4" sm="12">
        <div className="form-section-title headline underline small-font-size py-2 mb-2 text-left">Firm Changes</div>
        <p>Discuss any material changes to the firm, including personnel changes.</p>
      </Col>
      <Col sm="12">
        <Table className="exportable" data-export-name="MaterialChanges">
          <thead>
            <tr>
              <th>Date of Change</th>
              <th>Description of Change</th>
            </tr>
          </thead>
          <tbody>
            {editMode &&
              <tr>
                <td colSpan={3} className="text-left">
                  <Button color="link" className="ml-auto" onClick={() => addMaterialChangesRow()}>
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="mr-2 text-blue-100"
                    />
                    Add Row
                  </Button>
                </td>
              </tr>
            }
            {materialChanges.map(({date, body, key}:any, idx:number) => {
              const reference = idx
              let onChangeCallback = (value: any, property:string) =>
                updateValue(value, "materialChanges."+reference+"."+property)
              return(
                <tr key={key} className={ editMode ? "full-width hover-actions editing" : "full-width hover-actions" }>
                  <td className="w-25 align-top">
                    <FormInput
                      key={"materialChanges.date"}
                      property={"materialChanges.date"}
                      displayName={""}
                      type={"date"}
                      idx={2*reference +1}
                      editMode={editMode}
                      propertyVal={date}
                      updateValue={(value:any) => {onChangeCallback(value, "date")}}
                      placeholder={"dd/mm/yyyy"}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={"materialChanges.body"}
                      property={"materialChanges.body"}
                      displayName={""}
                      type={"textarea"}
                      idx={2*reference +1}
                      editMode={editMode}
                      propertyVal={body}
                      updateValue={(value:any) => {onChangeCallback(value, "body")}}
                      placeholder={"Description of material changes to the manager"}
                    />
                  </td>
                  {editMode &&
                    <td className="actions">
                      <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeMaterialChangesRow(reference)}>
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  }
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default QueryManagerSummary
//TODO modularize
//TODO add form validations
