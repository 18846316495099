import React from 'react'
import _ from 'lodash'
import { Table, UncontrolledTooltip } from 'reactstrap'

import { FormInput } from '../ui/Forms/FormInput'
import { ManagerOwnershipDiverseTablesDef } from '../Org/ManagerOverviewOwnership'
import { TooltipFragment } from '../../__generated__/graphql'
import { InputTooltip } from './Forms/TextFieldInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import classnames from 'classnames'
import { parseDateTime } from '../../helpers/helpers'
import DOMPurify from 'dompurify'

export type ColumnDefSource = {[propertyName: string]: { columns: { headerName: string; field: string, className: string, type?: string, subtype?: string, optionSource?: string, required?: boolean, headerTooltip?: { icon: string, id: string} | undefined}[], calculatedTotal?: {columnNumber: number, className?: string} | false | undefined}}
interface Props {
  data: any[]
  tableName: string
  editMode: boolean
  exportTableName: string
  // add source props so EmployeeTable can use more columnDef
  source?: ColumnDefSource
  updateValue: (value:any, property:string, type:any, assetClass?:number) => void
  tooltipsData?: TooltipFragment[]
  tooltipMapping?: {[key: string]: number}
}
export interface GetColumnDefProps {
  source: ColumnDefSource
  title: string
}

const getColumnDef = ({ source, title }: GetColumnDefProps) => {
  if (source.hasOwnProperty(title)) {
    const tableDef = source[title]
    if (tableDef.hasOwnProperty("columns")) {
      return tableDef.columns
    }
  }
  return []
}

interface EmployeeRowProps {
  data: any
  columnDef: any[]
  highlightRow: boolean
  row: number
  editMode: boolean
  updateValue: (value: any, property: string) => void
  tableName?: string
  ownershipRow?: boolean
  tooltipsData?: TooltipFragment[]
  tooltipMapping?: Record<string, number>
}

const EmployeeRow = ({data, columnDef, highlightRow, row, editMode, updateValue, tableName, ownershipRow, tooltipsData, tooltipMapping}: EmployeeRowProps) => {
  let className: string = ""
  if(!ownershipRow && !editMode && _.isNil(data?.count) && _.isNil(data?.gained) && _.isNil(data?.lost)){
    return(
      <></>
    )
  }else if(ownershipRow && !editMode && _.isNil(data?.percent)) {
    return(
      <></>
    )
  }
  return (
    <tr className={className}>
      {columnDef.map((header:any) => {
        const numberField =  ["gained", "lost", "count"].includes(header.field)
        // add percentage use case
        const isPercentField = header.field === "percent" || header.subType === "percent"
        const isFloatType = header?.type === "float"
        if(numberField || isFloatType || isPercentField){
          return (
            <td key={header.field}>
              <FormInput
                key={header.field}
                showZero={true}
                property={`${header.field}-${tableName}`}
                displayName={""}
                type={isFloatType || isPercentField? "float": "number"}
                subtype={isPercentField? "percent" : ""}
                idx={row}
                editMode={editMode}
                propertyVal={_.get(data, header.field)}
                updateValue={(value:any)=>updateValue(value, header.field)}
                required={header?.required === true}
                // optionSource={optionSource}
                />
            </td>
          )
        } else {
          let tooltip: InputTooltip | undefined = undefined
          if(tableName === "minorityBreakDown") {
            if(_.get(data, "type.code") === "MINFM") {
              const tooltipId = "otherMinorityFemTooltip"
              const tooltipText = tooltipId? (tooltipsData || [])?.find((tooltip:any) => tooltip?.id === (tooltipMapping || {})[tooltipId])?.text : ""
              tooltip = {icon: "question-circle", id: tooltipId, text: tooltipText} as InputTooltip
            }
          }
          return (
            <td key={header.field}>
              <FormInput
                key={header.field}
                showZero={true}
                property={`${header.field}-${tableName}`}
                displayName={""}
                type={"text"}
                idx={row}
                editMode={editMode}
                propertyVal={_.get(data, header.field)}
                updateValue={(value:any)=>updateValue(value, header.field)}
                // optionSource={header.optionSource}
                readonly={true}
                tooltip={tooltip}
                displayTooltipAtValue={!!tooltip}
              />
            </td>
          )
        }
      })}
    </tr>
  )
}

export const EmployeeTable: React.FC<Props> = props => {
  const columnDef = getColumnDef({
    source: props.source || ManagerEmployeeCountTablesDef,
    title: props.tableName
  })

  const calculatedTotal = props.source?.[props.tableName].calculatedTotal
  const getCalculatedRow = () => {
    if(!_.isEmpty(calculatedTotal) && calculatedTotal){
      let {columnNumber} = calculatedTotal
      let calculatedColumn = columnDef[columnNumber]
      let {headerName, field, type, subtype} = calculatedColumn
      let className = calculatedTotal.className || calculatedColumn.className
      
      let isPercentField = field === "percent" || subtype === "percent"
      let total = props.data.reduce((acc, row)=>{
        let newValue = _.get(row, field) || 0
        acc += newValue
        return acc
      }, 0)
      return (
        <tr className={className} key={"calculated-total"}>
          <td key={"calculated-total-left"} className="text-left">
            Calculated Total
          </td>
          <td key={"calculated-total-right"}>
            <FormInput
              key={field}
              showZero={true}
              property={`${headerName}-${field}}`}
              displayName={""}
              type={type|| "number"}
              subtype={isPercentField? "percent" : ""}
              idx={`total-${props.tableName}-${headerName}-${field}`}
              editMode={false}
              propertyVal={total}
              updateValue={()=>{return}}
              readonly={true}
              // optionSource={optionSource}
              />
          </td>
        </tr>
      )
    }else{
      return <></>
    }
  }

  let previousRow = {};
  let highlightRow = false;

  const tooltipsData = props.tooltipsData || []
  const tooltipMapping = props.tooltipMapping || {}
  return (
    <div>
      {/* commentedTable */}
      <Table className={'employee-table exportable'} key={`Employee Turnover`} data-export-name={props.exportTableName || props.tableName} hover>
        <thead>
          <tr>
            {columnDef.map((header, index) => {
              let tooltip = header.headerTooltip
              const tooltipId = tooltip?.id || ""
              const tooltipText = tooltipId? tooltipsData?.find((tooltip:any) => tooltip?.id === tooltipMapping[tooltipId])?.text : ""
              return (
                <th
                  key={index}
                  className={classnames(header.className)}
                >
                  <div className="d-inline-flex align-items-center tooltip-icon" id={tooltipId}>
                  {header.headerName}
                  {tooltip && (
                    <>
                      <FontAwesomeIcon
                        icon={tooltip?.icon as IconName}
                        size="sm"  
                        id={`generic-tooltip-${tooltipId}`}
                      />
                      {tooltipText && <UncontrolledTooltip
                        className="generic-tooltip"
                        target={`generic-tooltip-${tooltipId}`}
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tooltipText || "")}}
                        delay={{hide: 1000, show: 0}}
                      />}
                    </>
                  )}
                </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody key={0}>
          {props.data.map((row, idx) => {
            if (props.source === ManagerOwnershipDiverseTablesDef) {
              return (
                <EmployeeRow
                  data={row}
                  columnDef={columnDef}
                  highlightRow={false}
                  key={idx}
                  row={idx}
                  editMode={props.editMode}
                  updateValue={(value, property) => props.updateValue(value, property, row.type)}
                  tableName={props.tableName}
                  ownershipRow={true}
                  tooltipsData={tooltipsData}
                  tooltipMapping={tooltipMapping}
                />
              )
            }
            if (['genderBreakDown','diversity','jobCategory','productDiversity','productGenderBreakDown','productTurnover', 'minorityBreakDown'].indexOf(props.tableName) > -1) {
              return (
                <EmployeeRow
                  data={row}
                  columnDef={columnDef}
                  highlightRow={false}
                  key={idx}
                  row={idx}
                  editMode={props.editMode}
                  updateValue={(value, property) => props.updateValue(value, property, row.type)}
                  tableName={props.tableName}
                  tooltipsData={tooltipsData}
                  tooltipMapping={tooltipMapping}
                />
              )
            } else if (props.tableName === 'turnover'){
              highlightRow = _.get(previousRow, columnDef[0].field) !== _.get(row, columnDef[0].field)

              previousRow = row
              return (
                <EmployeeRow
                  data={row}
                  columnDef={columnDef}
                  highlightRow={highlightRow}
                  key={idx}
                  row={idx}
                  editMode={props.editMode}
                  updateValue={(value, property) => props.updateValue(value,property,row.type,row.assetClass.id)}
                  tableName={props.tableName}
                  tooltipsData={tooltipsData}
                  tooltipMapping={tooltipMapping}
                />
              )
            } else {
              console.log(props.tableName)
              return <></>
            }
          })
          }
          {getCalculatedRow()}
        </tbody>
      </Table>
    </div>
  )
}

const ManagerEmployeeCountTablesDef = {
  genderBreakDown: {
    columns: [
      {
        headerName: "Gender Breakdown",
        className: "text-left",
        type: "Lookup",
        field: "type.value",
        optionSource: "EmployeeGenderType",
        headerTooltip: {
          icon: "question-circle",
          id: "genderBreakdownTooltip",
        },
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        type: "number",
        field: "count"
      }
    ]
  },
  diversity: {
    columns: [
      {
        headerName: "Diversity Profile",
        className: "text-left",
        field: "type.value",
        optionSource: "EmployeeRaceType",
        headerTooltip: {
          icon: "question-circle",
          id: "diversityProfileTooltip",
        },
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        field: "count"
      }
    ]
  },
  jobCategory: {
    columns: [
      {
        headerName: "Job Category",
        className: "text-left",
        field: "type.value",
        optionSource: "EmployeeJobType"
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        field: "count"
      }
    ]
  },
  turnover: {
    columns: [
      {
        headerName: 'Asset Class',
        className: "text-left width-25-percent",
        field: 'assetClass.fullName'
      },
      {
        headerName: 'Job Category',
        className: "text-left width-25-percent",
        field: 'type.value',
        optionSource: "EmployeeJobType"
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        field: "count"
      },
      {
        headerName: "# Gained",
        className: "text-right",
        field: "gained"
      },
      {
        headerName: "# Lost",
        className: "text-right",
        field: "lost"
      }
    ]
  },
  productGenderBreakDown: {
    columns: [
      {
        headerName: "Gender Breakdown",
        className: "text-left",
        type: "Lookup",
        field: "type.value",
        optionSource: "EmployeeGenderType",
        headerTooltip: {
          icon: "question-circle",
          id: "genderBreakdownTooltip",
        },
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        type: "number",
        field: "count"
      }
    ]
  },
  productDiversity: {
    columns: [
      {
        headerName: "Diversity Profile",
        className: "text-left",
        field: "type.value",
        optionSource: "EmployeeJobType",
        headerTooltip: {
          icon: "question-circle",
          id: "diversityProfileTooltip",
        },
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        field: "count"
      }
    ]
  },
  productTurnover: {
    columns: [
      {
        headerName: 'Job Category',
        className: "text-left",
        field: 'type.value',
        optionSource: "EmployeeJobType"
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        field: "count"
      },
      {
        headerName: "# Gained",
        className: "text-right",
        field: "gained"
      },
      {
        headerName: "# Lost",
        className: "text-right",
        field: "lost"
      }
    ]
  },
  minorityBreakDown: {
    columns: [
      {
        headerName: "Other Minority",
        className: "text-left",
        field: "type.value",
        optionSource: "EmployeeOtherMinorityType",
        headerTooltip: {
          icon: "question-circle",
          id: "otherMinorityTooltip",
        },
      },
      {
        headerName: "# of Employees",
        className: "text-right",
        field: "count"
      },
    ]
  }
}
