import React, { Component, useState, useContext, useRef, FormEvent, RefObject, useEffect, useMemo } from 'react'
import _, { compact } from 'lodash'
import { CalendarContext } from "../../Context/CalendarContext"
import iassign from 'immutable-assign'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import { Manager, EmployeeBreakdownQuery, useEmployeeBreakdownQuery, useUpdateManagerEmployeesMutation, EmployeeTurnoverInput, useGetLookupQuery, GetLookupQuery, useUpdateManagerEmployeeBreakdownDetailMutation, EmployeeBreakdownDetail, Maybe, TooltipsQuery, TooltipFragment } from '../../__generated__/graphql'
import { convertLookupToString, excludePropertyArray, } from '../../helpers/object'
import { EmployeeTable } from '../ui/EmployeeTable'
import { Container, Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from '../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import moment from 'moment'
import exportTables from '../../helpers/exportTable'
import DOMPurify from 'dompurify'

interface Props {
  setSearchDate: (searchDate:string) => void
  data: EmployeeBreakdownQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<Result>
  searchDate: string
  jobData: GetLookupQuery
  raceData: GetLookupQuery
  genderData: GetLookupQuery
  typeData: GetLookupQuery
  minorityData: GetLookupQuery
  history: History
  setReasonErrorMessage: (message: string) => void
  tooltipMapping: Record<string, number>
}

interface idProps {
  id: number
  auth: Auth
}

interface ManagerCountFootnoteInputField extends FormInputField {
  displayData?: {
    property: string
    value: any
  },
  charactersLimit?: number
}

export const EmployeeBreakdownDetailUpperInputs: ManagerCountFootnoteInputField[] = [
  {
    property: "canProvideDiversityGender",
    label: "Can provide diversity and gender employee counts",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-6 pl-2 my-2",
      inputWrapperClasses: "col-sm-6 pl-0 my-2 left-aligned-boolean"
    },
  },
  {
    property: "reason.code",
    label: "If no, select reason",
    type: "select",
    subtype: "single",
    required: true,
    optionSource: "EmployeeReasonCode",
    subClasses: {
      labelClasses: "col-sm-6 pl-4 mb-2",
      inputWrapperClasses: "col-sm-6 pl-0 mb-2",
      inputClasses: "col-sm-10"
    },
    displayData: {
      property: "canProvideDiversityGender",
      value: false
    },
  },
]

export const EmployeeBreakdownDetailLowerInputs: ManagerCountFootnoteInputField[] = [
  {
    property: "explanation",
    label: "employee count explanation",
    type: "textarea",
    subClasses: {
      labelClasses: "pl-2",
      inputWrapperClasses: "col-sm-12 pl-2"
    },
  },
]

const reasonCheckPass = (state: EmployeeBreakdownQuery | undefined) => {
  // check for non-nullable reason code if can't provide employee count info.
  const canProvide = _.get(state as EmployeeBreakdownQuery, "org.employeeBreakdownDetail.canProvideDiversityGender")
  const provideCode = !!_.get(state, "org.employeeBreakdownDetail.reason.code")
  return canProvide || provideCode
}

const REASON_ERROR_MESSAGE = `Please select a reason why diversity or gender breakdown cannot be provided.`

enum ManagerEmployeeTableTooltipType {
  employeeCount = "employeeCountTooltip",
  employeeTurnover = "employeeTurnoverTooltip",
  genderBreakdown = "genderBreakdownTooltip",
  otherMinority = "otherMinorityTooltip",
  otherMinorityFem = "otherMinorityFemTooltip",
  // Slideout, ignore for now
  // diversityProfile = "diversityProfileTooltip"
}

const ManagerEmployeeTableTooltipIdsMapping = {
  [ManagerEmployeeTableTooltipType.employeeCount]: 11,
  [ManagerEmployeeTableTooltipType.employeeTurnover]: 12,
  [ManagerEmployeeTableTooltipType.genderBreakdown]: 122,
  [ManagerEmployeeTableTooltipType.otherMinority]: 123,
  [ManagerEmployeeTableTooltipType.otherMinorityFem]: 102,
  // [ManagerEmployeeTableTooltipType.diversityProfile]: 0
}

/**
 * query ManagerEmployeeBreakdown by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const ManagerEmployeeBreakdown: React.FC<idProps> = ({ id, auth }: idProps) => {
  const context = useContext(CalendarContext)
  // CAL-2190 year-end-date
  const [searchDate, setSearchDate] = useState(context.year)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  const [updateEmployees] = useUpdateManagerEmployeesMutation()
  const [updateBreakdownDetail] = useUpdateManagerEmployeeBreakdownDetailMutation()
  const [reasonErrorMessage, setReasonErrorMessage] = useState("")
  const tooltip_ids = useMemo(() => Object.values(ManagerEmployeeTableTooltipIdsMapping) || [], [])
  let { data, loading, error } =
  useEmployeeBreakdownQuery({
    variables: {
      id,
      tooltip_ids,
      startDate: searchDate,
      endDate: searchDate
    }
  })


  const { loading: jobLoading, error: jobError, data: jobData } = useGetLookupQuery({
    variables: { name: "EmployeeJobType" }
  })
  const { loading: raceLoading, error: raceError, data: raceData } = useGetLookupQuery({
    variables: { name: "EmployeeRaceType" }
  })
  const { loading: genderLoading, error: genderError, data: genderData } = useGetLookupQuery({
    variables: { name: "EmployeeGenderType" }
  })
  const { loading: typeLoading, error: typeError, data: typeData } = useGetLookupQuery({
    variables: { name: "EmployeeType" }
  })
  const { loading: reasonLoading, error: reasonError, data: reasonData } = useGetLookupQuery({
    variables: { name: "EmployeeReasonCode" }
  })
  const { loading: minorityLoading, error: minorityError, data: minorityData } = useGetLookupQuery({
    variables: { name: "EmployeeOtherMinorityType" }
  })

  useEffect(() => {
    let checkPassFlag = reasonCheckPass(resultRef!.current?.state?.currentState)
    if((!checkPassFlag) && editMode) {
      setReasonErrorMessage(REASON_ERROR_MESSAGE)
    }
  }, [resultRef!.current?.state])

  const handleEdit = () => {
    setReasonErrorMessage("")
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const onSubmit = () => {
    let checkPassFlag = reasonCheckPass(resultRef!.current?.state?.currentState)
    if(checkPassFlag) {
      handleSubmit()
    }else {
      setReasonErrorMessage(REASON_ERROR_MESSAGE)
      return
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    let currentOrg = resultRef!.current?.state.currentState.org
    let initialOrg = resultRef!.current?.state.initialState.org
    if(!currentOrg || currentOrg.__typename !== "Manager"){
      return
    }
    if(!initialOrg || initialOrg.__typename !== "Manager"){
      return
    }
    setReasonErrorMessage("")
    setSaving(true)
    let org = initialOrg
    let employeeTurnover = currentOrg.employeeTurnover.filter(x => {
      if(org && org.__typename === "Manager" && org.employeeTurnover){
        return !_.find(org.employeeTurnover, (o) => JSON.stringify(o) === JSON.stringify(x))
      }
      return true
    })
    let employeeBreakdownByGender = currentOrg.employeeBreakdownByGender.filter(x => {
      if(org && org.__typename === "Manager" && org.employeeBreakdownByGender){
        return !_.find(org.employeeBreakdownByGender, (o) => JSON.stringify(o) === JSON.stringify(x))
      }
      return true
    })
    let employeeBreakdownByJobCategory = currentOrg.employeeBreakdownByJobCategory.filter(x => {
      if(org && org.__typename === "Manager" && org.employeeBreakdownByJobCategory){
        return !_.find(org.employeeBreakdownByJobCategory, (o) => JSON.stringify(o) === JSON.stringify(x))
      }
      return true
    })
    let employeeBreakdownByDiversityProfile = currentOrg.employeeBreakdownByDiversityProfile.filter(x => {
      if(org && org.__typename === "Manager" && org.employeeBreakdownByDiversityProfile){
        return !_.find(org.employeeBreakdownByDiversityProfile, (o) => JSON.stringify(o) === JSON.stringify(x))
      }
      return true
    })

    let employeeBreakdownByOtherMinority = currentOrg.employeeBreakdownByOtherMinority.filter(x => {
      if(org && org.__typename === "Manager" && org.employeeBreakdownByOtherMinority){
        return !_.find(org.employeeBreakdownByOtherMinority, (o) => JSON.stringify(o) === JSON.stringify(x))
      }
      return true
    })

    const formattedEmployeeTurnover = convertLookupToString(employeeTurnover, false)
    const formattedEmployeeBreakdownByGender = convertLookupToString(employeeBreakdownByGender, false)
    const formattedEmployeeBreakdownByJobCategory = convertLookupToString(employeeBreakdownByJobCategory, false)
    const formattedEmployeeBreakdownByDiversityProfile = convertLookupToString(employeeBreakdownByDiversityProfile, false)
    const formattedEmployeeBreakdownByOtherMinority = convertLookupToString(employeeBreakdownByOtherMinority, false)

    const patchList = formattedEmployeeTurnover.concat(formattedEmployeeBreakdownByGender).concat(formattedEmployeeBreakdownByJobCategory).concat(formattedEmployeeBreakdownByDiversityProfile).concat(formattedEmployeeBreakdownByOtherMinority)

    const updateData = {
      id: currentOrg.id,
      patch: _.map(patchList, (fp) => {return excludePropertyArray(fp, ["__typename"])})
    } as EmployeeTurnoverInput

    const {employeeBreakdownDetail} = currentOrg
    const formattedEmployeeBreakdownDetail = convertLookupToString(employeeBreakdownDetail || {}, false)
    const updateEmployeeBreakdownDetailData = {
      id: currentOrg.id,
      patch: excludePropertyArray(formattedEmployeeBreakdownDetail, ["__typename"])
    }

    updateEmployees({ variables: { input: updateData, startDate: searchDate, endDate: searchDate } })
      .then(result => {
        if (result && result.data) {
          let unformattedNewData = result.data.upsertEmployeeTurnover
          let previousState = resultRef!.current?.state
          resultRef!.current?.setState({ ...previousState, currentState: {...unformattedNewData, tooltips: data?.tooltips}, initialState: unformattedNewData })
          // setEditMode(false)
          // setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error upsertEmployeeTurnover", err.message)
        // throw new Error(`${err.message}`)
      })

    if(!employeeBreakdownDetail) {
      setEditMode(false)
      setSaving(false)
    }else {
      updateBreakdownDetail({ variables: { input: updateEmployeeBreakdownDetailData, startDate: searchDate, endDate: searchDate } })
      .then(result => {
        if (result && result.data) {
          let unformattedNewData = result.data.upsertEmployeeBreakdownDetail
          let previousState = resultRef!.current?.state
          resultRef!.current?.setState({ ...previousState, currentState: {...unformattedNewData, tooltips: data?.tooltips}, initialState: unformattedNewData })
          setEditMode(false)
          setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error upsertEmployeeBreakdownDetail", err.message)
      })
    }
  }

  let explanation = ""
  if(data?.org?.__typename === "Manager") explanation = data.org?.employeeBreakdownDetail?.explanation || ""
  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <CalendarPicker
        updateValue={(searchDate) => setSearchDate(moment(searchDate, DATE_API_FORMAT).endOf("year").format(DATE_API_FORMAT))}
        defaultPeriod={"year"}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <div className="border-left ml-2 pl-2">
        <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables({extraRows: [{values:[explanation]}]})}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
      </div>
      {reasonErrorMessage &&
        <span className="text-accent-red py-2 ml-2 d-flex">
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
          &nbsp; {`Save Error: ${reasonErrorMessage}`}
        </span>
      }
      {auth.checkPermissions(["edit:manager"]) &&
        <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={onSubmit} disabled={loading}/>
      }
    </div>
  )

  if (loading || jobLoading || raceLoading || genderLoading || typeLoading || reasonLoading || minorityLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error || jobError || raceError || genderError || typeError || reasonError || minorityError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <p>{error?.message}</p>
              <p>{jobError?.message}</p>
              <p>{raceError?.message}</p>
              <p>{genderError?.message}</p>
              <p>{typeError?.message}</p>
              <p>{reasonError?.message}</p>
              <p>{minorityError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (data && data.org && data.org.__typename === "Manager" && jobData && raceData && genderData && typeData && reasonData && minorityData) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              key={searchDate}
              ref={resultRef}
              editMode={editMode}
              setEditMode={setEditMode}
              setSearchDate={setSearchDate}
              data={data}
              searchDate={searchDate}
              jobData={jobData}
              raceData={raceData}
              genderData={genderData}
              typeData={typeData}
              minorityData={minorityData}
              history={history}
              setReasonErrorMessage={setReasonErrorMessage}
              tooltipMapping={ManagerEmployeeTableTooltipIdsMapping}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }
  constructor(props: any) {
    super(props)
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    type: any,
    assetClass?: number
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["org", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => {return (o?.type?.code === type.code) && (!assetClass || o?.assetClass?.id === assetClass)})
        if(selectedRow){
          _.set(selectedRow, property, value)
        } else {
          let newRow
          if(assetClass){
            newRow = {
              date: this.props.searchDate,
              type: {...type, __typename: "Lookup"},
              count: undefined,
              gained: undefined,
              lost: undefined,
              assetClass: _.find(assetClasses, (o) => {return o.id === assetClass})
            }
            _.set(newRow, property, value)
          } else {
            newRow = {
              date: this.props.searchDate,
              type: {...type, __typename: "Lookup"},
              count: undefined,
            }
            _.set(newRow, property, value)
          }
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  handleManagerCountFootnoteChange = (
    value: any,
    property: string,
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["org", "employeeBreakdownDetail", ...property.split(".")]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, value)
    }
    let newState = iassign(
      oldState,
      path,
      () => value
    )
    if(reasonCheckPass(newState)) {
      this.props.setReasonErrorMessage("")
    }
    this.setState({ ...this.state, currentState: newState})
  }

  formatData = (sourceData:any, mappingList:any) => {
    return _.map(mappingList.__type?.enumValues, (type:any) => {
      const foundData = _.find(sourceData, (o:any) => {return o.type.code === type.code})
      if(foundData){
        return foundData
      } else {
        return {
          date: this.props.searchDate,
          type: type,
          count: undefined
        }
      }
    })
  }

  turnoverData = (sourceData:any) => {
    const defaultTypes:string[] = ['PM','FUNDA','QUANT','CENRE'] //Default
    const hedgeTypes:string[] = ['PM','HEDGE','RISK','OPDUE'] //Hedge Fund of Fund
    const realTypes:string[] = ['ACCT','ACQUI','AMGMT','CSMK','DISPO','ENGIN','IT','LEGAL','LEASE','PM','PMGMT','RSRCH','SENMG','OTHER'] //Real Estate
    const turnoverResults = assetClasses.reduce((result:any,entry:any) => {
      let typeList
      if(entry.id === 45){
        // Hedge Fund of Fund
        typeList = hedgeTypes
      } else if (entry.id === 9){
        // Real Estate
        typeList = realTypes
      } else {
        typeList = defaultTypes
      }
      const resultList = typeList.reduce((types:any,type:any) => {
        const foundObject = _.find(sourceData, (o) => {return o.assetClass.id === entry.id && o.type.code == type})
        if(foundObject){
          types.push(foundObject)
        } else {
          types.push({
            date: this.props.searchDate,
            type: {..._.find(this.props.typeData.__type?.enumValues, (o) => {return type === o.code}), __typename: "Lookup"},
            count: undefined,
            gained: undefined,
            lost: undefined,
            assetClass: entry
          })
        }
        return types
      },[])
      return result.concat(resultList);
    },[])
    return turnoverResults
  }

  generateDetailComponent = (inputFields: ManagerCountFootnoteInputField[], state: Maybe<EmployeeBreakdownDetail> | undefined) => {
    return (
      <Col sm="6" className="px-4">
        {inputFields.map(
          (
            {
              property,
              label,
              type,
              subtype,
              placeholder,
              optionSource,
              subClasses,
              displayData,
              charactersLimit,
              required,
            },
            idx
          ) => {
            if (
              (displayData && _.get(state, displayData.property) !== displayData.value)
            ) {
              return <React.Fragment key={idx}></React.Fragment>
            }
            let propertyVal: any = _.get(state, property)
            let onChangeCallback = (value: any) =>{
              if(type === "select" && value === ""){
                this.handleManagerCountFootnoteChange(null, property)
              } else {
                this.handleManagerCountFootnoteChange(value, property)
              }
            }
            return (
              <FormInput
                key={idx}
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={`Details-${idx}`}
                editMode={this.props.editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                subClasses={subClasses}
                showZero={true}
                charactersLimit={charactersLimit}
                required={required}
              />
            )
      })}
      </Col>)
  }

  render() {
    const employeeBreakdown = this.state.currentState.org
    const {
      employeeBreakdownByGender,
      employeeBreakdownByDiversityProfile,
      employeeBreakdownByJobCategory,
      employeeTurnover,
      employeeBreakdownDetail,
      employeeBreakdownByOtherMinority,
    } = employeeBreakdown as Manager
    const formattedGenderData = this.formatData(employeeBreakdownByGender,this.props.genderData)
    const formattedJobData = this.formatData(employeeBreakdownByJobCategory,this.props.jobData)
    const formattedDiversityData = this.formatData(employeeBreakdownByDiversityProfile,this.props.raceData)
    const formattedTurnoverData = this.turnoverData(employeeTurnover)
    const formattedMinorityData = this.formatData(employeeBreakdownByOtherMinority,this.props.minorityData)
    const sortedJobCategory = _.sortBy(formattedJobData, [(o) => {return (o.type.code === "TOTAL") ? "zzzzzz" : o.type.value}])

    const tooltipsData: TooltipFragment[] = compact(this.state.currentState.tooltips)
    const tooltipMapping = this.props.tooltipMapping

    return(
      <>
        <RouteLeavingGuard
          when={this.props.editMode}
          navigate={path => this.props.history.push(path)}
        />
        <div className='pane pane-table'>
          <Row>
            <Col>
              <h3 className="headline underline green-underline mb-3" id="employeeCountContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="employeeCountTooltip">
                  Employee Count
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                    id={"generic-tooltip-employeeCountTooltip"}
                  />
                  <UncontrolledTooltip
                    className="generic-tooltip"
                    target={"generic-tooltip-employeeCountTooltip"}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                      tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerEmployeeTableTooltipIdsMapping["employeeCountTooltip" as ManagerEmployeeTableTooltipType] || "")?.text || ""
                      )}}
                    delay={{hide: 1000, show: 0}}
                  />
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="mb-2" key={`employeeBreakdownDetail-1`}>
            {this.generateDetailComponent(EmployeeBreakdownDetailUpperInputs, employeeBreakdownDetail)}
          </Row>
          <Row>
            <Col className="border-right">
              <EmployeeTable
                data={sortedJobCategory}
                tableName={`jobCategory`}
                exportTableName={`Job`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByJobCategory",type)}
              />
            </Col>
            <Col className="border-right">
              <EmployeeTable
                data={formattedDiversityData}
                tableName={`diversity`}
                exportTableName={`Diversity`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByDiversityProfile",type)}
              />
            </Col>
            <Col className="border-right">
              <EmployeeTable
                data={formattedGenderData}
                tableName={`genderBreakDown`}
                exportTableName={`Gender`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByGender",type)}
                tooltipsData={tooltipsData}
                tooltipMapping={tooltipMapping}
              />
            </Col>
            <Col>
              <EmployeeTable
                data={formattedMinorityData}
                tableName={`minorityBreakDown`}
                exportTableName={`Minority`}
                editMode={this.props.editMode}
                updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByOtherMinority",type)}
                tooltipsData={tooltipsData}
                tooltipMapping={tooltipMapping}
              />
            </Col>
          </Row>
          <Row className="mb-2" key={`employeeBreakdownDetail-2`}>
            {this.generateDetailComponent(EmployeeBreakdownDetailLowerInputs, employeeBreakdownDetail)}
          </Row>
          <Row className="mt-5">
            <Col>
              <h3 className="headline underline green-underline mb-3" id="employeeTurnoverContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="employeeTurnoverTooltip">
                  Employee Turnover
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                    id={"generic-tooltip-employeeTurnoverTooltip"}
                    />
                  <UncontrolledTooltip
                    className="generic-tooltip"
                    target={"generic-tooltip-employeeTurnoverTooltip"}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(
                      tooltipsData?.find((tooltip:any) => tooltip?.id === ManagerEmployeeTableTooltipIdsMapping["employeeTurnoverTooltip" as ManagerEmployeeTableTooltipType] || "")?.text || ""
                      )}}
                    delay={{hide: 1000, show: 0}}
                  />
                </div></h3>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-9" style={{minWidth: 1000}}>
              <EmployeeTable
                data={formattedTurnoverData}
                tableName={`turnover`}
                exportTableName={`Turnover`}
                editMode={this.props.editMode}
                updateValue={(value, property, type, assetClass) => this.handleInputChange(value,property,"employeeTurnover",type, assetClass)}
                tooltipsData={tooltipsData}
                tooltipMapping={tooltipMapping}
              />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

const assetClasses = [
  {
    id: 1,
    fullName: "Domestic Broad Equity",
    __typename: "AssetClass"
  },
  {
    id: 4,
    fullName: "Domestic Broad Fixed-Inc",
    __typename: "AssetClass"
  },
  {
    id: 16,
    fullName: "Domestic Balanced",
    __typename: "AssetClass"
  },
  {
    id: 11,
    fullName: "International Broad Equity",
    __typename: "AssetClass"
  },
  {
    id: 12,
    fullName: "International Broad Fixed-Inc",
    __typename: "AssetClass"
  },
  {
    id: 18,
    fullName: "Global Balanced",
    __typename: "AssetClass"
  },
  {
    id: 49,
    fullName: "Domestic Real Estate Investment Trusts",
    __typename: "AssetClass"
  },
  {
    id: 9,
    fullName: "Real Estate",
    __typename: "AssetClass"
  },
  {
    id: 45,
    fullName: "Hedge Funds",
    __typename: "AssetClass"
  },
  {
    id: 57,
    fullName: "Private Equity",
    __typename: "AssetClass"
  }
]

export default ManagerEmployeeBreakdown
