import classnames from "classnames"
import React, { EventHandler, useContext, useEffect, useState } from "react"
import { CustomInput, FormGroup, Label, UncontrolledTooltip } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import _ from "lodash"
import { EditButtonContext } from "../../../Context/EditButtonContext"
import { InputTooltip } from "./TextFieldInput"
import DOMPurify from "dompurify"

interface BooleanInputProps {
  idx: string | number
  property: string
  displayName: string
  propertyValue: string | boolean | undefined
  editMode: boolean
  updateValue: (event: any) => void
  readonly?: boolean
  wrapperClasses?: string
  labelClasses?: string
  inputWrapperClasses?: string
  inputClasses?: string
  required?: boolean
  tooltip?: InputTooltip
  disabled?: boolean
}

export const BooleanInput: React.FC<BooleanInputProps> = (props) => {
  const { idx, property, displayName, propertyValue, editMode, labelClasses, inputWrapperClasses, inputClasses, tooltip, disabled } = props
  let updateValue: EventHandler<any> = props.updateValue
  let booleanValue: boolean | null = propertyValue === "true" || propertyValue === true
  if (_.isNull(propertyValue) || _.isUndefined(propertyValue)) {
    // no preCheck if no data provided
    booleanValue = null
  }
  let required = props.required || false
  let shortProperty = property.split(".").pop()
  shortProperty = shortProperty ? shortProperty : property

  const handleBoolRadioClick = (event: React.ChangeEvent<HTMLInputElement>, updateValue: (value: any) => void) => {
    if (!shortProperty) {
      shortProperty = property
    }
    const value = event.target.value
    updateValue(value === "true" ? true : value === "false" ? false : null)
  }

  const { setError } = useContext(EditButtonContext)
  const [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || "")

  useEffect(() => {
    if (editMode && required) {
      if (propertyValue === undefined || propertyValue === null) {
        setWrapperClasses(wrapperClasses + " has-error")
        setError(`${idx}-${shortProperty}`, ["Presence"])
      } else {
        setError(`${idx}-${shortProperty}`, [])
        setWrapperClasses(wrapperClasses.replace(/has-error/g, ""))
      }
    } else {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g, ""))
    }
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [editMode, idx, propertyValue, required])

  return (
    <FormGroup className={classnames("form-group row", wrapperClasses || "")} key={idx} name={`${idx}-${shortProperty}`} validate={{ required: { value: true, errorMessage: "Please select" } }}>
      <Label className={classnames("col-form-label", labelClasses || "", { "col-sm-4": !labelClasses })} htmlFor={`${idx}-${shortProperty}`} id={property}>
        <div
          className={classnames("d-flex w-100", { "tooltip-icon": tooltip, "justify-content-start": tooltip?.onClick})}
          id={tooltip ? tooltip.id : ""}
        >
          {displayName}
          {tooltip && (
            <>
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
                id={tooltip? "generic-tooltip-"+tooltip?.id : ""}
              />
              {tooltip?.text &&
                <UncontrolledTooltip
                  className="generic-tooltip"
                  target={"generic-tooltip-"+tooltip?.id || ""}
                  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tooltip?.text || "")}}
                  delay={{hide: 5000, show: 0}}
                />
              }
            </>
          )}
        </div>
      </Label>
      <div className={classnames("d-flex align-items-center justify-content-between exportable-form-input ", inputWrapperClasses || "", { "col-sm-4": !inputWrapperClasses })} data-export-value={booleanValue ? "Yes" : "No"} id={`${idx}-${shortProperty}`}>
        <CustomInput
          className={classnames(inputClasses, "boolean-radio custom-control-inline no-export-form-label", {
            hidden: false, // (!editMode && propertyValue !== true),
            editing: editMode,
            disabled: !editMode || disabled,
          })}
          id={`${idx}-${shortProperty}-true`}
          bsSize="sm"
          type="radio"
          value="true"
          checked={booleanValue === true}
          onChange={(event: any) => {
            handleBoolRadioClick(event, updateValue)
          }}
          label="Yes"
          inline
          disabled={!editMode || disabled}
        />
        <CustomInput
          className={classnames(inputClasses,"boolean-radio custom-control-inline no-export-form-label", {
            hidden: false, // (!editMode && propertyValue !== true),
            editing: editMode,
            disabled: !editMode || disabled,
          })}
          id={`${idx}-${shortProperty}-false`}
          bsSize="sm"
          type="radio"
          value="false"
          checked={booleanValue === false}
          onChange={(event: any) => handleBoolRadioClick(event, updateValue)}
          label="No"
          inline
          disabled={!editMode || disabled}
        />
      </div>
    </FormGroup>
  )
}
