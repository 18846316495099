import { disableFragmentWarnings, gql } from "@apollo/client"
disableFragmentWarnings();

export const TOOLTIP_FRAGMENT = gql`
  fragment TooltipFragment on Tooltip {
    id
    text
  }
`

export const TOOLTIP_QUERY = gql`
  query Tooltips($ids: [Int!]!) {
    tooltips(ids: $ids) {
      ...TooltipFragment
    }
  }
  ${TOOLTIP_FRAGMENT}
`