import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties } from 'react'
import { noRefreshHistory as history } from "../history"
import { ICellRendererParams } from "@ag-grid-community/core"
import { downloadWithFilename } from './download'
import classnames from 'classnames'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { statusColor, statusEnumToValue } from './helpers'
import { compact, first, get, some, uniqueId } from 'lodash'
import moment from 'moment'
import { DocumentPreviewIcon } from '../Components/Shared/DocumentPreviewIcon'
import { JobStatusCode, StatusDetails } from '../__generated__/graphql'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import numbro from 'numbro'
import { UseTypeCode } from '../__generated__/graphql'
import { TransactionTypeWithTitles } from '../Components/Report/SumSheet'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from './constant'

/**
 * if showHref is true, show real url on hover.
 **/
type textLinkParams = {
  text: string,
  url: string,
  showHref?: boolean,
  newTab?: boolean,
}

export const textLink = ({text, url, showHref = true, newTab = false}: textLinkParams) => {
  if(!text) return (<span>-</span>)
  let href = showHref? url: ""
  return (
    <a href={href} target={newTab? "_blank": ""} className="fake-link">
      {text}
      <FontAwesomeIcon
        icon={["fas", "external-link"]}
        className={classnames("ag-cell-right-icon-hover text-blue-80", {"d-none": !newTab})}
        // onClick={() => history.push(url)}
      />
    </a>
  )
}

export const ReportPlanRenderer = (params: ICellRendererParams) => {
  // for report list table
  let result: JSX.Element[] = params.data?.plans?.map((plan:any, idx: number)=>{
    var link = textLink({text: plan.name, url: "/clients/" + plan.client?.id + "/" + plan?.id + "/" + params.data?.id + "/"})
    return link
  })
  return (
    <span>
      {result.map((el, idx) => {
        if(idx > 0) {
          return <React.Fragment key={idx}>, {el}</React.Fragment>
        }
        return <React.Fragment key={idx}>{el}</React.Fragment>
      })}
    </span>
  )
}

export const checkOrCrossRenderer = (params: ICellRendererParams) => {
  let check = params.value && params.value !== "Inactive" ? "fa-check": ""
  return (<span className={`cell-icon fa-solid ${check}`}></span>)
}

export const rightAlignedRenderer = (params: ICellRendererParams) => {
  if(params.value === "Loading") {
    return (
      <div className="text-right">
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    )
  }
  return (
    <div className="text-right">
      {params.valueFormatted || params.value}
    </div>
  )
}

export const selectTransactionTypeRenderer = (params: ICellRendererParams, lookupData: TransactionTypeWithTitles[]) => {
  const value = lookupData.find((item) => item.code === params.value)
  const isTitle = value && "title" in value

  return (
    <div className={classNames("pe-none", {'font-weight-bold text-uppercase': isTitle, 'pl-1': !isTitle})}>
      {value?.name}
    </div>
  )
}

export const overValueWarningRenderer = (params: ICellRendererParams, compareValue: number) => {
  let warningIcon = <></>
  if(compareValue !== 0 && params.value >= compareValue && params.data?.transactionType !== "ending-assets"){
    warningIcon = (
      <FontAwesomeIcon
        icon="exclamation-triangle"
        className="text-warning ml-1"
      />
    )
  }
  return (
    <div className="text-right">
      {warningIcon} {params.valueFormatted || params.value}
    </div>
  )
}

export const diffWarningRenderer = (params: ICellRendererParams, warnings: string[]) => {
  let warningIcon = <></>
  let mappedWarnings = compact(warnings.map((warning) => params.data[warning]))
  if(mappedWarnings.length > 0){
    const uniqId = uniqueId()
    warningIcon = (
      <>
        <span className='absolute left-0' id={"grid-tooltip-"+uniqId}>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className="text-warning ml-1"
          />
        </span>
        <UncontrolledTooltip placement="top" target={"grid-tooltip-"+uniqId}>
          {mappedWarnings[0]}
        </UncontrolledTooltip>
      </>
    )
  }
  return (
    <div className="text-right pl-3">
      {warningIcon} {params.valueFormatted || params.value}
    </div>
  )
}

export const statusRenderer = (params: ICellRendererParams, warnings: string[]) => {
  let warningIcon = <></>
  const uniqId = uniqueId()
  if((params.valueFormatted || params.value) === "Within Expectations"){
    warningIcon = (
      <div>
        <span id={"grid-tooltip-"+uniqId}>
          <FontAwesomeIcon
            icon="circle"
            className="text-accent-green mx-1"
          />
        </span>
        <UncontrolledTooltip placement="top" target={"grid-tooltip-"+uniqId}>
          Within Expectations
        </UncontrolledTooltip>
      </div>
    )
  } else {
    warningIcon = (
      <div>
        <span id={"grid-tooltip-"+uniqId}>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className="text-warning mx-1"
          />
          {(params.valueFormatted || params.value) === "Cautionary" ? "" : (params.valueFormatted || params.value)}
        </span>
        <UncontrolledTooltip placement="top" target={"grid-tooltip-"+uniqId}>
          {params.valueFormatted || params.value}
        </UncontrolledTooltip>
      </div>
    )
  }
  return (
    <div className="">
      {warningIcon}
    </div>
  )
}

export const cashflowChangedDateRenderer = (params: ICellRendererParams, date: string) => {
  if(params.value === "Loading") return rightAlignedRenderer(params)
  let warningIcon = <div></div>
  // let assets = params.data.cashFlowActivity || []
  // let correctAsset = assets.find((asset:any) => asset.date === date)
  // if(correctAsset?.underlyingDataChange){
  //   const uniqId = uniqueId()
  //   warningIcon = (
  //     <div>
  //       <span id={"grid-tooltip-"+uniqId}>
  //         <FontAwesomeIcon
  //           icon="exclamation-triangle"
  //           className="text-accent-red"
  //         />
  //       </span>
  //       <UncontrolledTooltip placement="top" target={"grid-tooltip-"+uniqId}>
  //         Data has changed. Please run the appropriate job to capture the latest data.
  //       </UncontrolledTooltip>
  //     </div>
  //   )
  // }
  return (
    <div className="d-flex justify-content-between">
      {warningIcon} {params.valueFormatted || params.value}
    </div>
  )
}

export const fileIconRenderer = (params: ICellRendererParams) => {
  if(params.data){
    let result = DocumentPreviewIcon({document: params.data, className: "svg-20px"})
    let downloadIcon = (
      <FontAwesomeIcon
          icon="download"
          className="ml-1"
      />)
    return result || downloadIcon
  }
}

export const downloadIconRenderer = (params: ICellRendererParams, grayText: boolean) => {
  if(!params.value) return ""
  return (
    <Button className="btn btn-link p-0 border-0"
      onClick={() => downloadWithFilename(params.value || "", params.data.name)}>
      <FontAwesomeIcon
        icon="download"
        className={classnames("ml-1", {"text-callan-blue" :!grayText})}
      />
    </Button>
  )
}

export const favoriteIconRenderer = (params: ICellRendererParams) => {
  if(params.value){
    return (
      <FontAwesomeIcon
        icon="star"
        className="text-accent-yellow ml-4"
      />
    )
  } else {
    return (
      <FontAwesomeIcon
        icon={["far", "star"]}
        className="ml-4"
      />
    )
  }
}

export const tickIconRenderer = (params: ICellRendererParams) => {
  if(params.value){
    return (
      <FontAwesomeIcon
        icon="check"
        className="text-gray-100"
      />
    )
  } else {
    return ""
  }
}

export const noteTickRenderer = (params: ICellRendererParams) => {
  if(params.value){
    const check = (
      <FontAwesomeIcon
        icon="check"
        className="text-gray-100"
      />
    )
    return tooltipCellRenderer({value: check, toolTip: params.value})
  } else {
    return ""
  }
}

const alertIcon = (message: string) => {
  const uniqId = uniqueId()
  return (
    <>
      <span id={"grid-tooltip-"+uniqId}>
        <FontAwesomeIcon icon={["fas", "asterisk"]} className="text-gray-70 ml-1" />
      </span>
      <UncontrolledTooltip
        className="status-alert-tooltip"
        placement="top" target={"grid-tooltip-"+uniqId}
      >
        {message}
      </UncontrolledTooltip>
    </>
  )
}

const statusChangedScript = "Status has changed within the last 90 days."

export const statusIconRenderer = (params: ICellRendererParams, withAlertSign: boolean = false) => {
  if(params.value){
    const status = get(params.data, params.colDef?.field || "")
    if(!status) return ""
    return (
      <div className="d-flex">
        <span>
          <FontAwesomeIcon
            icon="circle"
            className={`text-${statusColor(statusEnumToValue(status.status))}`}
            />
        </span>
          {withAlertSign === true && alertIcon(statusChangedScript)
          }
      </div>
    )
  } else {
    return ""
  }
}

export const statusIconRendererWithDate = (params: ICellRendererParams, withAlertSign: boolean = false) => {
  if(params.data){
    const status = get(params.data, params.colDef?.field || "")
    if(!status) return ""
    var text = document.createElement("span")
    // text.innerText = moment(params.value.lastUpdated).format("YYYY/MM/DD HH:mm")
    text.className = "text-gray-70"
    return (
      <span className="text-gray-70">
        <FontAwesomeIcon
          icon="circle"
          className={`text-${statusColor(statusEnumToValue(status.status))}`}
        />
        {" " + moment(status.lastChangedDate).format("YYYY/MM/DD")}
        {withAlertSign === true && alertIcon(statusChangedScript)
        }
      </span>
    )
  } else {
    return ""
  }
}

type statusIconRendererWithDateConfigProps = {
  showChange?: boolean,
  period?: number
}

// helper function to check if status(not text) changed within period days.
export const statusChangeWithinPeriod = (status: StatusDetails, period: number) => {
  if(status?.statusLastUpdated){
    return moment().diff(moment(status.statusLastUpdated, DATE_API_FORMAT), "days") <= period
  }
  return false
}

// showChange: show exclamation triangle if status(not text) changed within period days.
export const statusIconRendererWithDateAndMonitor = (params: ICellRendererParams, {showChange = false, period = 90}: statusIconRendererWithDateConfigProps) => {

  if(params.data){
    const status = get(params.data, params.colDef?.field || "")
    const changedInPeriod = showChange && statusChangeWithinPeriod(status, period)
    if(params.data?.product)
    return statusIconRendererWithDate(params, changedInPeriod)
  } else {
    return ""
  }
}

export const statusIconRendererWithMonitor = (params: ICellRendererParams, {showChange = false, period = 90}: statusIconRendererWithDateConfigProps) => {
  if(params.value){
    const status = get(params.data, params.colDef?.field || "")
    const changedInPeriod = showChange && statusChangeWithinPeriod(status, period)
    return statusIconRenderer(params, changedInPeriod)
  } else {
    return ""
  }
}


export const downloadLinkRenderer = (params: ICellRendererParams) => {
  return (
    <a href={params.data.url} target={"_blank"} className="fake-link">
      {params.value}
    </a>
  )
}

export interface FormCellRendererProps extends ICellRendererParams{
  values: {
    editMode: boolean
  }
}

export const calendarCellRenderer = (params: FormCellRendererProps) => {
  let icon = params.values.editMode ? (<FontAwesomeIcon icon={["far", "calendar"]} className="ag-cell-right-icon text-blue-80"/>) : (<></>)
  return (<span>{params.valueFormatted}{icon}</span>)
}

// supply full url for ag-grid cellRenderer.
export const glidepathLinkRenderer = (params: ICellRendererParams) => textLink({text: params.value, url: params?.data?.url})

type TooltipCellRendererProps = {
  value: string | JSX.Element,
  toolTip: string | JSX.Element,
  className?: string,
  title?: string
  usedId?: string
  autoHide?: boolean
  hideUnderline?: boolean
}

const tooltipCellRenderer = (params: TooltipCellRendererProps) => {
  const uniqId = params.usedId ? params.usedId : uniqueId()
  return (
    <div className={classNames(params.className)} id={"grid-tooltip-"+uniqId}>
      <span title={params.title || ""} className={classNames({'hover-transition-underline': !params.hideUnderline})}>
        {params.value}
      </span>
      <UncontrolledTooltip placement="top" target={"grid-tooltip-"+uniqId} className={"text-white bg-dark"} autohide={params.autoHide}>
        {params.toolTip}
      </UncontrolledTooltip>
    </div>
  )
}

export const sourceTooltipCellRenderer = (params: ICellRendererParams) => {
  return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip: `Source: ${params.data?.origin?.value}`})
}

export const paramsSourceTooltipCellRenderer = (params: ICellRendererParams, toolTip: string | JSX.Element) => {
  return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip})
}

export const sourceCashFlowTooltipCellRenderer = (params: ICellRendererParams) => {
  return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip: `Source: ${params.data?.originName}`})
}

export const cashflowAmountTooltipCellRenderer = (params: ICellRendererParams, inFormat?: numbro.Format) => {
  if(params.data.accruedInterests){
    let subAssets = params.value - params.data.accruedInterests
    const tooltip = <>
      <div className='d-flex justify-content-between'><div>Assets</div><div className='ml-2'>{numbro(subAssets).format(inFormat)}</div></div>
      <div className='d-flex justify-content-between'><div>Interest</div><div className='ml-2'>{numbro(params.data.accruedInterests).format(inFormat)}</div></div>
    </>
    return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip: tooltip})
  }
  return <div>
    {params.valueFormatted || params.value}
  </div>
}

export type TooltipLink = {
  label?: string
  url: string
  newTab?: boolean
}

type LinkedTooltipCellRendererProps = {
  value: string | JSX.Element,
  className?: string,
  title?: string
  usedId?: string
  links?: TooltipLink[]
  hideUnderline?: boolean
}

const linkedTooltipCellRenderer = (params: LinkedTooltipCellRendererProps) => {
  const handleLink = (link?:TooltipLink) => {
    if(!link || !link.url) return
    if(link.newTab){
      window.open(link.url, '_blank')
    } else {
      history.push(link.url || "")
    }
  }

  const tooltipItem = (linkNumber:number, link?:TooltipLink) => {
    if(!link) return <React.Fragment key={linkNumber}/>
    return (
      <div key={linkNumber} onClick={() => handleLink(link)} className={classNames("text-align-left cursor-pointer py-1")}>
        {link.label}
        {link.newTab &&
          <FontAwesomeIcon icon="external-link" className='ml-2'/>
        }
      </div>
    )
  }

  const toolTip = (
    <>
      {params.links?.map((link, idx) => tooltipItem(idx, link))}
    </>
  )

  return tooltipCellRenderer({...params, toolTip, autoHide: false})
}

export const sumSheetPortfolioLinkRenderer = (params: ICellRendererParams) => {
  return linkedTooltipCellRenderer({
    value: textLink({text: params.valueFormatted || params.value, url: "/clientportfolios/" + params.data?.id + "/overview", newTab: true}),
    links: compact([
      {label: "Portfolio Overview", url: `/clientportfolios/${params.data?.id}/overview`, newTab: true},
      {label: "Plan Performance", url: `/plans/${params.data?.plan?.id}/performance/${params.data?.id}/returns`,  newTab: true},
      {label: "Plan Characteristics", url: ``},
      params.data?.relatedVehicle ? {label: "Product Performance", url: `/products/${params.data?.relatedVehicle?.vehicle?.product?.product?.id}/performance/vehicles/${params.data?.relatedVehicle?.vehicle?.fundid}/returns`, newTab: true} : undefined,
    ]),
    hideUnderline: true,
  })
}

export const importedFileLinkRenderer = (params: ICellRendererParams, disableUntilFinished: boolean = true) => {
  let isInProcess = params?.data?.status.code === "_1" && !params?.data?.messages
  let text = params?.valueFormatted || params.value
  if(disableUntilFinished && isInProcess){
    return text
  }
  return isInProcess? text: textLink({
    text,
    url: "/control-center/imported-files/" + params?.data?.id,
    showHref: true,
    newTab: false,
  })
}

export interface WithDateRendererProps extends ICellRendererParams{
  values: {
    date: string
    period: string
  }
}

export const endingMarketTooltipCellRenderer = (params: WithDateRendererProps) => {
  if(params.valueFormatted === "-" || params.value === "-") return rightAlignedRenderer(params)
  let assets = params.data.quarterCashFlows?.marketValues || params.data.monthCashFlows?.marketValues
  let date = params?.values?.date
  let assetsBeforeDate = assets?.filter((asset:any) => moment(asset.date).isAfter(moment(date).subtract(1, params?.values?.period as moment.unitOfTime.DurationConstructor)))
  if(!assetsBeforeDate || assetsBeforeDate.length <= 1) return rightAlignedRenderer(params)
  return tooltipCellRenderer({value: params.valueFormatted || params.value, usedId: params.data?.id, toolTip:
  <>
    {assetsBeforeDate.map((asset:any) => {
      return <div key={asset.date}>{moment(asset.date).format(DATE_DISPLAY_FORMAT)} {numbro(asset.amount + (asset.accruedInterests || 0)).format("$0,0.00")}</div>
    })}
  </>, className: "text-right"})
}

export const vehicleReturnTooltipCellRenderer = (params: ICellRendererParams) => {
  if(!params.data?.relatedVehicle?.vehicle || params.value === "Loading") return rightAlignedRenderer(params)
  return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip: `${params.data?.relatedVehicle?.vehicle?.name} (${params.data?.relatedVehicle?.vehicle?.fundid})`, className: "text-right"})
}

export const benchmarkTooltipCellRenderer = (params: ICellRendererParams) => {
  if(params.valueFormatted === "-" || params.value === "-" || params.value === "Loading") return rightAlignedRenderer(params)
  let targets = params.data?.performanceTargetMap || []
  let correctTarget = targets.length > 1 ? targets.find((target:any) => target.order === 1) : first(targets)
  let toolTip = correctTarget?.target?.name
  if(correctTarget && correctTarget?.target?.useType?.code === UseTypeCode.GENRIC){
    const definition = first(correctTarget?.target?.definition) as any
    toolTip = `${toolTip} (${definition?.member?.indexId})`
  }
  return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip: toolTip, className: "text-right"})
}

export const isJobStatusInProgress = (status: JobStatusCode) => {
  const JobStatusInProgress = [JobStatusCode.BUILDING, JobStatusCode.READY, JobStatusCode.RUNNING, JobStatusCode.QUEUED]
  return JobStatusInProgress.includes(status)
}

const jobStatusCodeMappingToIconConfig = (status: JobStatusCode, staticDisplay: boolean = false) => {
  const InStatusIconName: IconProp = ["fad", "spinner-third"]
  const DoneStatusIconName: IconProp = ["fas", "check-circle"]
  const FailStatusIconName: IconProp = ["far", "times"]
  switch (status) {
    // in progress
    case JobStatusCode.BUILDING:
    case JobStatusCode.READY:
    case JobStatusCode.RUNNING:
    case JobStatusCode.QUEUED:
      return {icon: InStatusIconName, className: staticDisplay? "": "fa-spin fa-pulse", style:{"--fa-primary-color": "#055f8e", "--fa-secondary-color": "#8f9ba6"} as CSSProperties }
    // done
    case JobStatusCode.DONE_OK:
      return {icon: DoneStatusIconName, className: "userSuccess"}
      // failed
    case JobStatusCode.SUBMIT_FAIL:
    case JobStatusCode.SUBMIT_FLOP:
    case JobStatusCode.DONE_FAIL:
    case JobStatusCode.DONE_FLOP:
    case JobStatusCode.DONE_OOPS:
    case JobStatusCode.DONE_CANCEL:
      return {icon: FailStatusIconName, className: "danger"}
    default:
      return {icon: "", className: ""}

  }
}

export const jobStatusCodeRenderer = (params: ICellRendererParams) => {
  const status = params?.data?.status.code
  const {icon, className, style} = jobStatusCodeMappingToIconConfig(status)
  return (
    <span
      className="jobStatus"
    >
      {icon?.length > 1 && <FontAwesomeIcon icon={icon as IconProp} className={classnames(className, "ag-cell-center-icon")} style={style} fixedWidth={true} />}
      {!icon?.length && ("-")}
    </span>
  )
}

export const jobStatusCodeFilterRenderer = (params: ICellRendererParams) => {
  const isDefaultFilter = !(params?.value?.value)
  let text = isDefaultFilter? (params?.value || '(Blanks)'): params?.value?.value
  if(isDefaultFilter){
    return <span>{text}</span>
  }

  const code = params?.value?.code
  const {icon, className, style} = jobStatusCodeMappingToIconConfig(code, true)
  return (
    <span
      className="jobStatus"
    >
      {icon?.length > 1 && <FontAwesomeIcon icon={icon as IconProp} className={classnames(className, "mr-1")} style={style} fixedWidth={true} />}
      {text}
    </span>
  )
}

export const jobStatusWithTooltipCellRenderer = (params: ICellRendererParams) => {
  return tooltipCellRenderer({value: jobStatusCodeRenderer(params), toolTip: `${params.data?.status?.value}`})
}

export const JobStatusComparator = (valueA: any, valueB: any) => {
  const {className: classNameA} = jobStatusCodeMappingToIconConfig(valueA.code, true)
  const {className: classNameB} = jobStatusCodeMappingToIconConfig(valueB.code, true)

  // match className from jobStatusCodeMappingToIconConfig
  const classNameOrder = ["userSuccess", "danger", ""]
  const classA = classNameOrder.indexOf(classNameA)
  const classB = classNameOrder.indexOf(classNameB)
  if(classA < classB){
    return -1
  }else if(classA > classB){
    return 1
  }else {
    return (valueA?.value).localeCompare(valueB?.value)
  }
}

export const JobStatusLogRenderer = (params: ICellRendererParams) => {
  const env = process.env.NODE_ENV
  // window.location.hostname
  let hostName = window.location.hostname
  let prefix: string = "https://apps.callan.com"
  if(hostName !== "app.callan.com") {
    prefix = "https://appdev.callan.com"
  }
  const jobStatusId = params.data?.id
  return textLink({
    text: jobStatusId,
    url: prefix +`/jobs/result/${jobStatusId}/`,
    showHref: true,
    newTab: true,
  })
}